import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldWrapper,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { ArrowLeft, Lock, Mail } from "lucide-react";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ILoginCredentials } from "../../interfaces/auth.interface";
import EmailInputWithValidation from "../../shared-components/EmailInputWithValidation";
import { EMAIL_VALIDATOR } from "../../utils/utils";
import { useAppDispatch } from "../../store/hooks/hooks";
import { setUser } from "../../store/features/userSlice";
import authService from "../../services/auth.service";
import { setToast } from "../../store/features/toastSlice";

const LoginForm = () => {
  const dispatch = useAppDispatch();

  const [loginCredentials] = React.useState<ILoginCredentials>({
    email: "",
    password: "",
  });

  const navigateTo = useNavigate();

  const handleSubmit = (dataItem: { [name: string]: ILoginCredentials }) => {
    //@ts-ignore
    authService
      .login(dataItem)
      .then((response: any) => {
        if (response.data) {
          dispatch(setUser(response.data));
          navigateTo("/home");
        } else {
          dispatch(
            setToast({
              toastType: "error",
              toastMsg: response.error,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: error.data,
          })
        );
        console.error(error);
      });
  };

  return (
    <div className="w-full">
      <div className="mb-4">
        <Link to="/">
          <Button fillMode="link" themeColor={"primary"} className="p-0">
            <span className="inline-flex items-center">
              <ArrowLeft className="w-4 h-4" />{" "}
              <span className="ml-1">Back</span>
            </span>
          </Button>
        </Link>
      </div>
      <h3 className="text-2xl font-kanit mb-8">Sign in with Email</h3>
      <Form
        initialValues={loginCredentials}
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement className="mb-4">
            <fieldset className={"k-form-fieldset"}>
              <FieldWrapper>
                <div className="k-form-field-wrap">
                  <div className="relative loginField">
                    <Field
                      name={"email"}
                      type={"email"}
                      component={EmailInputWithValidation}
                      label={"Email ID"}
                      placeholder={"Enter Email ID"}
                      labelClassName="font-cabin font-medium mb-2 text-[var(--kendo-neutral-160)]"
                      validator={EMAIL_VALIDATOR}
                    />
                    <Mail className="absolute top-[37px] -translate-y-1/2 right-2 w-4 h-4" />
                  </div>
                </div>
              </FieldWrapper>

              <FieldWrapper className="mb-4">
                <div className="k-form-field-wrap">
                  <div className="relative loginField">
                    <Field
                      name={"password"}
                      type={"password"}
                      component={Input}
                      label={"Password"}
                      placeholder={"Enter Password"}
                      labelClassName="font-cabin font-medium  mb-2 text-[var(--kendo-neutral-160)]"
                    />
                    <Lock className="absolute top-[30px] right-2 w-4 h-4" />
                  </div>
                </div>
              </FieldWrapper>
            </fieldset>

            <div className="flex flex-wrap items-center justify-between">
              {/* <Checkbox label={"Remember me"} /> */}
              <Link
                to="/forgot-password"
                className="hover:text-[var(--kendo-primary-100)] font-medium"
              >
                Forgot Password?
              </Link>
            </div>

            <div className="k-form-buttons">
              <Button
                className="w-full"
                themeColor={"primary"}
                disabled={!formRenderProps.allowSubmit}
              >
                Sign In
              </Button>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
};

export default LoginForm;
