import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldWrapper, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import {
  Input
} from "@progress/kendo-react-inputs";
import { ArrowLeft, Lock } from 'lucide-react';
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IResetPassword } from "../../interfaces/auth.interface";
import authService from "../../services/auth.service";
import { setToast } from "../../store/features/toastSlice";
import { setUser } from "../../store/features/userSlice";
import { useAppDispatch } from "../../store/hooks/hooks";

const ResetPasswordForm: React.FC = () => {
  const [resetPasswordValues] = React.useState<IResetPassword>({
    password: "",
    confirmPassword: "",
  });

  const navigateTo = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const dispatch = useAppDispatch();

  const handleSubmit = (dataItem: { [name: string]: IResetPassword }) => {
    if(dataItem.password === dataItem.confirmPassword){
      //@ts-ignore
      authService.resetPassword(token, dataItem.password).then((response: any) => {
        if(response.data){
          dispatch(setUser(response.data));
          navigateTo("/home");
        }
      }).catch((error) => {
        console.error(error);
      });
    }else{
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: "Passwords are not matching",
        })
      );
    }
  };

  return (
    <div className="w-full">
      <div className="mb-4">
        <Link to="/">
          <Button fillMode="link" themeColor={"primary"} className="p-0">
            <span className="inline-flex items-center">
              <ArrowLeft className="w-4 h-4" /> <span className="ml-1">Back</span>
            </span>
          </Button>
        </Link>
      </div>
      <h3 className="text-2xl font-kanit mb-8">Create New Password</h3>

      <Form
        initialValues={resetPasswordValues}
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement className="mb-4">
            <fieldset className={"k-form-fieldset"}>
              <FieldWrapper>
                <div className="k-form-field-wrap">
                  <div className="relative loginField">
                    <Field
                      name={"password"}
                      type={"password"}
                      component={Input}
                      label={"New Password"}
                      placeholder={"New Password"}
                      labelClassName="font-cabin font-medium mb-2 text-[var(--kendo-neutral-160)]"
                    />
                    <Lock className="absolute top-[30px] right-2 w-4 h-4" />
                  </div>
                </div>
              </FieldWrapper>

              <FieldWrapper className="mb-4">
                <div className="k-form-field-wrap">
                  <div className="relative loginField">
                    <Field
                      name={"confirmPassword"}
                      type={"password"}
                      component={Input}
                      label={"Confirm Password"}
                      placeholder={"Confirm Password"}
                      labelClassName="font-cabin font-medium mb-2 text-[var(--kendo-neutral-160)]"
                    />
                    <Lock className="absolute top-[30px] right-2 w-4 h-4" />
                  </div>
                </div>
              </FieldWrapper>
            </fieldset>

            <div className="k-form-buttons">
              <Button
                className="w-full"
                themeColor={"primary"}
                disabled={!formRenderProps.allowSubmit}
              >
                Create Password
              </Button>
            </div>
          </FormElement>
        )}
      />
    </div>
  )
}

export default ResetPasswordForm;
