import { ShowColumnsFilter } from "../../component/global/ColumnMenu";
import { ObjectTypeActionCellPopup, SubContractorActionCellPopup } from "../../shared-components/custom-cells";
import { MIN_RESIZABLE_WIDTH_KENDO_TABLE } from "../../utils/utils";

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}

export const generateColumns = (fetchObjectTypes: any, handleEditClick: any, onColumnsSubmit: any, columnsExternal?: any, columnsMapping?: any, changed?: boolean): any => {
  // const isVisible = (field: string) => {
  //   if(!columnsMapping?.length) return false;
  //   if(columnsMapping.includes(field)){
  //     return true;
  //   }

  //   return false;
  // }
  const columns = [
    {
      title: "Name",
      field: "name",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu = {true}
        />
      ),
      cells: {
        data: (props: any) => (
          <ObjectTypeActionCellPopup {...props} handleEditClick={handleEditClick} fetchObjectTypes={fetchObjectTypes}/>
        )
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE
    },
    {
      title: "Code",
      field: "code",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu = {true}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE
    }
  ];

  return columns;
};
