import * as React from "react";
import {
  ComboBox,
  ComboBoxChangeEvent,
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { ISubContractor } from "../../../interfaces/subcontractor.interface";

export const SubconComboBoxCell = (
  props: GridCellProps & {
    subcontractors: ISubContractor[];
  }
) => {
  const handleChange = (e: ComboBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.id,
      });
    }
  };

  const { dataItem } = props;
  const field = "name" || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  return (
    <td>
      {dataItem.inEdit ? (
        <ComboBox
          style={{ width: "220px" }}
          onChange={handleChange}
          value={props.subcontractors.find((c) => c.id === dataValue)}
          data={props.subcontractors}
          dataItemKey="name"
          textField="name"
        />
      ) : dataValue == null ? (
        ""
      ) : (
        dataValue
      )}
    </td>
  );
};
