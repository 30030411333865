import { ILeaseRequest } from '../../interfaces/peopleflow/lease.interface';
import { api } from '../../lib';
import { setToast } from '../../store/features/toastSlice';
import { store } from '../../store/store/store';
import { getPaginationData } from '../../utils/utils';
import LoaderService from '../loader.service';

const Pagination = getPaginationData();

class LeaseService {
  async dashboardStats() {
    try {
      LoaderService.showLoader();
      const res = await api.get(
        `${process.env.REACT_APP_BASE_URL}/Dashboard/lease-stats`
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async createLease(leaseRequest: ILeaseRequest) {
    try {
      LoaderService.showLoader();
      const res = await api.post(
        `${process.env.REACT_APP_BASE_URL}/data/Leases`,
        leaseRequest
      );

      if (res.ok) {
        store.dispatch(
          setToast({
            toastType: 'success',
            toastMsg: 'Lease added successfully.',
          })
        );
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg: "Couldn't add the lease. Try again later",
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async updateLease(leaseRequest: ILeaseRequest) {
    try {
      LoaderService.showLoader();
      const res = await api.put(
        `${process.env.REACT_APP_BASE_URL}/data/Leases/${leaseRequest.Id}`,
        leaseRequest
      );

      if (res.ok) {
        store.dispatch(
          setToast({
            toastType: 'success',
            toastMsg: 'The lease data is updated successfully.',
          })
        );
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg: "Couldn't edit the lease. Try again later",
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async markIsHistory(id: number) {
    try {
      LoaderService.showLoader();
      const lease = await this.findLease(id.toString());

      if (lease.data) {
        const prev = lease.data;
        const res = await api.put(
          `${process.env.REACT_APP_BASE_URL}/data/Leases/${id}`,
          {
            ...prev,
            IsHistory: true,
          }
        );

        if (res.ok) {
          return 'Marked as history';
        }
      }
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async findLease(id: string) {
    try {
      LoaderService.showLoader();
      const res = await api.get(
        `${process.env.REACT_APP_BASE_URL}/data/Leases/${id}`
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async get(
    searchQuery = '',
    pageNumber = 1,
    pageSize = Pagination.initialDataState.take,
    showArchive: boolean,
    kendoFilter?: string
  ) {
    try {
      LoaderService.showLoader();

      let skip = Math.max((pageNumber - 1) * pageSize, 0);

      let filterParam = '';

      if (kendoFilter) {
        filterParam += kendoFilter ? `${kendoFilter} and ` : '';
      }

      const searchParam = searchQuery ? `${searchQuery} and ` : '';
      filterParam += showArchive
        ? `${searchParam}Status eq 'Cancelled' or Status eq 'Expired'`
        : `${searchParam}not(Status eq 'Cancelled' or Status eq 'Expired')`;
      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/data/LeaseMasters?$filter=${filterParam} and IsDeleted eq false&$skip=${skip}&$top=${pageSize}&$orderby=CreatedOn desc&$count=true`
      );

      if (res.ok) {
        return {
          data: res.data.value,
          total: res.data['@odata.count'],
          error: null,
        };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg: "Couldn't fetch data of leases.",
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getAll(showArchive?: boolean) {
    try {
      LoaderService.showLoader();

      const res: any = await api.get(
        `${
          process.env.REACT_APP_BASE_URL
        }/data/LeaseMasters?$filter=IsDeleted eq ${showArchive || false}`
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg: "Couldn't fetch data of leases.",
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async createLeaseAllocation(leaseId: number, employeeId: number) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/data/LeaseAllocations`,
        {
          LeaseId: leaseId,
          EmployeeId: employeeId,
        }
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }
      if (res.data.error.code === '400') {
        store.dispatch(
          setToast({
            toastType: 'error',
            toastMsg: res.data.error.message,
          })
        );
      }
      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg: "Couldn't fetch data of leases.",
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async deleteLeaseAllocation(Id: number) {
    try {
      LoaderService.showLoader();
      const res = await api.delete(
        `${process.env.REACT_APP_BASE_URL}/data/LeaseAllocations/${Id}`
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async fetchLeaseAllocation(leaseId: number) {
    try {
      LoaderService.showLoader();
      const res = await api.get(
        `${process.env.REACT_APP_BASE_URL}/data/LeaseAllocations`,
        {
          $filter: `LeaseId eq ${leaseId} and IsDeleted ne true`,
        }
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getHistory(
    parentId: number,
    pageNumber = 1,
    pageSize = Pagination.initialDataState.take,
    kendoFilter?: string
  ) {
    try {
      LoaderService.showLoader();
      let skip = Math.max((pageNumber - 1) * pageSize, 0);
      // let filterParam = "";

      if (kendoFilter) {
        skip = 0;
        // filterParam += kendoFilter ? `${kendoFilter} and ` : "";
      }

      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/data/LeaseHistories?$filter=ParentId eq ${parentId} and IsDeleted eq false&$skip=${skip}&$top=${pageSize}&$orderby=CreatedOn desc&$count=true`
      );

      if (res.ok) {
        return {
          data: res.data.value,
          total: res.data['@odata.count'],
          error: null,
        };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg: "Couldn't fetch data of lease history.",
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const leaseService = new LeaseService();

export default leaseService;
