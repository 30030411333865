import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToastState {
  toastType: "none" | "success" | "error" | "warning" | "info" | undefined;
  toastMsg: string;
}

const initialState: ToastState = {
  toastType: undefined,
  toastMsg: "",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToast: (state, action: PayloadAction<ToastState>) => {
      state.toastType = action?.payload?.toastType;
      state.toastMsg = action?.payload?.toastMsg;
    },
  },
});

export const { setToast } = toastSlice.actions;

export default toastSlice.reducer;
