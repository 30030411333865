import { Button } from "@progress/kendo-react-buttons";
import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";

const RecoverNotify = () => {
  return (
    <div className="w-full">
      <div className="mb-4">
        <Link to="/login">
          <Button fillMode="link" themeColor={"primary"} className="p-0">
            <span className="inline-flex items-center">
              <ArrowLeft className="w-4 h-4" /> <span className="ml-1">Back</span>
            </span>
          </Button>
        </Link>
      </div>
      <h3 className="text-2xl font-kanit mb-8">Recover your password</h3>
      <div className="mb-4">
        <p>A verification email has been sent to your email address.</p>
      </div>
    </div>
  )
}

export default RecoverNotify;
