import { State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  getSelectedState,
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import { useCallback, useEffect, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import SearchBox from "../../../component/ui/SearchBox";
import Filters from "../../../constants/filters";
import { IBBS, ILine } from "../../../interfaces/bbs.interface";
import { IProject } from "../../../interfaces/project.interface";
import projectSvc from "../../../services/project.service";
import { CustomDialogBox } from "../../../shared-components/dialog-box";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import { getDialogInfo } from "../../../utils/dialog.utils";
import { columnInterface, generateColumns } from "./ThirdLevelColumns";
import bbsSvc from "../../../services/bbs.service";
import { setToast } from "../../../store/features/toastSlice";
import { TreeViewDataItem } from "..";
import { setSelectedProject } from "../../../store/features/commonSlice";

const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);
let mappingChanged = false;

function ThirdLevelListing({
  treeView,
  setTreeView,
  distinctMembers,
  projects,
  fetchProjects,
}: {
  treeView: TreeViewDataItem[];
  setTreeView: React.Dispatch<React.SetStateAction<TreeViewDataItem[]>>;
  distinctMembers: any[];
  projects: IProject[];
  fetchProjects: any;
}) {
  const commonState = useAppSelector((state: RootState) => state.common);
  const dispatch = useAppDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [inputVal, setInputVal] = useState<string>("");
  const [showDialog, setShowDialog] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [gridData, setGridData] = useState<any>([]);
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});
  const [dataState, setDataState] = useState<State>({
    skip: 0,
    take: 10,
  });
  const [columnsMapping, setColumnsMapping] = useState<any>(null);
  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(onColumnsSubmit, null, columnsMapping, mappingChanged)
  );

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      if (
        newSelectedState &&
        selectedState &&
        JSON.stringify(newSelectedState) === JSON.stringify(selectedState)
      ) {
        setSelectedState({});
        return;
      }
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: HTMLInputElement = event.syntheticEvent
        .target as HTMLInputElement;
      const checked = checkboxElement.checked;
      const newSelectedState: { [id: string]: boolean | number[] } = {};
      gridData?.forEach((item: any) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length !== 1) {
      const filteredData = gridData.filter((data: any) =>
        data.name.toLowerCase().includes(value.toLowerCase())
      );
      setGridData(filteredData);
    }

    if (value === "") {
      setGridData(gridData);
    }
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  useEffect(() => {
    loadGridData();
  }, [commonState?.selectedProject]);

  const loadGridData = () => {
    if (commonState?.selectedProject?.event?.item) {
      bbsSvc
        .getByCode(
          commonState?.selectedProject?.event?.item.projectId,
          commonState?.selectedProject?.event?.item.levelCode,
          commonState?.selectedProject?.event?.item.subLevelCode
        )
        .then((res) => {
          var bbs = res.data as IBBS[];
          if (bbs) {
            var bbsIds = Array.from(new Set(bbs?.map((x) => x.id)));
            bbsSvc.getByIds(bbsIds).then((x) => {
              if (x.data) {
                setGridData(x.data);
                return;
              } else {
                setGridData([]);
                return;
              }
            });
          } else {
            setGridData([]);
          }
        });
    } else {
      setGridData([]);
    }
  };

  const setSelectedLevel = () => {
    if (
      projects?.length &&
      treeView?.length &&
      commonState?.selectedProject?.event?.item
    ) {
      const item = treeView.find(
        (p: any) =>
          p.projectId === commonState?.selectedProject?.event?.item?.projectId
      );
      if (item) {
        let newItem = JSON.parse(JSON.stringify(commonState.selectedProject));
        newItem.event.item = item;
        dispatch(setSelectedProject(newItem));
      }
    }
  };

  const handleEditLevel = async () => {
    const proj = projects.find(
      (p) => p.id === commonState?.selectedProject?.event?.item?.projectId
    );
    if (proj) {
      proj.levels[
        commonState?.selectedProject?.event?.item?.parentId
      ].subLevels[commonState?.selectedProject?.event?.item?.idx].name =
        inputVal;

      await projectSvc.update(proj?.id, proj).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Level updated successfully",
            })
          );
        }
        fetchProjects();
      });
    }
    setShowDialog(false);
    setInputVal("");
    loadGridData();
  };

  const handleDelete = async () => {
    if (commonState.selectedProject?.event?.item) {
      const proj = projects.find(
        (x) => x.id === commonState.selectedProject?.event?.item?.projectId
      );

      if (proj) {
        proj.levels[
          commonState?.selectedProject?.event?.item?.parentId
        ].subLevels.splice(commonState.selectedProject?.event?.item?.idx, 1);
        await projectSvc
          .update(commonState.selectedProject?.event?.item?.projectId, proj)
          .then((res) => {
            if (!res.error) {
              dispatch(
                setToast({
                  toastType: "success",
                  toastMsg: "Level deleted successfully",
                })
              );
            }
            fetchProjects();
            setSelectedLevel();
            loadGridData();
          });
      }
    }
  };

  const handleDeleteDialog = (action: string) => {
    if (action === "yes") {
      handleDelete();
    }
    toggleDeleteDialog();
  };

  const toggleDeleteDialog = () => {
    setModalVisible(!modalVisible);
  };

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("delete")}
          onToggleDeleteDialog={handleDeleteDialog}
        />
      )}
      <Grid
        style={{
          height: "100%",
          maxHeight: "710px",
          maxWidth: "100vw",
          width: "100%",
        }}
        data={gridData?.map((item: any) => ({
          ...item,
          [SELECTED_FIELD]: selectedState[idGetter(item)],
        }))}
        // {...dataState}
        // onDataStateChange={dataStateChange}
        // skip={page.skip}
        // take={page.take}
        // total={total}
        selectedField={SELECTED_FIELD}
        // pageable={{
        //   ...Pagination.pageSizeValue,
        //   pageSizeValue: pageSizeValue,
        // }}
        // onPageChange={pageChange}
        className={`customHeight employee`}
        resizable={Filters.resizeable}
        // selectable={{
        //   enabled: true,
        //   drag: true,
        //   mode: "single",
        // }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
      >
        <GridToolbar>
          <div className="flex gap-3 w-full items-center justify-between">
            <div className="flex gap-x-4 w-full">
              <div className="w-[250px]">
                <SearchBox
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </GridToolbar>
        {/* <GridColumn
          field={SELECTED_FIELD}
          width="50px"
          headerSelectionValue={
            gridData?.findIndex(
              (item: any) => !selectedState[idGetter(item)]
            ) === -1
          }
        /> */}
        {stateColumns &&
          stateColumns.length > 0 &&
          stateColumns.map((column: any, idx: number) => {
            if (column.show) {
              if (column.cells) {
                return (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    columnMenu={column.columnMenu}
                    minResizableWidth={200}
                    width={column.width}
                    cells={column.cells}
                    filter={column.filter ? column.filter : "text"}
                  />
                );
              }
              return (
                <Column
                  key={idx}
                  field={column.field}
                  title={column.title}
                  columnMenu={column.columnMenu}
                  minResizableWidth={200}
                  width={column.width}
                  filter={column.filter ? column.filter : "text"}
                />
              );
            }
          })}
      </Grid>
      {showDialog && (
        <Dialog
          width={350}
          title={"Edit Level"}
          onClose={() => setShowDialog(false)}
          className="custom-dialog"
        >
          <div className="my-4">
            <CustomLabel label="Level" required={true} />
            <Input
              type="text"
              placeholder={"Enter Name"}
              value={inputVal}
              onChange={(e: any) => {
                setInputVal(e.target.value);
              }}
            />
          </div>
          <DialogActionsBar>
            <div className="flex justify-end gap-3">
              <Button
                themeColor={"primary"}
                disabled={!inputVal}
                onClick={handleEditLevel}
              >
                Save
              </Button>
              <Button
                onClick={() => setShowDialog(false)}
                themeColor={"base"}
                fillMode="outline"
              >
                Cancel
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}

export default ThirdLevelListing;
