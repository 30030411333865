import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  NumericTextBoxChangeEvent,
  TextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import {
  ArrowLeft,
  Check,
  ChevronLeft,
  CircleUser,
  Eye,
  FolderSync,
  Pencil,
  UploadCloud,
  X,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import BBSDatePicker from "../../component/inputs/BBSDatePicker";
import BBSNumberField from "../../component/inputs/BBSNumberField";
import BBSTextField from "../../component/inputs/BBSTextField";
import DashboardMain from "../../component/layout/main/DashboardMain";
import PageTitle from "../../component/ui/PageTitle";
import { ITemplateMapping } from "../../interfaces/mapping.interface";
import templateMappingSvc from "../../services/templateMapping.service";
import { setToast } from "../../store/features/toastSlice";
import { useAppDispatch } from "../../store/hooks/hooks";

const CreateMapping = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selected, setSelected] = React.useState<number>(0);
  const [templateMapping, setTemplateMapping] = React.useState<null | any>(
    null
  );
  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});
  const [id, setId] = useState<string | null>(null);
  const [actionFor, setActionFor] = useState<string | null>("create");

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const actionParam = searchParams.get("action");
      const idParam = searchParams.get("id");
      if (actionParam) {
        setActionFor(actionParam);
      } else {
        setActionFor("view");
      }
      setId(idParam);
    } else {
      setActionFor("view");
    }
  }, [location]);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const handleChange = (
    e: TextBoxChangeEvent | DatePickerChangeEvent | NumericTextBoxChangeEvent,
    isDate?: boolean
  ) => {
    if (!e || !e.target) return;

    const { name, value } = e.target;
    if (name === null || name === undefined) return;

    const keys = name.split(".");

    const updateNestedState = (obj: any, keys: string[], value: any) => {
      const [currentKey, ...remainingKeys] = keys;

      if (remainingKeys.length === 0) {
        return { ...obj, [currentKey]: value };
      }

      if (!(currentKey in obj)) {
        obj[currentKey] = {};
      }

      obj[currentKey] = updateNestedState(
        obj[currentKey],
        remainingKeys,
        value
      );

      return { ...obj };
    };

    setTemplateMapping((prevSubContractor: any) => {
      return updateNestedState({ ...prevSubContractor }, keys, value);
    });
  };

  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  const createSubContractor = () => {
    if (templateMapping?.templateHeaderMapping.sheetNumber === null) {
      templateMapping.templateHeaderMapping.sheetNumber = "1";
    }
    templateMappingSvc
      .create({ ...templateMapping, isEnabled: true })
      .then((res: any) => {
        if (res.data) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "mapping added successfully",
            })
          );
          setTemplateMapping(res.data);
          setActionFor("edit");
          navigate(`/settings/create-mapping?action=edit&id=${res.data.id}`);
        }
      });
  };

  const editSubContractor = () => {
    if (!templateMapping?.id) return;
    templateMappingSvc
      .update(templateMapping.id, { ...templateMapping, isEnabled: true })
      .then((res: any) => {});
  };

  const validateSave = () => {
    return Object.values(invalidFields).every((field) => !field);
  };

  useEffect(() => {
    if (id) {
      templateMappingSvc.getById(id).then((res: any) => {
        if (res.data) {
          setTemplateMapping(res.data);
        }
      });
    }
  }, [id]);

  return (
    <DashboardMain>
      <div className="py-5 px-8 flex-1 flex flex-col">
        <div className="grid  gap-x-5 gap-y-5 grid-cols-2  mb-5">
          <div className="card p-3 col-span-2">
            <div className="grid grid-cols-2">
              <div className="flex items-center gap-2">
                {actionFor === "edit" && (
                  <PageTitle icon={FolderSync} pageTitle={"Edit"} />
                )}
                {actionFor === "view" && (
                  <PageTitle icon={FolderSync} pageTitle={"View"} />
                )}
                {actionFor === "view" && (
                  <p className="inline-flex items-center gap-1 text-sm font-medium text-txtPrimary">
                    <Eye className="w-4 h-4" strokeWidth={1} />
                  </p>
                )}
                {actionFor === "edit" && (
                  <p className="inline-flex items-center gap-1 text-sm font-medium text-txtPrimary">
                    <Pencil className="w-4 h-4" strokeWidth={1} />
                  </p>
                )}
                {actionFor === "create" && (
                  <p className="inline-flex items-center gap-1 text-lg font-medium text-txtPrimary">
                    <FolderSync strokeWidth={1} size="20" className="mr-2" />
                    New Mapping
                  </p>
                )}
              </div>
              {actionFor === "view" && (
                <>
                  <div className="ml-auto inline-flex">
                    <div className="border-r border-neutral-30 px-3">
                      <p className="text-[14px] font-bold">Mapping</p>
                      <p className="text-[12px]">
                        {templateMapping?.mappingName}
                      </p>
                    </div>
                    <div className="border-r border-neutral-30 px-3">
                      <Button
                        type="button"
                        themeColor={"light"}
                        className="bg-[#F5F5F5] border border-[#E2E8F0] px-3 w-24"
                        onClick={() => navigate(`/settings/mappings`)}
                      >
                        <ChevronLeft strokeWidth={0.8} />
                        <span className="inline-flex items-center gap-2">
                          Back
                        </span>
                      </Button>
                    </div>
                    <div className="border-r border-neutral-30 last-of-type:border-none px-3">
                      <Button
                        themeColor={"primary"}
                        className="border border-[#E2E8F0] py-2 w-24"
                        onClick={() =>
                          navigate(
                            `/settings/create-mapping?action=edit&id=${id}`
                          )
                        }
                      >
                        <span className="inline-flex items-center gap-2">
                          <Pencil className="w-5 h-5" strokeWidth={0.8} /> Edit
                        </span>
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <TabStrip
          selected={selected}
          onSelect={handleSelect}
          className="dependentsTabs flex-1"
          keepTabsMounted={true}
          // renderAllContent={true}
        >
          <TabStripTab
            // disabled={!subContractor?.EmployeeId}
            title={
              <div className="flex items-center gap-3">
                <UploadCloud size={16} strokeWidth={1} /> Mapping Info
              </div>
            }
          >
            <div className="grid grid-cols-1 gap-5 pb-4">
              <div className="card p-3">
                <h3 className="text-txtPrimary text-lg font-medium font-kanit tracking-[-0.04px] mb-4">
                  Basic Info
                </h3>
                <div className="grid grid-cols-4 gap-3">
                  <div>
                    <BBSTextField
                      name="mappingName"
                      value={templateMapping?.mappingName}
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Mapping Name"
                      required={true}
                      placeholder="Enter mapping name"
                      disabled={actionFor === "view"}
                      regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                      error="Invalid Name"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.sheetNumber"
                      value={
                        templateMapping?.templateHeaderMapping?.sheetNumber ??
                        "1"
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Sheet Number"
                      required={false}
                      placeholder="Enter sheet number"
                      disabled={actionFor === "view"}
                      error="Invalid Sheet Number"
                      flexDir="row"
                    />
                  </div>
                </div>
              </div>
              <div className="card p-3">
                <h3 className="text-txtPrimary text-lg font-medium font-kanit tracking-[-0.04px] mb-4">
                  Header Mapping
                </h3>
                <div className="grid grid-cols-2 gap-y-3 gap-x-10">
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.projectName"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.projectName
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Project Name"
                      required={false}
                      placeholder="Enter cell e.g., B5"
                      disabled={actionFor === "view"}
                      error="Invalid Project Name"
                      flexDir="row"
                    />
                  </div>
                  <div></div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.wbs"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.wbs
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="WBS"
                      required={false}
                      placeholder="Enter cell e.g., B6"
                      disabled={actionFor === "view"}
                      error="Invalid WBS"
                      flexDir="row"
                    />
                  </div>
                  <div></div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.name"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.name
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="BBS Name"
                      required={false}
                      placeholder="Enter cell e.g., B7"
                      disabled={actionFor === "view"}
                      error="Invalid BBS Name"
                      flexDir="row"
                    />
                  </div>
                  <div></div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.no"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.no
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="BBS Number"
                      required={false}
                      placeholder="Enter cell e.g., B8"
                      disabled={actionFor === "view"}
                      error="Invalid BBS Number"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.revNo"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.revNo
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="BBS Revision Number"
                      required={false}
                      placeholder="Enter cell e.g., D8"
                      disabled={actionFor === "view"}
                      error="Invalid BBS Revision Number"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.shopDrawNo"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.shopDrawNo
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Shop Drawing Number"
                      required={false}
                      placeholder="Enter cell e.g., B9"
                      disabled={actionFor === "view"}
                      error="Invalid Shop Drawing Number"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.shopDrawRevNo"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.shopDrawRevNo
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Shop Drawing Revision Number"
                      required={false}
                      placeholder="Enter cell e.g., D9"
                      disabled={actionFor === "view"}
                      error="Invalid Shop Drawing Revision Number"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.zone"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.zone
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Zone"
                      required={false}
                      placeholder="Enter cell e.g., B10"
                      disabled={actionFor === "view"}
                      error="Invalid zone"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.objectType"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.objectType
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Object Type"
                      required={false}
                      placeholder="Enter cell e.g., D10"
                      disabled={actionFor === "view"}
                      error="Invalid zone"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.pour"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.pour
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Pour"
                      required={false}
                      placeholder="Enter cell e.g., B11"
                      disabled={actionFor === "view"}
                      error="Invalid pour"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.preparedBy"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.preparedBy
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Prepared By"
                      required={false}
                      placeholder="Enter cell e.g., D11"
                      disabled={actionFor === "view"}
                      error="Invalid zone"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.boqCode"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.boqCode
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="BOQ Code"
                      required={false}
                      placeholder="Enter cell e.g., B12"
                      disabled={actionFor === "view"}
                      error="Invalid BOQ Code"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.preparedOn"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.preparedOn
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Prepared On"
                      required={false}
                      placeholder="Enter cell e.g., D12"
                      disabled={actionFor === "view"}
                      error="Invalid Cell"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.detailingOff"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.detailingOff
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Detailing Office"
                      required={false}
                      placeholder="Enter cell e.g., B13"
                      disabled={actionFor === "view"}
                      error="Invalid Detailing Office"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.checkedBy"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.checkedBy
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Checked By"
                      required={false}
                      placeholder="Enter cell e.g., D13"
                      disabled={actionFor === "view"}
                      error="Invalid Checked By"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.discipline"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.discipline
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Discipline"
                      required={false}
                      placeholder="Enter cell e.g., B14"
                      disabled={actionFor === "view"}
                      error="Invalid Discipline"
                      flexDir="row"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templatePropertyMapping.checkedOn"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templatePropertyMapping?.checkedOn
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Checked On"
                      required={false}
                      placeholder="Enter cell e.g., D14"
                      disabled={actionFor === "view"}
                      error="Invalid Cell"
                      flexDir="row"
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab
            // disabled={!subContractor?.EmployeeId}
            title={
              <div className="flex items-center gap-3">
                <UploadCloud size={16} strokeWidth={1} /> Line Mapping
              </div>
            }
          >
            <div className="grid grid-cols-1 gap-5 pb-4">
              <div className="card p-3">
                {/* <h3 className="text-txtPrimary text-lg font-medium font-kanit tracking-[-0.04px] mb-4">
                  Line Mapping
                </h3> */}
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.lineStartRow"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.lineStartRow
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Enter Line Start Row"
                      required={false}
                      placeholder="Enter Row number e.g., 17"
                      disabled={actionFor === "view"}
                      flexDir="row"
                      error="Invalid Cell"
                    />
                  </div>
                </div>
              </div>
              <div className="card p-3">
                <h3 className="text-txtPrimary text-lg font-medium font-kanit tracking-[-0.04px] mb-4">
                  Member Details
                </h3>

                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Property
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Column
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Offset
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Seperator
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Array Index
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Start Index
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Text Length
                    </h4>
                  </div>
                </div>

                <hr className="my-4" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Enter Member Name
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.name.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.name?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder={
                        actionFor === "view" ? "" : "Enter Column Name"
                      }
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.name.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.name?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.name.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.name?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.name.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.name?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Array Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.name.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.name?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.name.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.name?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Number In Each
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.noInEach.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noInEach?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.name.noInEach.offSet"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noInEach?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.name.noInEach.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noInEach?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.name.noInEach.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noInEach?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Array Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.name.noInEach.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noInEach?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.name.noInEach.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noInEach?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-1 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Details
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.detail.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.detail?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.detail.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.detail?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.detail.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.detail?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.detail.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.detail?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.detail.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.detail?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.detail.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.detail?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Total Number
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.totalNo.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.totalNo?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.totalNo.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.totalNo?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.totalNo.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.totalNo?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.totalNo.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.totalNo?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Array Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.totalNo.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.totalNo?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.totalNo.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.totalNo?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Bar Mark
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.mark.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.mark?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.mark.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.mark?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.mark.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.mark?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.mark.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.mark?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.mark.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.mark?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.mark.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.mark?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Length (MM)
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.length.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.length?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.length.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.length?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.length.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.length?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.length.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.length?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.length.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.length?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.length.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.length?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Type
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.type.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.type?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.type.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.type?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.type.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.type?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.type.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.type?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.type.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.type?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.type.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.type?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Shape Code
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.shapeCode.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.shapeCode?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.shapeCode.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.shapeCode?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.shapeCode.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.shapeCode?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.shapeCode.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.shapeCode?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.shapeCode.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.shapeCode?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.shapeCode.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.shapeCode?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Size
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.size.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.size?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.size.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.size?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.size.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.size?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.size.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.size?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.size.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.size?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.size.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.size?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Weight In KG
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.weight.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.weight?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.weight.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.weight?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.weight.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.weight?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.weight.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.weight?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.weight.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.weight?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.weight.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.weight?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Rev Number
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.barRev.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.barRev?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.barRev.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.barRev?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.barRev.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.barRev?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.barRev.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.barRev?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.barRev.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.barRev?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.barRev.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.barRev?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Remarks
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.remarks.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.remarks?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.remarks.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.remarks?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.remarks.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.remarks?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.remarks.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.remarks?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.remarks.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.remarks?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.remarks.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.remarks?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Number Of Member
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.noOfMem.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noOfMem?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.noOfMem.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noOfMem?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.noOfMem.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noOfMem?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.noOfMem.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noOfMem?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.noOfMem.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noOfMem?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.noOfMem.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.noOfMem?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Quantity
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.qty.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.qty?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.qty.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.qty?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.qty.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.qty?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.qty.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.qty?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.qty.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.qty?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.qty.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.qty?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />

                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Comments
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.comments.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.comments?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.comments.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.comments?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.comments.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.comments?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.comments.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.comments?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.comments.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.comments?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.comments.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.comments?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
              </div>
              <div className="card p-3">
                <h3 className="text-txtPrimary text-lg font-medium font-kanit tracking-[-0.04px] mb-4">
                  Dimensions
                </h3>
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Property
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Column
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Offset
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Seperator
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Array Index
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Start Index
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      Text Length
                    </h4>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      A
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimA.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimA?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimA.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimA?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimA.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimA?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimA.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimA?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimA.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimA?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimA.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimA?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      B
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimB.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimB?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimB.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimB?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimB.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimB?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimB.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimB?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimB.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimB?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimB.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimB?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      C
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimC.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimC?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimC.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimC?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimC.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimC?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimC.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimC?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimC.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimC?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimC.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimC?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      D
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimD.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimD?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimD.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimD?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimD.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimD?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimD.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimD?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimD.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimD?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimD.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimD?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      E
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimE.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimE?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimE.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimE?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimE.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimE?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimE.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimE?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimE.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimE?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimE.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimE?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      F
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimF.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimF?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimF.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimF?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimF.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimF?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimF.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimF?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimF.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimF?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimF.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimF?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      G
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimG.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimG?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimG.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimG?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimG.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimG?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimG.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimG?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimG.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimG?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimG.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimG?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      H
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimH.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimH.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimH.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimH.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimH.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimH.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      H2
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimH2.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH2?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimH2.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH2?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimH2.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH2?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimH2.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH2?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimH2.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH2?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimH2.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimH2?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      I
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimI.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimI?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimI.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimI?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimI.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimI?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimI.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimI?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimI.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimI?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimI.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimI?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      J
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimJ.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimJ?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimJ.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimJ?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimJ.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimJ?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimJ.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimJ?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimJ.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimJ?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimJ.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimJ?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      K
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimK.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimK?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimK.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimK?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimK.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimK?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimK.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimK?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimK.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimK?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimK.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimK?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      O
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimO.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimO?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimO.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimO?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimO.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimO?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimO.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimO?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimO.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimO?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimO.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimO?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-7 gap-y-3 gap-x-4 items-center">
                  <div>
                    <h4 className="text-sm font-cabin block font-medium mb-1 text-txtPrimary">
                      R
                    </h4>
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimR.col"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimR?.col
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Column Name"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimR.offset"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimR?.offset
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Offset"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="templateHeaderMapping.templateLineMapping.dimR.separator"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimR?.separator
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter Separator"
                      disabled={actionFor === "view"}
                      error="Invalid separator "
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimR.arrIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimR?.arrIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter ArrIndex"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimR.startIndex"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimR?.startIndex
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter start Index"
                      disabled={actionFor === "view"}
                    />
                  </div>
                  <div>
                    <BBSNumberField
                      name="templateHeaderMapping.templateLineMapping.dimR.textLength"
                      value={
                        templateMapping?.templateHeaderMapping
                          ?.templateLineMapping?.dimR?.textLength
                      }
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label=""
                      required={false}
                      placeholder="Enter text length"
                      disabled={actionFor === "view"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabStripTab>
        </TabStrip>
        {actionFor !== "view" && (
          <div className="bg-white px-8 py-6 gap-2 flex justify-end fixed bottom-0 z-50 right-0 w-full shadow-elevation12">
            <div className="inline-flex gap-2">
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                onClick={() => navigate("/settings/mappings")}
              >
                <span className="inline-flex items-center gap-2">
                  <X className="w-4 h-4" strokeWidth={1} />
                  Close
                </span>
              </Button>
              {actionFor === "create" && (
                <Button
                  className="border border-[#E2E8F0] px-4 py-2 font-medium"
                  themeColor={"primary"}
                  onClick={createSubContractor}
                  disabled={!validateSave()}
                >
                  <span className="inline-flex items-center gap-2">
                    <Check className="w-4 h-4" strokeWidth={1} />
                    Save
                  </span>
                </Button>
              )}
              {actionFor === "edit" && (
                <Button
                  className="border border-[#E2E8F0] px-4 py-2 font-medium"
                  themeColor={"primary"}
                  onClick={editSubContractor}
                  disabled={!validateSave()}
                >
                  <span className="inline-flex items-center gap-2">
                    <Check className="w-4 h-4" strokeWidth={1} />
                    Save
                  </span>
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </DashboardMain>
  );
};

export default CreateMapping;
