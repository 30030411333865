import { Ripple } from '@progress/kendo-react-ripple';
import "@progress/kendo-theme-fluent/dist/all.scss";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';
import Startup from './component/Startup';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Ripple>
    <Router>
      <Provider store={store}>
        <Startup />
      </Provider>
    </Router>
  </Ripple>
);

reportWebVitals();
