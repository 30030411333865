import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  NumericTextBoxChangeEvent,
  TextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useEffect, useRef, useState } from "react";
import DashboardMain from "../../component/layout/main/DashboardMain";
import PageTitle from "../../component/ui/PageTitle";
import { IStandard } from "../../interfaces/standard.interface";
import standardSvc from "../../services/standard.service";
import BarSizes from "./components/BarSizes";
import BarTypes from "./components/BarTypes";
import Bars from "./components/Bars";
import Standards from "./components/Standards";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { ISearchFilter } from "../../interfaces/searchFilter.interface";
import {
  Album,
  Check,
  ChevronLeft,
  Pencil,
  Tally2,
  UploadCloud,
  X,
} from "lucide-react";
import BBSTextField from "../../component/inputs/BBSTextField";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch } from "../../store/hooks/hooks";
import { setToast } from "../../store/features/toastSlice";

function StandardDetails() {
  const selectedStandard = useRef<IStandard | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [standards, setStandards] = useState<IStandard[]>([]);
  const [standardsTotal, setStandardsTotal] = useState<number | null>(null);
  const [barSizes, setBarSizes] = useState([] as any);
  const [barTypes, setBarTypes] = useState([] as any);
  const [bars, setBars] = useState([] as any);
  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});
  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  const handleChange = (
    e: TextBoxChangeEvent | DatePickerChangeEvent | NumericTextBoxChangeEvent
  ) => {};

  const tabTitles = ["Standards", "Bar Types", "Bar Sizes", "Bars"];
  const dispatch = useAppDispatch();
  const fetchStandards = async (
    searchQuery = "",
    filters?: CompositeFilterDescriptor | null,
    sort?: any,
    pageNumber = 0,
    pageSize = 20
  ) => {
    let searchFilters: ISearchFilter[] = [];
    if (searchQuery) {
      searchFilters = [
        { field: "Name", operator: "contains", value: searchQuery },
        { field: "Abbreviation", operator: "contains", value: searchQuery },
      ];
    }
    const res = await standardSvc.get(
      searchFilters,
      filters,
      sort,
      pageNumber,
      pageSize
    );
    if (res.data) {
      setStandardsTotal(res.total);
      setStandards(res.data);
    }
  };

  const createNestedProperty = async (data: any, key: keyof IStandard) => {
    if (key === "barTypes" || key === "barSizes" || key === "bars") {
      let standard = standards.find(
        (x) => x.id === selectedStandard.current?.id
      );
      if (standard) {
        if (key === "barTypes") {
          standard[key].push({ ...data });
        } else {
          standard[key].push(data);
        }
        await standardSvc.update(standard.id, standard).then((res) => {
          if (!res.error) {
            dispatch(
              setToast({
                toastType: "success",
                toastMsg: key + " added successfully",
              })
            );
          }
        });
        setBarSizes(
          standard?.barSizes?.map((x, index) => ({ ...x, idx: index }))
        );
        setBarTypes(
          standard?.barTypes?.map((x, index) => ({ ...x, idx: index }))
        );
        setBars(standard?.bars?.map((x, index) => ({ ...x, idx: index })));
        fetchStandards();
      }
    }
  };

  const editNestedProperty = async (data: any, key: keyof IStandard) => {
    if (key === "barTypes" || key === "barSizes" || key === "bars") {
      let standard = standards.find(
        (x) => x.id === selectedStandard.current?.id
      );
      if (standard) {
        const idx = data.idx;
        standard[key][idx] = data;
        await standardSvc.update(standard.id, standard).then((res) => {
          if (!res.error) {
            dispatch(
              setToast({
                toastType: "success",
                toastMsg: key + " updated successfully",
              })
            );
          }
        });
        setBarSizes(
          standard?.barSizes?.map((x, index) => ({ ...x, idx: index }))
        );
        setBarTypes(
          standard?.barTypes?.map((x, index) => ({ ...x, idx: index }))
        );
        setBars(standard?.bars?.map((x, index) => ({ ...x, idx: index })));
        fetchStandards();
      }
    }
  };

  const deleteNestedProperty = async (data: any, key: keyof IStandard) => {
    if (key === "barTypes" || key === "barSizes" || key === "bars") {
      let standard = standards.find(
        (x) => x.id === selectedStandard.current?.id
      );
      if (standard) {
        const idx = data.idx;
        standard[key].splice(idx, 1);
        await standardSvc.update(standard.id, standard).then((res) => {
          if (!res.error) {
            dispatch(
              setToast({
                toastType: "success",
                toastMsg: key + " deleted successfully",
              })
            );
          }
        });
        setBarSizes(
          standard?.barSizes?.map((x, index) => ({ ...x, idx: index }))
        );
        setBarTypes(
          standard?.barTypes?.map((x, index) => ({ ...x, idx: index }))
        );
        setBars(standard?.bars?.map((x, index) => ({ ...x, idx: index })));
        fetchStandards();
      }
    }
  };

  useEffect(() => {
    fetchStandards();
  }, []);

  const [action, setAction] = useState<string | null>(null);
  const [standardForm, setStandardForm] = useState<IStandard | null>(null);

  const handleStandardAction = async (
    action: string,
    standardData?: IStandard | null
  ) => {
    if (action === "create") {
      setBarSizes([]);
      setBarTypes([]);
      setBars([]);
      setStandardForm(null);
    }

    if (action === "edit") {
      setBarSizes(standardData?.barSizes?.map((x, idx) => ({ ...x, idx })));
      setBarTypes(standardData?.barTypes?.map((x, idx) => ({ ...x, idx })));
      setBars(standardData?.bars?.map((x, idx) => ({ ...x, idx })));
      selectedStandard.current = standardData!;
      setStandardForm(standardData!);
    }

    if (action === "read") {
      setBarSizes(standardData?.barSizes?.map((x, idx) => ({ ...x, idx })));
      setBarTypes(standardData?.barTypes?.map((x, idx) => ({ ...x, idx })));
      setBars(standardData?.bars?.map((x, idx) => ({ ...x, idx })));
      selectedStandard.current = standardData!;
      setStandardForm(standardData!);
    }

    if (action === "delete") {
      await standardSvc.delete(standardData?.id!);
      fetchStandards();
      setActiveTab(0);
      return;
    }
    setAction(action);
  };

  const handleStandardChange = (e: any) => {
    setStandardForm((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const editStandard = async () => {
    const currStandard = standards.find(
      (x) => x.id === selectedStandard.current?.id
    );
    if (currStandard) {
      currStandard.name = standardForm?.name!;
      currStandard.abbreviation = standardForm?.abbreviation!;
    }
    setBarSizes(currStandard?.barSizes?.map((x, idx) => ({ ...x, idx })));
    setBarTypes(currStandard?.barTypes?.map((x, idx) => ({ ...x, idx })));
    setBars(currStandard?.bars?.map((x, idx) => ({ ...x, idx })));
    await standardSvc
      .update(selectedStandard.current?.id!, currStandard)
      .then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "standard updated successfully",
            })
          );
        }
      });
    fetchStandards();
  };

  const createStandard = async () => {
    const res = await standardSvc.create(standardForm!);
    if (res.data) {
      //@ts-ignore
      selectedStandard.current = res.data;
      setAction("edit");
      fetchStandards();
    }
  };
  useEffect(() => {
    if (action === null) {
      setActiveTab(0);
    }
  }, [action]);
  return (
    <DashboardMain>
      <div className="xl:p-5 p-3 flex-1 flex flex-col LookUpTabs">
        <div className="card mb-5 p-3 md:flex hidden gap-x-2">
          {action && (
            <Button
              type="button"
              themeColor={"light"}
              className="border-none p-0 font-small self-center"
              onClick={() => setAction(null)}
            >
              <ChevronLeft strokeWidth={0.8} />
            </Button>
          )}
          {action === "create" && (
            <PageTitle icon={Album} pageTitle={"Create Standard"} />
          )}
          {action === "edit" && (
            <div className="self-center">
              <PageTitle icon={Album} pageTitle={"Edit Standard"} />
            </div>
          )}
          {(!action || action === "read") && (
            <div className="self-center">
              <PageTitle icon={Album} pageTitle={"Standards"} />
            </div>
          )}
          {(action === "edit" || action === "read") && (
            <div className="ml-auto text-left self-center border-r border-neutral-30 px-3">
              <p className="text-[14px] font-bold">Standard</p>
              <p className="text-[12px]">{standardForm?.name}</p>
            </div>
          )}
          {action === "read" && (
            <Button
              themeColor={"primary"}
              className="border border-[#E2E8F0] ml-2 h-30 w-24"
              onClick={() => handleStandardAction("edit", standardForm)}
            >
              <span className="inline-flex items-center gap-2">
                <Pencil className="w-4 h-4" /> Edit
              </span>
            </Button>
          )}
        </div>
        {action === null && (
          <Standards
            fetchStandards={fetchStandards}
            standards={standards}
            total={standardsTotal || 0}
            handleValidation={handleValidation}
            handleStandardAction={handleStandardAction}
          />
        )}
        {action && (
          <TabStrip
            selected={activeTab}
            onSelect={(e) => setActiveTab(e.selected)}
            className="dependentsTabs flex-1"
            keepTabsMounted={true}
            // renderAllContent={true}
          >
            <TabStripTab
              // disabled={!subContractor?.EmployeeId}
              title={
                <div className="flex items-center gap-3">Standard Info</div>
              }
            >
              <div className="grid gap-4 grid-cols-1 pb-4 border-b">
                <div className="card p-3">
                  <div className="pb-3">
                    <BBSTextField
                      name="name"
                      value={standardForm?.name}
                      onChange={handleStandardChange}
                      onValidate={handleValidation}
                      label="Name"
                      required={true}
                      placeholder="Enter Name"
                      disabled={(action as string) === "read"}
                      regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                      error="Invalid Name"
                      className="max-w-[400px]"
                    />
                  </div>
                  <div>
                    <BBSTextField
                      name="abbreviation"
                      value={standardForm?.abbreviation}
                      onChange={handleStandardChange}
                      onValidate={handleValidation}
                      label="Abbreviation"
                      required={false}
                      placeholder="Enter Abbreviation"
                      disabled={(action as string) === "read"}
                      error="Invalid Code"
                      className="max-w-[400px]"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white px-4 py-6 gap-2 flex items-center justify-start">
                <div className="inline-flex gap-2">
                  <Button
                    className="border border-[#E2E8F0] px-4 font-small"
                    onClick={() => setAction(null)}
                  >
                    <span className="inline-flex items-center gap-2">
                      <X className="w-4 h-4" strokeWidth={1} />
                      Close
                    </span>
                  </Button>
                  {(action as string) === "create" && (
                    <Button
                      className="border border-[#E2E8F0] px-4 font-small"
                      themeColor={"primary"}
                      onClick={createStandard}
                      disabled={
                        !standardForm?.name || !standardForm?.abbreviation
                      }
                    >
                      <span className="inline-flex items-center gap-2">
                        <Check className="w-4 h-4" strokeWidth={1} />
                        Save
                      </span>
                    </Button>
                  )}
                  {action === "edit" && (
                    <Button
                      className="border border-[#E2E8F0] px-4 py-2 font-medium"
                      themeColor={"primary"}
                      onClick={editStandard}
                      disabled={
                        !standardForm?.name || !standardForm?.abbreviation
                      }
                    >
                      <span className="inline-flex items-center gap-2">
                        <Check className="w-4 h-4" strokeWidth={1} />
                        Save
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            </TabStripTab>
            <TabStripTab
              // disabled={!subContractor?.EmployeeId}
              title={<div className="flex items-center gap-3">Bar Types</div>}
              disabled={action === "create"}
            >
              <BarTypes
                standards={standards}
                barTypes={barTypes}
                setBarTypes={setBarTypes}
                total={barTypes?.length || 0}
                handleValidation={handleValidation}
                handleCreate={createNestedProperty}
                handleEdit={editNestedProperty}
                handleDelete={deleteNestedProperty}
                action={action}
              />
            </TabStripTab>
            <TabStripTab
              // disabled={!subContractor?.EmployeeId}
              title={<div className="flex items-center gap-3">Bar Sizes</div>}
              disabled={action === "create"}
            >
              <BarSizes
                standards={standards}
                barSizes={barSizes}
                setBarSizes={setBarSizes}
                total={barSizes?.length || 0}
                handleValidation={handleValidation}
                handleCreate={createNestedProperty}
                handleEdit={editNestedProperty}
                handleDelete={deleteNestedProperty}
                action={action}
              />
            </TabStripTab>
            <TabStripTab
              // disabled={!subContractor?.EmployeeId}
              title={<div className="flex items-center gap-3">Bars</div>}
              disabled={action === "create"}
            >
              <Bars
                standards={standards}
                bars={bars}
                setBars={setBars}
                total={bars?.length || 0}
                handleChange={handleChange}
                actionFor={"edit"}
                handleValidation={handleValidation}
                handleCreate={createNestedProperty}
                handleEdit={editNestedProperty}
                handleDelete={deleteNestedProperty}
                action={action}
              />
            </TabStripTab>
          </TabStrip>
        )}
      </div>
    </DashboardMain>
  );
}

export default StandardDetails;
