import React from "react";
import DashboardMain from "../../../../component/layout/main/DashboardMain";
import PageTitle from "../../../../component/ui/PageTitle";
import ContractorListing from "./ContractorListing";
import { Users } from "lucide-react";

const Contractor = () => {
  return (
    <DashboardMain>
      <div className="content-lookups xl:p-5 p-3 overflow-hidden">
        <div className="card mb-5 p-3">
          <PageTitle icon={Users} pageTitle="Title" />
        </div>
        <ContractorListing />
      </div>
    </DashboardMain>
  );
};

export default Contractor;
