import { Button } from "@progress/kendo-react-buttons";
import { Plus } from "lucide-react";

function BtnAddLevel({ onClickHandler }: { onClickHandler: () => void }) {
  return (
    <Button
      className="border border-[#E2E8F0] k-button-solid-primary"
      onClick={onClickHandler}
    >
      <div className="flex gap-x-2">
        <Plus className="text-[#484F61] self-center p-0 w-4 h-4 text-white" />
        <p className="self-center pr-2">Add New Level</p>
      </div>
    </Button>
  );
}

export default BtnAddLevel;
