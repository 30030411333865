import { api } from '../lib';
import { BaseService } from './base.service';
import LoaderService from './loader.service';

class CompanyService extends BaseService {
  constructor() {
    super('Company');
  }

  downloadLogo = async (filePath: string, name: string) => {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/Company/download`,
        { FilePath: filePath },
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'image/png',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.removeChild(link);
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async upload(file: File, fileName: string) {
    try {
      LoaderService.showLoader();
      const formData = new FormData();
      formData.append('File', file);

      const res: any = await api.post(`${process.env.REACT_APP_BASE_URL}/api/Company/upload`, formData);

      if (res.ok) {
        return { data: res?.data, error: null }
      }

      return { data: null, error: res.data }
    } catch (error) {
      return { data: null, error: error }
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const companySvc = new CompanyService();

export default companySvc;
