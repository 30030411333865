import { api } from '../lib';
import { setToast } from '../store/features/toastSlice';
import { store } from '../store/store/store';
import { getPaginationData } from '../utils/utils';
import { BaseService } from './base.service';
import LoaderService from './loader.service';

class UserService extends BaseService {
  constructor() {
    super('Users');
  }
}

const userSvc = new UserService();

export default userSvc;
