import { api } from "../lib";
import { setToast } from "../store/features/toastSlice";
import { store } from "../store/store/store";
import LoaderService from "./loader.service";

class SharedService {
  async restore(entityName: string, entityId: number) {
    try {
      LoaderService.showLoader();
      const existing = await api.get(`${process.env.REACT_APP_BASE_URL}/data/${entityName}/${entityId}`);
      if(existing.data){
        const res = await api.put(`${process.env.REACT_APP_BASE_URL}/data/${entityName}/${entityId}`, {...existing.data, IsDeleted: false});

        if (res.ok) {
          return { data: res.data, error: null }
        }
        return { data: null, error: res.data }
      }

    } catch (error) {
      store.dispatch(
        setToast({
          toastType: "error",
          toastMsg: "Couldn't restore entity. Try again later",
        })
      );
      return { data: null, error: error }
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const sharedSvc = new SharedService();

export default sharedSvc;
