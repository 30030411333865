import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";
import {
  ItemRenderProps,
  TreeView,
  TreeViewCheckChangeEvent,
  TreeViewCheckChangeSettings,
  TreeViewCheckDescriptor,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
  TreeViewOperationDescriptor,
  handleTreeViewCheckChange,
  processTreeViewItems,
} from "@progress/kendo-react-treeview";
import { debounce } from "lodash";
import {
  BriefcaseIcon,
  Copy,
  CopyMinus,
  CopyPlus,
  FolderKanban,
  List,
  MoreHorizontal,
  Pen,
  Plus,
  SquarePen,
  Trash2Icon,
  User,
} from "lucide-react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import CustomLabel from "../../component/global/RequiredMark";
import SearchBox from "../../component/ui/SearchBox";
import { IBBS, ILine } from "../../interfaces/bbs.interface";
import { IProject, Level } from "../../interfaces/project.interface";
import { ISearchFilter } from "../../interfaces/searchFilter.interface";
import bbsSvc from "../../services/bbs.service";
import projectSvc from "../../services/project.service";
import { CustomDialogBox } from "../../shared-components/dialog-box";
import { setSelectedProject } from "../../store/features/commonSlice";
import { useAppSelector } from "../../store/hooks/hooks";
import { RootState } from "../../store/store/store";
import { getDialogInfo } from "../../utils/dialog.utils";
import _ from "lodash";
import { setToast } from "../../store/features/toastSlice";

type TreeViewDataItem = {
  text: string;
  expanded?: boolean;
  checked?: boolean;
  selected?: boolean;
  items?: TreeViewDataItem[];
  level?: number;
  levelCode?: string;
  subLevelCode?: string;
  projectId?: string;
};

const AddLevelContext = createContext({
  addLevel: (data: any, action: string) => {},
  handleManageProject: (data: any, action: string) => {},
  handleManageDetailingOffices: (data: any, action: string) => {},
  handleManageSubcontractors: (data: any, action: string) => {},
  showManageBBS: false,
});

interface IProps {
  projects: IProject[];
  setProjects: React.Dispatch<React.SetStateAction<IProject[]>>;
  setSelectedLevelBBS: React.Dispatch<React.SetStateAction<IBBS | null>>;
  treeView: TreeViewDataItem[];
  setTreeView: React.Dispatch<React.SetStateAction<TreeViewDataItem[]>>;
  setDistinctMembers: React.Dispatch<React.SetStateAction<ILine[]>>;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  showAddProjectDrawer: boolean;
  setShowAddProjectDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selected: boolean;
  toggleSelected: () => void;
  showEditProjectDrawer: boolean;
  setShowEditProjectDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showAddDetailingOfficeDrawer: boolean;
  setShowAddDetailingOfficeDrawer: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  showAddSubcontractorDrawer: boolean;
  setShowAddSubcontractorDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  projectToEdit: (IProject & { id: string }) | null;
  setProjectToEdit: React.Dispatch<
    React.SetStateAction<(IProject & { id: string }) | null>
  >;
  prevProject: (IProject & { id: string }) | null;
  setPrevProject: React.Dispatch<
    React.SetStateAction<(IProject & { id: string }) | null>
  >;
  showManageBBS: boolean;
}

const TreeViewMain = ({
  projects,
  setProjects,
  setSelectedLevelBBS,
  treeView,
  setTreeView,
  setDistinctMembers,
  expanded,
  setExpanded,
  showAddProjectDrawer,
  setShowAddProjectDrawer,
  selected,
  toggleSelected,
  showEditProjectDrawer,
  setShowEditProjectDrawer,
  showAddDetailingOfficeDrawer,
  setShowAddDetailingOfficeDrawer,
  showAddSubcontractorDrawer,
  setShowAddSubcontractorDrawer,
  projectToEdit,
  setProjectToEdit,
  prevProject,
  setPrevProject,
  showManageBBS,
}: IProps) => {
  const debouncedSearch = useRef(
    debounce((value: string) => fetchProjects(value), 500)
  ).current;

  const commonState = useAppSelector((state: RootState) => state.common);

  const dispatch = useDispatch();

  const selectedLevel = useRef<any>(null);
  const selectedAction = useRef<any>("");
  const [showDialog, setShowDialog] = useState(false);
  const [inputVal, setInputVal] = useState<string>("");
  const [check, setCheck] = useState<string[] | TreeViewCheckDescriptor>([]);
  const [expand, setExpand] = useState<TreeViewOperationDescriptor>({
    ids: ["Item2"],
    idField: "text",
  });
  const [select, setSelect] = useState<string[]>([""]);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [showAddNewChild, setShowAddNewChild] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");

  const [modalVisible, setModalVisible] = useState(false);
  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    // if (value?.length !== 1) {
    //   debouncedSearch(value);
    // }
  };

  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    const ids: string[] = expand.ids ? expand.ids.slice() : [];
    const index: number = ids.indexOf(event.item.text);

    index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
    setExpand({ ids, idField: "text" });
  };

  const handleCollapseAll = () => {
    setExpand({ ids: [], idField: "text" });
  };

  const handleExpandAll = () => {
    const allTexts = treeView.flatMap((item: TreeViewDataItem) => {
      return [
        item.text,
        ...(item.items
          ? item.items.flatMap((subItem) => {
              return [
                subItem.text,
                ...(subItem.items
                  ? subItem.items.flatMap((subSubItem) => {
                      return subSubItem.text;
                    })
                  : []),
              ];
            })
          : []),
      ];
    });
    setExpand({ ids: allTexts, idField: "text" });
  };

  const onItemClick = (event: TreeViewItemClickEvent) => {
    if (
      event.nativeEvent.target.className ===
      "k-button k-button-md k-button-flat k-button-flat-base k-rounded-md w-full iconBtn px-1"
    ) {
      return;
    }
    if (
      event.nativeEvent.target.className ===
      "k-button k-button-md k-button-flat k-button-flat-base k-rounded-md p-2 bg-none outline-none k-ripple-target"
    ) {
      return;
    }
    if (
      event.nativeEvent.target.className !==
      "self-center cursor-pointer hover:text-primaryMain whitespace-pre-wrap line-clamp-1 flex-1 project-text"
    ) {
      return;
    }

    if (showManageBBS && event.item.level === 3) return;

    setSelect([event.itemHierarchicalIndex]);
    selectedLevel.current = event.item;
    const settings: TreeViewCheckChangeSettings = {
      singleMode: true,
      checkChildren: false,
      checkParents: false,
    };
    const eventData = {
      item: event.item,
      itemHierarchicalIndex: event.itemHierarchicalIndex,
    };

    if (
      eventData.itemHierarchicalIndex ===
      commonState?.selectedProject?.event?.itemHierarchicalIndex
    ) {
      // dispatch(setSelectedProject(null));
    } else {
      dispatch(
        setSelectedProject({
          event: eventData,
          check,
          treeView,
          settings,
          index: eventData.itemHierarchicalIndex,
        })
      );
    }

    if (event.item.level === 1) {
      setShowAddNew(true);
      setShowAddNewChild(true);
      return;
    }
    if (event.item.level === 2) {
      setShowAddNew(true);
      setShowAddNewChild(false);
      return;
    }
    if (event.item.level === 3) {
      setShowAddNew(false);
      setShowAddNewChild(true);
      return;
    }
  };

  const onCheckChange = (event: TreeViewCheckChangeEvent) => {
    if (!event.nativeEvent.target.closest(".k-treeview")) {
      if (event.nativeEvent.code === "Space") return;
    }
    selectedLevel.current = event.item;
    const settings: TreeViewCheckChangeSettings = {
      singleMode: true,
      checkChildren: false,
      checkParents: false,
    };
    const eventData = {
      item: event.item,
      itemHierarchicalIndex: event.itemHierarchicalIndex,
    };
    dispatch(
      setSelectedProject({
        event: eventData,
        check,
        treeView,
        settings,
      })
    );
    if (event.item.level === 1) {
      setShowAddNew(true);
      setShowAddNewChild(true);
      return;
    }
    if (event.item.level === 2) {
      setShowAddNew(true);
      setShowAddNewChild(false);
      return;
    }
    if (event.item.level === 3) {
      setShowAddNew(false);
      setShowAddNewChild(true);
      return;
    }
  };

  useEffect(() => {
    if (!commonState.selectedProject) {
      setSelect([""]);
      return;
    }
    setSelect([commonState.selectedProject.event.itemHierarchicalIndex]);
  }, [commonState.selectedProject]);

  useEffect(() => {
    if (commonState?.fetchProjects) {
      fetchProjects();
    }
  }, [commonState?.fetchProjects]);

  const fetchProjects = useCallback(
    (searchValue?: string) => {
      let searchFilters: ISearchFilter[] = [];
      if (searchValue) {
        searchFilters = [
          { field: "name", operator: "contains", value: searchValue },
        ];
      }
      projectSvc.get(searchFilters).then((res) => {
        if (res.data) {
          const treeViewData = mapProjectsToTreeView(res.data);
          setTreeView(treeViewData);
          setProjects(res.data);
        }
      });
    },
    [setProjects]
  );

  //Refreshing selecting project state
  useEffect(() => {
    if (
      projects?.length &&
      treeView?.length &&
      commonState?.selectedProject?.event?.item
    ) {
      const level = commonState?.selectedProject?.event?.item?.level;
      switch (level) {
        case 0:
          const item = treeView.find(
            (p: any) =>
              p.projectId ===
              commonState?.selectedProject?.event?.item?.projectId
          );
          if (
            item &&
            item.items?.length !==
              commonState?.selectedProject?.event?.item?.items?.length
          ) {
            let newItem = JSON.parse(
              JSON.stringify(commonState.selectedProject)
            );
            newItem.event.item = item;
            dispatch(setSelectedProject(newItem));
          }
          break;
        case 1:
          const parentItem: any = treeView.find(
            (p: any) =>
              p.projectId ===
              commonState?.selectedProject?.event?.item?.projectId
          );
          if (parentItem) {
            const item = parentItem.items.find(
              (l: any) =>
                l.levelCode ===
                commonState?.selectedProject?.event?.item?.levelCode
            );
            if (
              item &&
              item.items?.length !==
                commonState?.selectedProject?.event?.item?.items?.length
            ) {
              let newItem = JSON.parse(
                JSON.stringify(commonState.selectedProject)
              );
              newItem.event.item = item;
              dispatch(setSelectedProject(newItem));
            }
          }
          break;
      }
    }
  }, [treeView, projects, commonState?.selectedProject]);

  const prevProjectsLength = useRef(0);
  useEffect(() => {
    const treeViewData = mapProjectsToTreeView(projects);
    setTreeView(treeViewData);
    if (projects.length && prevProjectsLength.current !== projects.length) {
      prevProjectsLength.current = projects.length;
    }
  }, [projects]);

  const mapProjectsToTreeView = (projects: IProject[]): TreeViewDataItem[] => {
    return projects.map((project) => ({
      text: project.name + "-Code-" + project.code,
      level: 0,
      projectId: project.id,
      standardId: project.standardId,
      detailingOffices: project.detailingOffices,
      subcontractorIds: project.subcontractorIds,
      barLength: project.barLength,
      items: project.levels.map((level, levelIdx) => ({
        text: level.name + "-Code-" + level.code,
        level: 1,
        projectId: project.id,
        idx: levelIdx,
        barLength: project.barLength,
        standardId: project.standardId,
        levelCode: level.code,
        levelName: level.name,
        createdOn: level.createdOn,
        projectName: project.name,
        items: level.subLevels.map((subLevel, subLevelIdx) => ({
          text: subLevel.name + "-Code-" + subLevel.code,
          level: 2,
          projectId: project.id,
          parentId: levelIdx,
          idx: subLevelIdx,
          barLength: project.barLength,
          standardId: project.standardId,
          subLevelCode: subLevel.code,
          subLevelName: subLevel.name,
          levelCode: level.code,
          levelName: level.name,
          projectName: project.name,
          createdOn: subLevel.createdOn,
          allMembers: subLevel.members
            .filter((x) => x.isActive === true)
            .map((member, shopDrawingIdx) => {
              return member;
            }),
          items: subLevel.members
            .filter((x) => x.isActive === true)
            .map((member, shopDrawingIdx) => ({
              code: member.code,
              text: member.name,
              level: 3,
              projectId: project.id,
              parentId: levelIdx,
              subLevelId: subLevelIdx,
              idx: shopDrawingIdx,
              barLength: project.barLength,
              standardId: project.standardId,
              subLevelCode: subLevel.code,
              levelCode: level.code,
              projectName: project.name,
              levelName: level.name,
              subLevelName: subLevel.name,
            })),
        })),
      })),
    }));
  };

  const addLevel = (data: any, action: string) => {
    if (action === "edit") {
      setInputVal(data.text?.split("-Code-")[0]);
    }
    selectedLevel.current = data;
    selectedAction.current = action;
    if (action !== "delete") {
      toggleDialog();
    } else {
      toggleDeleteDialog();
      // deleteLevel();
    }
  };

  const handleDeleteDialog = (action: string) => {
    if (action === "yes") {
      deleteLevel();
    }
    toggleDeleteDialog();
  };

  const toggleDeleteDialog = () => {
    setModalVisible(!modalVisible);
  };

  const handleManageProject = (data: any, action: string) => {
    const proj: any = projects?.find((p) => p.id === data.projectId);
    setProjectToEdit(proj);
    setPrevProject(proj);
    setShowEditProjectDrawer(!showEditProjectDrawer);
  };
  const handleManageDetailingOffices = (data: any, action: string) => {
    const proj: any = projects?.find((p) => p.id === data.projectId);
    setProjectToEdit(proj);
    setShowAddDetailingOfficeDrawer(!showAddDetailingOfficeDrawer);
  };
  const handleManageSubcontractors = (data: any, action: string) => {
    const proj: any = projects?.find((p) => p.id === data.projectId);
    setProjectToEdit(proj);
    setShowAddSubcontractorDrawer(!showAddSubcontractorDrawer);
  };

  const toggleDialog = useCallback(() => {
    setShowDialog((prevShowDialog) => !prevShowDialog);
  }, []);

  const deleteLevel = async () => {
    const proj = projects.find((p) => p.id === selectedLevel.current.projectId);
    if (proj) {
      if (selectedLevel.current.level === 1) {
        proj.isLevelDeleted = true;
        proj.levelCode = selectedLevel.current.levelCode;
      }

      if (selectedLevel.current.level === 2) {
        proj.isSubLevelDeleted = true;
        proj.levelCode = selectedLevel.current.levelCode;
        proj.subLevelCode = selectedLevel.current.subLevelCode;
      }

      if (selectedLevel.current.level === 3) {
        proj.isMemberDeleted = true;
        proj.memberLevelCode = selectedLevel.current.levelCode;
        proj.memberSubLevelCode = selectedLevel.current.subLevelCode;
        proj.memberCode = selectedLevel.current.text;
        proj.memberName = selectedLevel.current.text;
      }
      await projectSvc.update(proj?.id, proj).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Deleted successfully",
            })
          );

          fetchProjects();

          const item = treeView.find(
            (p: any) =>
              p.projectId ===
              commonState?.selectedProject?.event?.item?.projectId
          );
          if (
            item &&
            item.items?.length !==
              commonState?.selectedProject?.event?.item?.items?.length
          ) {
            let newItem = JSON.parse(
              JSON.stringify(commonState.selectedProject)
            );
            newItem.event.item = item;
            dispatch(setSelectedProject(newItem));
            setSelect([commonState?.selectedProject?.index.split("_")[0]]);
          }
        }
      });
    }
  };

  const handleSaveLevel = () => {
    switch (selectedAction.current) {
      case "new":
        createNewLevel();
        break;
      case "newChild":
        createNewChildLevel();
        break;
      case "edit":
        editLevel();
        break;
      default:
        break;
    }
  };

  const editLevel = async () => {
    var proj = projects.find((p) => p.id === selectedLevel.current.projectId);

    if (proj) {
      var projClone = JSON.parse(JSON.stringify(proj));

      if (selectedLevel.current.level === 1) {
        projClone.levels[selectedLevel.current.idx].name = inputVal;
      }
      if (selectedLevel.current.level === 2) {
        projClone.levels[selectedLevel.current.parentId].subLevels[
          selectedLevel.current.idx
        ].name = inputVal;
      }
      if (selectedLevel.current.level === 3) {
        projClone.levels[selectedLevel.current.parentId].subLevels[
          selectedLevel.current.subLevelId
        ].members[selectedLevel.current.idx].name = inputVal;

        projClone.isMemberRenamed = true;
        projClone.memberLevelCode = selectedLevel.current.levelCode;
        projClone.memberSubLevelCode = selectedLevel.current.subLevelCode;
        projClone.memberCode = selectedLevel.current.code;
        projClone.oldMemberName = selectedLevel.current.text;
        projClone.memberName = inputVal;
      }

      await projectSvc.update(projClone?.id, projClone).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Updated successfully",
            })
          );
          fetchProjects();
          setInputVal("");
          setShowDialog(false);
        }
      });
    }
  };

  const createNewChildLevel = async () => {
    const proj = projects.find((p) => p.id === selectedLevel.current.projectId);
    if (selectedLevel.current.level === 1) {
      const newLevel: any = {
        name: inputVal,
        code: crypto.randomUUID(),
        subLevels: [],
      };
      proj?.levels[selectedLevel.current.idx].subLevels.push(newLevel);
    }
    if (proj) {
      await projectSvc.update(proj?.id, proj).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Level added successfully",
            })
          );
          fetchProjects();
          setInputVal("");
          setShowDialog(false);
        } else {
        }
      });
    }
  };

  const createNewLevel = async () => {
    const proj = projects.find((p) => p.id === selectedLevel.current.projectId);

    if (selectedLevel.current.level === 0) {
      const newLevel: Level = {
        name: inputVal,
        code: crypto.randomUUID(),
        subLevels: [],
      };
      proj?.levels.push(newLevel);
    }
    if (selectedLevel.current.level === 1) {
      const newLevel = {
        name: inputVal,
        code: crypto.randomUUID(),
        members: [],
      };
      const level = proj?.levels[selectedLevel.current.idx];
      if (level) level.subLevels.push(newLevel);
    }
    if (proj) {
      await projectSvc.update(proj?.id, proj).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Level added successfully",
            })
          );
          fetchProjects();
          setInputVal("");
          setShowDialog(false);
        }
      });
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (commonState?.selectedProject) {
      setCheck(
        handleTreeViewCheckChange(
          commonState.selectedProject.event,
          commonState.selectedProject.check,
          commonState.selectedProject.treeView,
          commonState.selectedProject.settings
        )
      );
    }

    if (commonState?.selectedProject?.event?.item?.level === 2) {
      setDistinctMembers(
        commonState?.selectedProject?.event?.item?.allMembers || [] //.flat()
      );
    }
  }, [commonState?.selectedProject?.event?.item]);

  useEffect(() => {
    if (showAddProjectDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showAddProjectDrawer]);

  const getModalHeaderText = () => {
    if (selectedAction.current === "new") {
      if (selectedLevel.current.level === 0) {
        return "Add Level";
      }
      if (selectedLevel.current.level === 1) {
        return "Add Level";
      }
    }
    if (selectedAction.current === "newChild") {
      if (selectedLevel.current.level === 1) {
        return "Add Level";
      }
    }
    if (selectedAction.current === "edit") {
      if (selectedLevel.current.level === 1) {
        return "Edit Level";
      }
      if (selectedLevel.current.level === 2) {
        return "Edit Level";
      }
      if (selectedLevel.current.level === 3) {
        return "Edit Member Name";
      }
    }
  };

  const filteredTreeView = useMemo(() => {
    let filteredProjects = projects;

    if (searchValue.trim()) {
      const lowercasedSearch = searchValue.toLowerCase();
      filteredProjects = projects.filter(
        (project) =>
          project.name.toLowerCase().includes(lowercasedSearch) ||
          project.levels.some(
            (level) =>
              level.name.toLowerCase().includes(lowercasedSearch) ||
              level.subLevels.some(
                (subLevel) =>
                  subLevel.name.toLowerCase().includes(lowercasedSearch) ||
                  subLevel.members.some((member) =>
                    member.name.toLowerCase().includes(lowercasedSearch)
                  )
              )
          )
      );
    }

    return mapProjectsToTreeView(filteredProjects);
  }, [projects, searchValue]);

  return (
    <div className={`${selected ? "" : ""}`}>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("delete")}
          onToggleDeleteDialog={handleDeleteDialog}
        />
      )}
      {!selected && (
        <>
          <div className="px-4 flex justify-between w-full gap-x-2 min-h-[48px]">
            <SearchBox
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search.."
            />
            <Button
              title="Add New"
              className="border border-[#E2E8F0] rounded-md px-2 py-2 max-h-[40px]"
              onClick={() => {
                if (
                  commonState?.selectedProject?.event?.item?.level === 0 ||
                  !commonState?.selectedProject
                ) {
                  setShowAddProjectDrawer(!showAddProjectDrawer);
                } else if (
                  commonState?.selectedProject?.event?.item?.level != 3 &&
                  commonState?.selectedProject?.event?.item?.level != 2
                ) {
                  addLevel(selectedLevel.current, "new");
                }
              }}
            >
              <Plus className="text-[6px] text-[#484F61]" size="20" />
            </Button>
            <div className="flex gap-x-1 max-h-[40px]">
              <Button
                title="Collapse All"
                className="border border-[#E2E8F0] rounded-md px-2 py-2"
                onClick={handleCollapseAll}
              >
                <CopyMinus size="20" />
              </Button>
              <Button
                title="Expand All"
                className="border border-[#E2E8F0] rounded-md px-2 py-2"
                onClick={handleExpandAll}
              >
                <CopyPlus size="20" />
              </Button>
            </div>
          </div>
        </>
      )}
      {/* {selected && (
          <>
            <Button
              className="border border-[#E2E8F0] rounded-md px-2 py-0 max-h-[40px]"
              onClick={() => {
                if (
                  commonState?.selectedProject?.event?.item?.level === 0 ||
                  !commonState?.selectedProject
                ) {
                  setShowAddProjectDrawer(!showAddProjectDrawer);
                } else {
                  addLevel(selectedLevel.current, "new");
                }
              }}
            >
              <Plus className="text-[6px] text-[#484F61]" size={20} />
            </Button>
          </>
        )} */}

      {!selected && (
        <>
          <hr className="ml-[14px]" />
          <div className="w-full mt-2">
            <AddLevelContext.Provider
              value={{
                addLevel: (data: any, action: string) => addLevel(data, action),
                handleManageProject: (data: any, action: string) =>
                  handleManageProject(data, action),
                handleManageDetailingOffices: (data: any, action: string) =>
                  handleManageDetailingOffices(data, action),
                handleManageSubcontractors: (data: any, action: string) =>
                  handleManageSubcontractors(data, action),
                showManageBBS: showManageBBS,
              }}
            >
              <TreeView
                data={processTreeViewItems(filteredTreeView, {
                  select: select,
                  check: check,
                  expand: expand,
                })}
                size={"small"}
                expandIcons={true}
                onExpandChange={onExpandChange}
                // aria-multiselectable={true}
                onItemClick={onItemClick}
                // checkboxes={true}
                onCheckChange={onCheckChange}
                item={TreeViewItemCell}
              />
            </AddLevelContext.Provider>
            {showDialog && (
              <Dialog
                width={350}
                title={getModalHeaderText()}
                onClose={() => setShowDialog(false)}
                className="custom-dialog"
              >
                <div className="my-4">
                  <CustomLabel
                    label={getModalHeaderText()?.split(" ")[1] || ""}
                    required={true}
                  />
                  <Input
                    type="text"
                    placeholder={"Enter Name"}
                    value={inputVal}
                    onChange={(e: any) => {
                      setInputVal(e.target.value);
                    }}
                  />
                </div>
                <DialogActionsBar>
                  <div className="flex justify-end gap-3">
                    <Button
                      className="border border-[#E2E8F0] xpx-2 py-2 font-small"
                      themeColor={"primary"}
                      disabled={!inputVal}
                      onClick={handleSaveLevel}
                    >
                      Save
                    </Button>
                    <Button
                      className="border border-[#E2E8F0]"
                      onClick={() => {
                        setInputVal("");
                        setShowDialog(false);
                      }}
                      themeColor={"base"}
                      fillMode="outline"
                    >
                      Cancel
                    </Button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TreeViewMain;

const TreeViewItemCell: React.FC<ItemRenderProps> = (props) => {
  const {
    addLevel,
    handleManageProject,
    handleManageDetailingOffices,
    handleManageSubcontractors,
    showManageBBS,
  } = useContext(AddLevelContext);
  const anchor = useRef<HTMLButtonElement>(null);

  const [show, setShow] = useState(false);

  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShow(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        anchor.current &&
        !anchor.current.contains(event.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const onClick = useCallback(() => {
    setShow((prevShow) => !prevShow);
  }, []);

  const openDialog = (action: string) => {
    addLevel(props.item, action);
  };

  const canDelete = () => {
    if (props.item.level === 0) return false;
    if (props.item.level !== 2) {
      return true;
    }
    // if (props.item.level === 2) {
    //   if (props.item.items && props.item.items.length > 0) {
    //     return false;
    //   }
    // }

    return true;
  };

  return (
    <>
      <div className="text-left w-full">
        <div className="flex items-center gap-x-2 group flex-1 w-full p-1">
          <div
            className={`self-center ${
              showManageBBS && props.item.level === 3 ? "" : "cursor-pointer"
            } hover:text-primaryMain whitespace-pre-wrap line-clamp-1 flex-1 project-text`}
          >
            {props.item.level === 0 && (
              <img src="./images/ProjectIcon.png" className="" />
            )}
            {props.item.level === 1 && (
              <img src="./images/LevelIcon.png" className="" />
            )}
            {props.item.level === 2 && (
              <img src="./images/SubLevel.png" className="" />
            )}
            {props.item.level === 3 && (
              <List height={20} className="text-primaryMain" />
            )}

            {props.item.level === 3
              ? props.item.text
              : props.item.text?.split("-Code-")[0]}
          </div>
          {!showManageBBS && (
            <div className="text-left h-5">
              <>
                <button
                  className={`${"opacity-100 visible"}  cta_dropdown  transition-all duration-300 ${
                    show ? "active" : ""
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClick();
                  }}
                  ref={anchor}
                >
                  <MoreHorizontal strokeWidth={1} />
                </button>
                <Popup
                  anchor={anchor.current}
                  show={show}
                  popupClass={"w-[161px]"}
                  anchorAlign={{ horizontal: "right", vertical: "bottom" }}
                  popupAlign={{ horizontal: "right", vertical: "top" }}
                >
                  <div ref={popupRef}>
                    {props.item.level === 0 && (
                      <Button
                        className="w-full iconBtn px-1"
                        fillMode="flat"
                        onClick={(event) => {
                          event.stopPropagation();
                          setShow(!show);
                          openDialog("new");
                        }}
                      >
                        <span className="inline-flex items-center gap-2 w-full">
                          <CopyPlus className="w-4 h-4" />
                          <span className="flex-1 text-sm font-normal">
                            New Level
                          </span>
                        </span>
                      </Button>
                    )}
                    {props.item.level == 0 && (
                      <>
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={() => {
                            setShow(!show);
                            handleManageProject(props.item, "save");
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <FolderKanban className="w-4 h-4" />
                            <span className="flex-1 text-sm font-normal">
                              Manage Project
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={() => {
                            handleManageDetailingOffices(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <BriefcaseIcon className="w-4 h-4" />
                            <span className="flex-1 text-sm font-normal">
                              Detailing Offices
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={() => {
                            handleManageSubcontractors(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <User className="w-4 h-4" />
                            <span className="flex-1 text-sm font-normal">
                              Subcontractors
                            </span>
                          </span>
                        </Button>
                      </>
                    )}

                    {props.item.level === 1 && (
                      <Button
                        className="w-full iconBtn px-1"
                        fillMode="flat"
                        onClick={(event) => {
                          event.stopPropagation();
                          setShow(!show);
                          openDialog("newChild");
                        }}
                      >
                        <span className="inline-flex items-center gap-2 w-full">
                          <Copy className="w-4 h-4" />
                          <span className="flex-1 text-sm font-normal">
                            New Level
                          </span>
                        </span>
                      </Button>
                    )}
                    {props.item.level != 0 && (
                      <Button
                        className="w-full iconBtn px-1"
                        fillMode="flat"
                        onClick={(event) => {
                          event.stopPropagation();
                          setShow(!show);
                          openDialog("edit");
                        }}
                      >
                        <span className="inline-flex items-center gap-2 w-full">
                          <SquarePen className="w-4 h-4" />
                          <span className="flex-1 text-sm font-normal">
                            Rename
                          </span>
                        </span>
                      </Button>
                    )}
                    {canDelete() && (
                      <Button
                        className="w-full iconBtn px-1"
                        fillMode="flat"
                        onClick={(event) => {
                          event.stopPropagation();
                          setShow(!show);
                          openDialog("delete");
                        }}
                      >
                        <span className="inline-flex items-center gap-2 w-full">
                          <Trash2Icon className="w-4 h-4" />
                          <span className="flex-1 text-sm font-normal">
                            Delete
                          </span>
                        </span>
                      </Button>
                    )}
                  </div>
                </Popup>
              </>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
