import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  GridColumn as Column,
  Grid,
  GridItemChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { Plus, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import { ISubContractor } from "../../../interfaces/subcontractor.interface";
import projectSvc from "../../../services/project.service";
import subContractorSvc from "../../../services/subContractor.service";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import { CommandCell } from "./CommandCell";
import { SubconComboBoxCell } from "./SubconComboBoxCell";
const initialSubcontractor = {
  id: "",
};

const editField = "inEdit";

export const AddSubcontractors = ({
  showAddSubcontractorDrawer,
  setShowAddSubcontractorDrawer,
  projectToEdit,
  setProjectToEdit,
  fetchProjects,
}: any) => {
  const commonState = useAppSelector((state: RootState) => state.common);
  const [newSubcontractor, setSubcontractor] = useState(initialSubcontractor);

  const [subcontractors, setSubcontractors] = useState<ISubContractor[]>([]);

  const [projectSubcontractors, setProjectSubcontractors] = useState<any>([]);

  const fetchSubcontractors = () => {
    subContractorSvc.getAll().then((res) => {
      setSubcontractors(res.data);
    });
  };

  const validateSubcontractor = () => {
    if (!newSubcontractor) return false;
    if (!newSubcontractor.id) return false;

    return true;
  };

  const saveSubcontractor = async () => {
    try {
      const proj = projectToEdit;
      if (proj?.subcontractorIds) {
        var newSubcons = projectSubcontractors
          .filter((x: any) => x.inEdit === true)
          .map((x: any) => x.name);

        newSubcons.forEach((x: any) => {
          proj.subcontractorIds.push(x);
        });
      }
      setProjectToEdit(proj);

      await projectSvc.update(projectToEdit?.id!, projectToEdit);
      setProjectSubcontractors(
        subcontractors?.filter((item: any) =>
          projectToEdit?.subcontractorIds.includes(item.id)
        )
      );
      fetchProjects();
      setShowAddSubcontractorDrawer(!showAddSubcontractorDrawer);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSubcontractors();
  }, []);

  useEffect(() => {
    if (projectToEdit?.subcontractorIds) {
      setProjectSubcontractors(
        subcontractors?.filter((item: any) =>
          projectToEdit?.subcontractorIds.includes(item.id)
        )
      );
    }
  }, [subcontractors, projectToEdit]);

  const ActionCell = (props: any) => {
    const { edit, remove, add, discard, update, cancel, editField } = props;
    return (
      <CommandCell
        {...props}
        edit={edit}
        remove={remove}
        add={add}
        discard={discard}
        update={update}
        cancel={cancel}
        editField={editField}
      />
    );
  };

  const remove = (dataItem: any) => {
    const newData = [...projectSubcontractors];
    const index = newData.findIndex((x) => {
      return x.id === dataItem.id;
    });

    newData.splice(index, 1);
    setProjectSubcontractors(newData);
  };

  const add = (dataItem: any) => {
    const newData = [...projectSubcontractors];
    dataItem.inEdit = true;

    newData.push(dataItem);
    setProjectSubcontractors(newData);
  };

  const update = (dataItem: any) => {
    dataItem.inEdit = false;
  };
  const discard = () => {
    const newData = [...projectSubcontractors];
    newData.splice(0, 1);
    setProjectSubcontractors(newData);
  };

  const cancel = (dataItem: any) => {
    const originalItem = projectSubcontractors.find(
      (p: any) => p.id === dataItem.id
    );
    const newData = projectSubcontractors.map((item: any) =>
      item.name === originalItem?.name ? originalItem : item
    );
    if (newData) {
      setProjectSubcontractors(newData!);
    }
  };

  const enterEdit = (dataItem: any) => {};

  const itemChange = (event: GridItemChangeEvent) => {
    const newData: any = projectSubcontractors.map((item: any) => {
      return item.id === event.dataItem.id
        ? {
            ...item,
            [event.field || ""]: event.value,
          }
        : item;
    });
    setProjectSubcontractors(newData);
  };

  const addNew = () => {
    const newDataItem: any = {
      inEdit: true,
      name: "",
      id: projectSubcontractors.length,
    };
    setProjectSubcontractors([newDataItem, ...projectSubcontractors]);
  };
  // const initialRender = useRef(false);

  // useEffect(() => {
  //   if (initialRender.current === true || !showAddSubcontractorDrawer) return;
  //   let temp = [...projectSubcontractors];
  //   temp = temp.map((item: any, index: number) => {
  //     return {
  //       ...item,
  //       id: index,
  //     };
  //   });
  //   setProjectSubcontractors(temp);
  //   if (temp.length) {
  //     initialRender.current = true;
  //   }
  // }, [projectSubcontractors, showAddSubcontractorDrawer]);

  const commandCellProps = {
    edit: enterEdit,
    remove: remove,
    add: add,
    discard: discard,
    update: update,
    cancel: cancel,
    editField: editField,
  };

  return (
    <>
      <Drawer
        expanded={showAddSubcontractorDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500"
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() =>
              setShowAddSubcontractorDrawer(!showAddSubcontractorDrawer)
            }
          ></div>
          <div
            className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showAddSubcontractorDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Subcontractors</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() =>
                  setShowAddSubcontractorDrawer(!showAddSubcontractorDrawer)
                }
              >
                <X className="w-4 h-4" />{" "}
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div>
                  <Grid
                    pageable={false}
                    sortable={false}
                    filterable={false}
                    size={"small"}
                    groupable={false}
                    scrollable="none"
                    data={projectSubcontractors}
                    onItemChange={itemChange}
                    editField={editField}
                  >
                    <GridToolbar>
                      <Button
                        title="Add new"
                        themeColor={"primary"}
                        onClick={addNew}
                      >
                        Add new
                      </Button>
                    </GridToolbar>
                    <Column
                      field="name"
                      title="Name"
                      width={250}
                      cell={(props) => (
                        <SubconComboBoxCell
                          {...props}
                          subcontractors={subcontractors}
                          {...commandCellProps}
                        />
                      )}
                    />
                    <Column
                      cell={(props) => (
                        <ActionCell {...props} {...commandCellProps} />
                      )}
                      width="100px"
                    />
                  </Grid>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0]"
                onClick={() =>
                  setShowAddSubcontractorDrawer(!showAddSubcontractorDrawer)
                }
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                themeColor={"primary"}
                onClick={saveSubcontractor}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
