import React, { useEffect, useState } from "react";
import { IBBS } from "../../interfaces/bbs.interface";

function Members({ selectedRow }: { selectedRow: IBBS | null }) {
  const [distinctMembers, setDistinctMembers] = useState<string[]>([]);

  useEffect(() => {
    if (selectedRow?.lines) {
      const members = selectedRow.lines
        .filter((x) => x.isActive)
        .map((line) => line.name);
      const distinctMembers = Array.from(new Set(members));
      setDistinctMembers(distinctMembers);
    }
  }, [selectedRow]);

  return (
    <div className="py-3 max-h-[350px] overflow-y-auto">
      <div className="card shadow-elevation8 py-2 relative z-10 mt-5">
        <div className=" p-3">
          <h3 className="text-[20px] text-txtPrimary tracking-[-0.08px] font-kanit font-medium">
            Members
          </h3>
        </div>
        <div className="p-3 pt-0">
          {distinctMembers &&
            distinctMembers.length > 0 &&
            distinctMembers.map((member, index) => {
              return (
                <h4
                  key={index}
                  className="text-[14px] py-2 border-t border-neutral-30 text-txtPrimary tracking-[-0.10px] font-light"
                >
                  {member}
                </h4>
              );
            })}
          {distinctMembers && distinctMembers.length == 0 && (
            <h4 className="text-[14px] py-2 border-t border-neutral-30 text-txtPrimary tracking-[-0.10px] font-light">
              No Member Exists
            </h4>
          )}
        </div>
      </div>
    </div>
  );
}

export default Members;
