import { useEffect, useState } from "react";
import LoginEase from "../../component/onboarding/LoginEase";
import OnBoardingContainer from "../../component/ui/container/OnBoardingContainer";
import { useAppSelector } from "../../store/hooks/hooks";
import { RootState } from "../../store/store/store";

const Login = () => {
  const settingsState: any = useAppSelector(
    (state: RootState) => state.settings
  );

  const [showCustomLogin, setShowCustomLogin] = useState(false);

  useEffect(() => {
    if (!settingsState) return;
    const authData = settingsState.settings.auth;

    for (let auth of authData) {
      if (auth.type === "Custom" && auth.enabled) {
        setShowCustomLogin(true);
        break;
      }
    }
  }, [settingsState]);

  if (showCustomLogin) {
    return (
      <OnBoardingContainer>
        <LoginEase />
      </OnBoardingContainer>
    );
  }
  return <></>;
};

export default Login;
