import { api } from '../lib';
import { BaseService } from './base.service';
import LoaderService from './loader.service';

class DashboardService extends BaseService {
  constructor() {
    super('Dashboard');
  }

  async getStats() {
    try {
      LoaderService.showLoader();
      const res = await api.get(`/Home/get-stats`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async projectStats() {
    try {
      LoaderService.showLoader();
      const res = await api.get(`/Home/project-stats`);

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const shapesSvc = new DashboardService();

export default shapesSvc;
