import { Switch } from "@progress/kendo-react-inputs";
import { ShowColumnsFilter } from "../../component/global/ColumnMenu";
import {
  MappingActionCellPopup,
  MappingEnabledCell,
  SubContractorActionCellPopup,
} from "../../shared-components/custom-cells";
import { MIN_RESIZABLE_WIDTH_KENDO_TABLE } from "../../utils/utils";

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}

export const generateColumns = (
  onColumnsSubmit: any,
  columnsExternal?: any,
  fetchMappings?: any,
  handleEnableDisable?: any
): any => {
  const columns = [
    {
      title: "Name",
      field: "mappingName",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
        />
      ),
      cells: {
        data: (props: any) => (
          <MappingActionCellPopup {...props} fetchMappings={fetchMappings} />
        ),
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Active",
      field: "isEnabled",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
        />
      ),
      cells: {
        data: (props: any) => (
          <td>
            <Switch
              checked={props.dataItem.isEnabled}
              onLabel="Yes"
              offLabel="No"
              name="isEnabled"
              value={props.dataItem.isEnabled}
              onChange={(e) => handleEnableDisable(props.dataItem)}
            ></Switch>
          </td>
        ),
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
  ];

  return columns;
};
