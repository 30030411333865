import {
  AuthenticationResult,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { ReactNode, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CustomLoader from "./component/loader";
import Toast from "./component/toast/Toast";
import SSO from "./pages/SSO/SSO";
import Error404 from "./pages/error/Error404";
import ForgotPassword from "./pages/forgot-password";
import Login from "./pages/login";
import LoginHome from "./pages/login-form";
import Home from "./pages/home";
import Imports from "./pages/home/components/ImportBBS";
import CreateProject from "./pages/peopleflow/visa/projects/CreateProject";
import {
  default as Contractor,
  default as Projects,
} from "./pages/peopleflow/visa/subcontractors";
import RecoverEmail from "./pages/recover-notify";
import ResetPassword from "./pages/reset-password";
import Standards from "./pages/standards/StandardDetails";
import SubContractor from "./pages/subcontractor";
import CreateMapping from "./pages/templateMapping/CreateMapping";
import TemplateMapping from "./pages/templateMapping";
import authService from "./services/auth.service";
import { setUser } from "./store/features/userSlice";
import { useAppDispatch } from "./store/hooks/hooks";
import { isAuthorize } from "./utils/utils";
import Users from "./pages/users";
import ObjectTypes from "./pages/object-type";
import Companies from "./pages/company";
import Shapes from "./pages/shapes";
import { setToast } from "./store/features/toastSlice";

interface IAppProps {
  msalInstance: IPublicClientApplication;
  msalScope: string;
  settings: any;
}

function App({ msalInstance, msalScope, settings }: IAppProps) {
  const { instance } = useMsal();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const account = msalInstance.getActiveAccount();
  if (!account) {
    msalInstance.initialize();
  }

  useEffect(() => {
    msalInstance
      .handleRedirectPromise()
      .then((response: AuthenticationResult | null) => {
        if (response && response.account) {
          authService.verifyToken(response.accessToken).then((res: any) => {
            if (res.data) {
              dispatch(
                setUser({
                  user: res.data.user,
                  token: res.data.token,
                  refreshToken: res.data.refreshToken,
                })
              );
              navigate("/home", { replace: true });
            } else {
              dispatch(
                setToast({
                  toastType: "error",
                  toastMsg: "Failed to Login",
                })
              );
              return;
            }
          });
        }
      });

    const user = localStorage.getItem("user");
    const atToken = localStorage.getItem("atToken");
    const rtToken = localStorage.getItem("rtToken");

    if (user && atToken && rtToken) {
      dispatch(
        setUser({
          user: JSON.parse(user),
          token: atToken,
          refreshToken: rtToken,
        })
      );
      // navigate("/home");
    } else {
      const authData = settings.auth;
      let isCustomEnabled = false;
      for (let auth of authData) {
        if (auth.type === "Custom" && auth.enabled) {
          isCustomEnabled = true;
          break;
        }
      }
      if (isCustomEnabled) {
        navigate("/");
      } else {
        handleMsalLogin();
      }
    }
  }, []);

  const handleMsalLogin = () => {
    const loginRequest = { scopes: [msalScope] };
    instance.loginRedirect(loginRequest).catch((error) => console.error(error));
  };

  const ProtectedRoute = ({
    children,
    allowedRoles,
  }: {
    children: ReactNode;
    allowedRoles?: string[];
  }) => {
    if (!isAuthorize(allowedRoles)) {
      return <Navigate to="/" />;
    }

    const user = localStorage.getItem("user");
    if (user) {
      return <>{children}</>;
    }

    return <Navigate to="/" />;
  };
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    if (pathname === "/visa/reports") {
      document.body.classList.add("reports-page");
    } else {
      document.body.classList.remove("reports-page");
    }
    return () => {
      document.body.classList.remove("reports-page");
    };
  }, [location]);

  return (
    <>
      <Toast />
      <CustomLoader></CustomLoader>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/sso" element={<SSO />}></Route>
        <Route path="/login" element={<LoginHome />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/recover-email" element={<RecoverEmail />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/home" element={<Home />} />
        <Route path="/project-list" element={<Projects />} />
        <Route path="/create-project" element={<CreateProject />} />
        <Route path="/contractor-list" element={<Contractor />} />
        {/* <Route path="/imports" element={<Imports />} /> */}
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/settings/subcontractor" element={<SubContractor />} />
        <Route path="/settings/create-mapping" element={<CreateMapping />} />
        <Route path="/settings/standards" element={<Standards />} />
        <Route path="/settings/mappings" element={<TemplateMapping />} />
        <Route path="/settings/users" element={<Users />} />
        <Route path="/settings/object-types" element={<ObjectTypes />} />
        <Route path="/settings/companies" element={<Companies />} />
        <Route path="/settings/shapes" element={<Shapes />} />
        {/* <Route
          path="/lease/allocation"
          element={
            <ProtectedRoute allowedRoles={[LEASE_OFFICER, LEASE_ADMIN]}>
              <LeaseAllocation />
            </ProtectedRoute>
          }
        />
        */}
      </Routes>
    </>
  );
}

export default App;
