
import { useMsal } from "@azure/msal-react";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../store/hooks/hooks";
import { RootState } from "../../store/store/store";
// import { loginRequest } from "../../lib";

const LoginEase = () => {
  const { instance } = useMsal();

  const settingsState: any = useAppSelector((state: RootState) => state.settings)
  const [showCustomLogin, setShowCustomLogin] = useState(false);
  const [isEntraIdEnabled, setIsEntraIdEnabled] = useState(false);
  const [loginRequest, setLoginRequest] = useState<any>(null);

  useEffect(() => {
    if (!settingsState?.settings) return;
    const authData = settingsState.settings.auth;
    const customData = authData.find((item: any) => item.type === "Custom");

    if(customData?.enabled){
      setShowCustomLogin(true);
    }

    const entraIdData = authData.find((item: any) => item.type === "EntraID");

    if(entraIdData && entraIdData?.enabled){
      const frontendSettings = entraIdData.frontend;
      const loginRequest = {
        scopes: [frontendSettings.backendScope]
      }
      setLoginRequest(loginRequest);
      setIsEntraIdEnabled(true);
    }
  }, [settingsState])


  const handleLoginRedirect = () => {
    if(!loginRequest) return;
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <div className="w-full">
      <form action="" className="w-full">
        <h3 className="text-2xl font-kanit mb-8">Sign in with ease</h3>
        <div className="grid gap-6 ">
          <div className="grid gap-3">
            {isEntraIdEnabled && <Button
              onClick={handleLoginRedirect}
              className="buttons-container-button"
              imageUrl="./images/microsoft.png"
              imageAlt="entra"
            >Continue with Microsoft Account
            </Button>}
            {showCustomLogin && <Link to="/login" className="w-full block">
              <Button
                type="button"
                className="buttons-container-button w-full"
                imageUrl="./images/mail.svg"

              >Continue with Email
              </Button>
            </Link>}
          </div>
        </div>

      </form>
    </div>
  )
}

export default LoginEase
