import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools/pager/Pager";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  GridColumn as Column,
  Grid,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { debounce } from "lodash";
import { Plus } from "lucide-react";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import Export from "../../component/global/Export";
import SearchBox from "../../component/ui/SearchBox";
import Filters from "../../constants/filters";
import IPageState from "../../interfaces/page.interface";
import { ISearchFilter } from "../../interfaces/searchFilter.interface";
import templateMappingSvc from "../../services/templateMapping.service";
import { getPaginationData } from "../../utils/utils";
import { columnInterface, generateColumns } from "./columns";
import { ITemplateMapping } from "../../interfaces/mapping.interface";
import { useNavigate } from "react-router-dom";
import { setToast } from "../../store/features/toastSlice";

let mappingChanged = false;

export const Listing = () => {
  const navigate = useNavigate();

  const _exporter = createRef<ExcelExport>();
  const excelExport = () => {
    if (_exporter.current) {
      fetchAllMappings();
    }
  };

  const Pagination = getPaginationData();

  const debouncedSearch = useRef(
    debounce((value: string) => fetchMappings(value), 500)
  ).current;

  const [allMappings, setAllMappings] = useState([]);
  const [columnsMapping, setColumnsMapping] = useState<any>(null);
  const [dataState, setDataState] = useState<State>(
    Pagination.initialDataState
  );
  const [page, setPage] = useState<IPageState>(Pagination.initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >();
  const [searchValue, setSearchValue] = useState("");
  const [templateMappings, setTemplateMappings] = useState<ITemplateMapping[]>(
    []
  );
  const [total, setTotal] = useState(0);

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      onColumnsSubmit,
      columnsState,
      fetchMappings,
      handleEnableDisable
    );
    setStateColumns(cols);
  }

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? templateMappings.length : event.page.take;

    fetchMappings(searchValue, null, event.page.skip, event.page.take);
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length !== 1) {
      debouncedSearch(value);
    }
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
    fetchMappings(
      searchValue,
      event.dataState.filter,
      event.dataState.sort,
      page.skip,
      page.take
    );
  };

  function handleEnableDisable(data: ITemplateMapping) {
    templateMappingSvc
      .update(data.id, {
        ...data,
        isEnabled: data.isEnabled ? false : true,
      })
      .then((x) => {
        fetchMappings(searchValue);
      });
  }

  useEffect(() => {
    if (templateMappings.length) return;
    fetchMappings(searchValue);
  }, []);

  const fetchMappings = useCallback(
    async (
      searchQuery = "",
      filter?: CompositeFilterDescriptor | null,
      sort?: any,
      pageNumber = 0,
      pageSize = Pagination.initialDataState.take
    ) => {
      let searchFilters: ISearchFilter[] = [];
      if (searchQuery) {
        searchFilters = [
          { field: "MappingName", operator: "contains", value: searchQuery },
          { field: "Code", operator: "contains", value: searchQuery },
        ];
      }
      const res = await templateMappingSvc.get(
        searchFilters,
        filter,
        sort,
        pageNumber,
        pageSize
      );
      if (res.data) {
        setTemplateMappings(res.data);
        setTotal(res.data.total);
      }
    },
    []
  );

  const fetchAllMappings = () => {
    templateMappingSvc.getAll().then((res) => {
      setAllMappings(res.data);
    });
  };

  useEffect(() => {
    if (!templateMappings?.length || !columnsMapping) return;
    const cols = generateColumns(
      onColumnsSubmit,
      null,
      fetchMappings,
      handleEnableDisable
    );
    setStateColumns(cols);
  }, [templateMappings, columnsMapping]);

  useEffect(() => {
    if (!allMappings?.length) return;
    _exporter.current?.save();
    setAllMappings([]);
  }, [allMappings]);

  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(onColumnsSubmit, null, fetchMappings, handleEnableDisable)
  );

  return (
    <>
      <div className="setting-page">
        <Grid
          style={{ maxHeight: "" }}
          data={templateMappings}
          {...dataState}
          onDataStateChange={dataStateChange}
          skip={page.skip}
          take={page.take}
          total={total}
          pageable={{
            ...Pagination.pageSizeValue,
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          className={`customHeight employee`}
          resizable={Filters.resizeable}
          selectable={{
            enabled: true,
            drag: true,
            mode: "multiple",
          }}
        >
          <GridToolbar>
            <div className="flex gap-3 w-full items-center">
              <div className="w-[250px]">
                <SearchBox
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search"
                />
              </div>
              <Button
                themeColor={"primary"}
                className="border border-[#E2E8F0]"
                onClick={() =>
                  navigate("/settings/create-mapping?action=create")
                }
              >
                <span className="inline-flex items-center gap-2">
                  <Plus className="w-4 h-4" /> Add New
                </span>
              </Button>
              <div
                className="cursor-pointer  sm:ml-0 ml-auto sm:flex-initial flex-1"
                onClick={excelExport}
              >
                <Export />
                <ExcelExport
                  data={allMappings}
                  collapsible={true}
                  fileName="Employees.xlsx"
                  ref={_exporter}
                >
                  {stateColumns &&
                    stateColumns.length > 0 &&
                    stateColumns.map((column: any, idx: number) => {
                      if (column.show) {
                        return (
                          <ExcelExportColumn
                            key={idx}
                            field={column.field}
                            title={column.title}
                          />
                        );
                      }
                    })}
                </ExcelExport>
              </div>
            </div>
          </GridToolbar>
          {stateColumns &&
            stateColumns.length > 0 &&
            stateColumns.map((column: any, idx: number) => {
              if (column.show) {
                if (column.cells) {
                  return (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      columnMenu={column.columnMenu}
                      minResizableWidth={200}
                      // width={200}
                      cells={column.cells}
                      filter={column.filter ? column.filter : "text"}
                    />
                  );
                }
                return (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    columnMenu={column.columnMenu}
                    minResizableWidth={200}
                    // width={200}
                    filter={column.filter ? column.filter : "text"}
                  />
                );
              }
            })}
        </Grid>
      </div>
    </>
  );
};
