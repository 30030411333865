import { BaseService } from './base.service';

class ProjectService extends BaseService {
  constructor() {
    super('Project');
  }
}

const projectSvc = new ProjectService();

export default projectSvc;
