import { auth } from '../lib';
import LoaderService from './loader.service';

class SettingsService {
  async get() {
    try {
      LoaderService.showLoader();
      const { data, ok }: any = await auth.get(
        `${process.env.REACT_APP_BASE_URL}/api/Settings`,
        {
          $filter: `IsDeleted ne true`,
        }
      );
      if (ok) {
        return { data, error: null };
      }

      return { data: null, error: data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const settingsSvc = new SettingsService();

export default settingsSvc;
