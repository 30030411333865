import { IDependant } from '../../interfaces/peopleflow/dependent.interface';
import { api } from '../../lib';
import { setToast } from '../../store/features/toastSlice';
import { store } from '../../store/store/store';
import { getPaginationData } from '../../utils/utils';
import LoaderService from '../loader.service';

const Pagination = getPaginationData();

class DependantService {
  async post(payload: IDependant) {
    try {
      LoaderService.showLoader();
      const res = await api.post(
        `${process.env.REACT_APP_BASE_URL}/data/Dependants`,
        payload
      );

      if (res.ok) {
        store.dispatch(
          setToast({
            toastType: 'success',
            toastMsg: 'Dependent created successfully.',
          })
        );
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg: "Couldn't create dependent.",
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async put(payload: IDependant) {
    try {
      LoaderService.showLoader();
      const res = await api.put(
        `${process.env.REACT_APP_BASE_URL}/data/Dependants/${payload.Id}`,
        payload
      );

      if (res.ok) {
        store.dispatch(
          setToast({
            toastType: 'success',
            toastMsg: 'The dependant is updated successfully.',
          })
        );
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg:
            'An error occurred while updating the dependant. Please try again later.',
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getById(id: number) {
    try {
      LoaderService.showLoader();
      const res = await api.get(
        `${process.env.REACT_APP_BASE_URL}/data/Dependants/${id}`
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg: "Couldn't fetch dependent.",
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getDependantsWithEmployee(
    searchQuery = '',
    pageNumber = 1,
    pageSize = Pagination.initialDataState.take,
    showArchived?: boolean,
    showActive = true,
    showInActive = false
  ) {
    try {
      LoaderService.showLoader();
      let skip = Math.max((pageNumber - 1) * pageSize, 0);

      let filterParam = '';

      const searchParam = searchQuery
        ? `contains(EmployeeName, '${searchQuery}') or contains(DependantName, '${searchQuery}') and `
        : '';

      filterParam += `${searchParam}IsDeleted eq ${showArchived || false}`;

      if (showActive && !showInActive) {
        filterParam += ` and Status eq 'Active'`;
      }

      if (showInActive && !showActive) {
        filterParam += ` and Status eq 'InActive'`;
      }

      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/data/DependantView?$filter=${filterParam}&$orderby=EmployeeName asc&$skip=${skip}&$top=${pageSize}&$count=true`
      );

      if (res.ok) {
        return {
          data: res.data.value,
          total: res.data['@odata.count'],
          error: null,
        };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg: "Couldn't fetch data of Dependants.",
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async markDependentInactive(id: number) {
    try {
      LoaderService.showLoader();
      const existing: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/data/Dependants/${id}`
      );

      if (existing.ok) {
        existing.data.Status = 'InActive';
        await api.put(
          `${process.env.REACT_APP_BASE_URL}/data/Dependants/${id}`,
          existing.data
        );
        store.dispatch(
          setToast({
            toastType: 'success',
            toastMsg: 'Dependant marked as InActive',
          })
        );
      }

      return { data: null, error: existing.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg: "Couldn't fetch dependent.",
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getDependentsByEmployeeId(employeeId: number) {
    try {
      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/data/DependantView?$filter=EmployeeId eq ${employeeId}`
      );
      if (res.ok) {
        return { data: res.data.value, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error };
    }
  }
}

const dependantSvc = new DependantService();

export default dependantSvc;
