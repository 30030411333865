import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { CheckIcon, X } from "lucide-react";
import { IDialogInfo } from "../interfaces/dialog-info.interface";

interface IDialogBox {
  dialogInfo: IDialogInfo;
  onToggleDeleteDialog: (action: string) => void;
}

export const CustomDialogBox: React.FC<IDialogBox> = ({ dialogInfo, onToggleDeleteDialog }: IDialogBox) => {
  const toggleDialog = (action: string) => {
    onToggleDeleteDialog(action);
  };

  return (
    <Dialog title={dialogInfo.title} width={"345px"} onClose={() => toggleDialog('no')}>
      <p className="mb-2">
        { dialogInfo.message }
      </p>
      <DialogActionsBar>
        <div className="flex gap-x-2 justify-end">
          <Button
            themeColor={"base"}
            onClick={() => toggleDialog('no')}
            className="iconcta"
          >
            <span className="inline-flex items-center gap-1">
              <X className="h-5 w-5" strokeWidth={1} />
              No
            </span>
          </Button>
          <Button
            className="iconcta"
            themeColor={"primary"}
            onClick={() => toggleDialog('yes')}
          >
            <span className="inline-flex items-center gap-1">
              <CheckIcon className="h-5 w-5" strokeWidth={1} />
              Yes
            </span>
          </Button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
}
