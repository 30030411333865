import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools/pager/Pager";
import {
  ComboBox,
  ComboBoxChangeEvent,
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import {
  Switch,
  TextBox,
  TextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { debounce } from "lodash";
import { X } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
} from "../../component/global/ColumnMenu";
import CustomLabel from "../../component/global/RequiredMark";
import SettingsGridToolbar from "../../component/global/SettingsGridToolbar";
import Filters from "../../constants/filters";
import { IExcelColumn } from "../../interfaces/excel-columns.interface";
import IPageState from "../../interfaces/page.interface";
import { IUser } from "../../interfaces/user.interface";
import authService from "../../services/auth.service";
import sharedSvc from "../../services/shared.service";
import userSvc from "../../services/user.service";
import {
  ActionCellPopupSettings,
  DateTimeCell,
  RolesCell,
} from "../../shared-components/custom-cells";
import { parseKendoFilter } from "../../utils/kendo";
import {
  PROJECT_CONTRIBUTOR,
  PROJECT_ADMIN,
  SUPER_USER,
  VISITOR,
} from "../../utils/roles.constant";
import {
  MIN_RESIZABLE_WIDTH_KENDO_TABLE,
  getPaginationData,
} from "../../utils/utils";
import { ISearchFilter } from "../../interfaces/searchFilter.interface";
import { setToast } from "../../store/features/toastSlice";
import { useAppDispatch } from "../../store/hooks/hooks";

const initialStateUser = {
  name: "",
  email: "",
  idProvider: "",
  roles: [],
};
interface IEditUser {
  id: string;
  name: string;
  email: string;
  idProvider: string;
  roles: string[] | string;
}

const USER_ROLES: string[] = [
  PROJECT_CONTRIBUTOR,
  PROJECT_ADMIN,
  SUPER_USER,
  VISITOR,
];

const ExcelColumns: IExcelColumn[] = [
  {
    field: "name",
    title: "Name",
    width: 200,
  },
  {
    field: "email",
    title: "Email",
  },
  {
    field: "idProvider",
    title: "Auth Provider",
  },
  {
    field: "roles",
    title: "Roles",
  },
];

const Pagination = getPaginationData();

export const AddUser = ({ showAddUserDrawer, setShowAddUserDrawer }: any) => {
  const debouncedSearch = useRef(
    debounce((value: string) => fetchUsers(value), 300)
  ).current;

  const [allUsers, setAllUsers] = useState<IUser[]>([]);
  const [dataState, setDataState] = useState<State>(
    Pagination.initialDataState
  );
  const [newUser, setNewUser] = useState(initialStateUser);
  const [filter, setFilter] = useState<string>();
  const [page, setPage] = useState<IPageState>(Pagination.initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >();
  const [searchValue, setSearchValue] = useState("");
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [showEditDrawer, setShowEditDrawer] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState<IUser[]>([]);
  const [userToEdit, setUserToEdit] = useState<IEditUser | null>(null);
  const [validFieldsOnAdd, setValidFieldsOnAdd] = useState<any>({
    name: null,
    email: null,
  });
  const [validFieldsOnEdit, setValidFieldsOnEdit] = useState<any>({
    name: null,
    email: null,
  });
  const dispatch = useAppDispatch();

  const saveUser = async () => {
    try {
      await authService
        .createUser({ ...newUser, isActive: true })
        .then((res) => {
          if (!res.error) {
            dispatch(
              setToast({
                toastType: "success",
                toastMsg: "User added successfully",
              })
            );
          }
          setShowAddUserDrawer(false);
          fetchUsers(searchValue);
        });
    } catch (error) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: `Unable to save user, Please try again.`,
        })
      );
    }
  };

  const fetchUsers = useCallback(
    async (
      searchQuery = "",
      filter?: CompositeFilterDescriptor | null,
      sort?: any,
      pageNumber = 0,
      pageSize = Pagination.initialDataState.take
    ) => {
      try {
        let searchFilters: ISearchFilter[] = [];
        if (searchQuery) {
          searchFilters = [
            { field: "Name", operator: "contains", value: searchQuery },
            { field: "Email", operator: "contains", value: searchQuery },
          ];
        }
        const filters: any = {
          filters: [
            {
              logic: "and",
              filters: [
                {
                  field: "isActive",
                  operator: "eq",
                  value: showArchived ? false : true,
                },
              ],
            },
          ],
        };
        if (filter) {
          filters.filters.push(filter);
        }
        const res = await userSvc.get(
          searchFilters,
          filters,
          sort,
          pageNumber,
          pageSize
        );
        setUsers(res.data);
        setTotal(Number(res?.total?.data));
      } catch (error) {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: `Unable to get users, Please try again.`,
          })
        );
      }
    },
    [Pagination.initialDataState.take, filter, showArchived]
  );

  const fetchAllUsers = useCallback(async () => {
    try {
      const res = await userSvc.getAll();
      if (res?.data) {
        const users = res?.data?.map((item: any) => {
          item.roles = item.roles.join(",");
          return item;
        });
        setAllUsers(users);
      }
    } catch (error) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: `Unable to get users, Please try again.`,
        })
      );
    }
  }, [showArchived]);

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take = targetEvent.value === "All" ? users.length : event.page.take;

    const pageNumber = event.page.skip / event.page.take + 1;
    const pageSize = event.page.take;
    fetchUsers(searchValue, null, pageNumber, pageSize);

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
    fetchUsers(
      searchValue,
      event.dataState.filter,
      event.dataState.sort,
      page.skip,
      page.take
    );
  };
  const onChangeRolesOnAdd = (event: MultiSelectChangeEvent) => {
    setNewUser((prev: any) => {
      return {
        ...prev,
        roles: event.value,
      };
    });
  };
  const onChangeRolesOnEdit = (event: MultiSelectChangeEvent) => {
    setValidFieldsOnEdit((prev: any) => {
      return {
        ...prev,
        roles: event.value.length !== 0,
      };
    });
    setUserToEdit((prev: any) => {
      return {
        ...prev,
        roles: event.value,
      };
    });
  };

  const onUserEditChange = (e: ComboBoxChangeEvent | TextBoxChangeEvent) => {
    if (e.target.name === "email") {
      setValidFieldsOnEdit((prev: any) => {
        return {
          ...prev,
          [e.target.name as string]: validateEmail(e.value)
            ? null
            : "Invalid Email",
        };
      });
    }

    if (e.target.name === "name") {
      setValidFieldsOnEdit((prev: any) => {
        return {
          ...prev,
          Name: validateName(e.value)
            ? null
            : "Name can't contain numbers or special characters",
        };
      });
    }

    setUserToEdit((prev: any) => {
      return {
        ...prev,
        [e.target.name as string]: e.value,
      };
    });
  };

  const onNewUserChange = (e: ComboBoxChangeEvent | TextBoxChangeEvent) => {
    if (e.target.name === "email") {
      setValidFieldsOnAdd((prev: any) => {
        return {
          ...prev,
          [e.target.name as string]: validateEmail(e.value)
            ? null
            : "Invalid Email",
        };
      });
    }

    if (e.target.name === "name") {
      setValidFieldsOnAdd((prev: any) => {
        return {
          ...prev,
          [e.target.name as string]: validateName(e.value)
            ? null
            : "Name can't contain numbers or special characters",
        };
      });
    }

    setNewUser((prev: any) => {
      return {
        ...prev,
        [e.target.name as string]: e.value,
      };
    });
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  const handleEditClick = (data: any) => {
    setUserToEdit(data);
    setShowEditDrawer(!showEditDrawer);
  };

  const handleDeleteClick = async (userData: any) => {
    await userSvc.delete(userData.Id);
    fetchUsers(searchValue);
  };

  const handleRestore = async (userData: any) => {
    await sharedSvc.restore("Users", userData.Id);
    fetchUsers(searchValue);
  };

  const handleAddClick = () => {
    setShowAddUserDrawer(!showAddUserDrawer);
    setNewUser(initialStateUser);
  };

  const handleEditUser = async () => {
    try {
      setUserToEdit((prev: any) => {
        return { ...prev, LastLoginDate: undefined };
      });

      await userSvc.update(userToEdit?.id!, userToEdit).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "User updated successfully",
            })
          );
        }
        setShowEditDrawer(false);
        fetchUsers(searchValue);
      });
    } catch (error) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: `Unable to update user, Please try again.`,
        })
      );
    }
  };

  const validateNewUser = () => {
    if (!newUser) return false;
    if (
      !newUser.email ||
      !newUser.idProvider ||
      !newUser.name ||
      !newUser.roles.length
    )
      return false;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-Z\s]*$/;

    if (!emailRegex.test(newUser.email) || !nameRegex.test(newUser.name))
      return false;

    return true;
  };

  const validateEditUser = () => {
    if (!userToEdit) return false;
    if (!userToEdit.name || !userToEdit.roles) return false;

    const nameRegex = /^[a-zA-Z\s]*$/;

    if (!nameRegex.test(userToEdit.name)) {
      return false;
    }

    return true;
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateName = (username: string) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    return nameRegex.test(username);
  };

  useEffect(() => {
    fetchUsers(searchValue);
  }, [fetchUsers]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  const handleEnableDisable = async (dataItem: any, isActive: boolean) => {
    const updatedData = { ...dataItem, isActive: isActive };
    await userSvc.update(dataItem.id, updatedData);
    fetchUsers(searchValue);
  };

  return (
    <>
      <div className="setting-page">
        <Grid
          style={{ maxHeight: "" }}
          data={users}
          {...dataState}
          onDataStateChange={dataStateChange}
          skip={page.skip}
          take={page.take}
          total={total}
          pageable={{
            ...Pagination.pageSizeValue,
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          resizable={Filters.resizeable}
          className="customHeight"
        >
          <GridToolbar>
            <SettingsGridToolbar
              searchValue={searchValue}
              handleSearchChange={handleSearchChange}
              handleAddClick={handleAddClick}
              setShowArchived={setShowArchived}
              data={allUsers}
              excelColumns={ExcelColumns}
              excelFileName="Users.xlsx"
              showArchived={true}
            />
          </GridToolbar>

          <GridColumn
            field="name"
            title="Name"
            columnMenu={ColumnMenu}
            minResizableWidth={MIN_RESIZABLE_WIDTH_KENDO_TABLE}
            cell={(props) => (
              <ActionCellPopupSettings
                rowData={props.dataItem}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                handleRestore={handleRestore}
                dataKey="name"
                showArchived={showArchived}
              />
            )}
          />
          <GridColumn
            field="email"
            title="Email"
            columnMenu={ColumnMenu}
            minResizableWidth={MIN_RESIZABLE_WIDTH_KENDO_TABLE}
          />
          <GridColumn
            field="idProvider"
            title="Auth Provider"
            columnMenu={(props) => (
              <ColumnMenuCheckboxFilter {...props} rowData={allUsers} />
            )}
            minResizableWidth={MIN_RESIZABLE_WIDTH_KENDO_TABLE}
          />
          <GridColumn
            field="roles"
            title="Roles"
            cells={{
              data: RolesCell,
            }}
            columnMenu={(props) => (
              <ColumnMenuCheckboxFilter {...props} rowData={allUsers} />
            )}
            minResizableWidth={MIN_RESIZABLE_WIDTH_KENDO_TABLE}
          />
          <GridColumn
            field="lastLoginDate"
            title="Last Login"
            cells={{
              data: DateTimeCell,
            }}
            minResizableWidth={MIN_RESIZABLE_WIDTH_KENDO_TABLE}
          />
          <GridColumn
            field="isActive"
            title="Active"
            columnMenu={ColumnMenu}
            minResizableWidth={MIN_RESIZABLE_WIDTH_KENDO_TABLE}
            cell={(props) => (
              <td>
                <Switch
                  checked={props.dataItem.isActive}
                  onLabel="Yes"
                  offLabel="No"
                  name="isActive"
                  value={props.dataItem.isActive}
                  onChange={(e) => handleEnableDisable(props.dataItem, e.value)}
                ></Switch>
              </td>
            )}
          />
        </Grid>
        <div>
          <Drawer
            expanded={showEditDrawer}
            position={"start"}
            mode={"overlay"}
            animation={{ duration: 400 }}
            className="customDrawer2 transition-all duration-500 "
            // onOverlayClick={() => handleEditClick}
            // onSelect={() => handleEditClick}
          >
            <DrawerContent>
              <div className="overlay" onClick={handleEditClick}></div>
              <div
                className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
                  showEditDrawer ? "translate-x-0" : "translate-x-full"
                }`}
              >
                <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
                  <h2 className="font-medium text-lg">Edit User</h2>
                  <Button
                    fillMode="flat"
                    className="p-2 bg-none outline-none"
                    onClick={handleEditClick}
                  >
                    <X className="w-4 h-4" />{" "}
                  </Button>
                </div>
                <div className="flex-1 py-4">
                  <div className="grid gap-4">
                    <div>
                      <CustomLabel label="Auth Provider" required={true} />
                      <ComboBox
                        data={["EntraID", "Custom"]}
                        placeholder="Select Auth Provider"
                        // defaultValue="Select Auth provider"
                        name="IdProvider"
                        value={userToEdit?.idProvider}
                        onChange={onUserEditChange}
                        disabled={true}
                        clearButton={false}
                        // valid={validFieldsOnEdit.IdProvider}
                      />
                    </div>
                    <div>
                      <CustomLabel label="Email" required={true} />
                      <TextBox
                        value={userToEdit?.email}
                        onChange={onUserEditChange}
                        placeholder="Enter Email"
                        name="email"
                        disabled={true}
                        valid={!validFieldsOnEdit.email}
                      />
                      {validFieldsOnEdit?.UserEmail && (
                        <p className="text-red-600 text-[12px]">
                          {validFieldsOnEdit?.UserEmail}
                        </p>
                      )}
                    </div>
                    <div>
                      <CustomLabel label="Name" required={true} />

                      <TextBox
                        value={userToEdit?.name}
                        onChange={onUserEditChange}
                        placeholder="Enter Name"
                        name="name"
                        valid={!validFieldsOnEdit.name}
                      />
                      {validFieldsOnEdit?.name && (
                        <p className="text-red-600 text-[12px]">
                          {validFieldsOnEdit?.name}
                        </p>
                      )}
                    </div>
                    <div>
                      <CustomLabel label="Roles" required={true} />

                      <MultiSelect
                        className="z-[9999]"
                        data={USER_ROLES}
                        onChange={onChangeRolesOnEdit}
                        value={
                          typeof userToEdit?.roles === "string"
                            ? userToEdit?.roles?.split(",")
                            : userToEdit?.roles
                        }
                        placeholder="Staff No."
                        valid={validFieldsOnEdit.roles}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end gap-2 pb-4">
                  <Button
                    className="border border-[#E2E8F0] px-4 py-2 font-medium"
                    onClick={() => setShowEditDrawer(!showEditDrawer)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="border border-[#E2E8F0] px-4 py-2 font-medium"
                    themeColor={"primary"}
                    onClick={handleEditUser}
                    disabled={!validateEditUser()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </DrawerContent>
          </Drawer>

          <Drawer
            expanded={showAddUserDrawer}
            position={"start"}
            mode={"overlay"}
            animation={{ duration: 400 }}
            className="customDrawer2 transition-all duration-500 "
            // onOverlayClick={() => handleEditClick}
            // onSelect={() => handleEditClick}
          >
            <DrawerContent>
              <div
                className="overlay"
                onClick={() => setShowAddUserDrawer(!showAddUserDrawer)}
              ></div>
              <div
                className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
                  showAddUserDrawer ? "translate-x-0" : "translate-x-full"
                }`}
              >
                <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
                  <h2 className="font-medium text-lg">Add New</h2>
                  <Button
                    fillMode="flat"
                    className="border border-[#E2E8F0]"
                    onClick={() => setShowAddUserDrawer(!showAddUserDrawer)}
                  >
                    <X className="w-4 h-4" />{" "}
                  </Button>
                </div>
                <div className="flex-1 py-4">
                  <div className="grid gap-4">
                    <div>
                      <CustomLabel label="Auth Provider" required={true} />
                      <ComboBox
                        data={["EntraID", "Custom"]}
                        onChange={onNewUserChange}
                        value={newUser.idProvider}
                        name="idProvider"
                        clearButton={newUser.idProvider ? true : false}
                        placeholder="Select Auth Provider"
                      />
                    </div>
                    <div>
                      <CustomLabel label="Email" required={true} />

                      <TextBox
                        onChange={onNewUserChange}
                        value={newUser.email}
                        placeholder="Enter Email"
                        name="email"
                        valid={!validFieldsOnAdd.email}
                      />
                      {validFieldsOnAdd?.email && (
                        <p className="text-red-600 text-[12px]">
                          {validFieldsOnAdd?.email}
                        </p>
                      )}
                    </div>
                    <div>
                      <CustomLabel label="Name" required={true} />

                      <TextBox
                        value={newUser.name}
                        type="email"
                        onChange={onNewUserChange}
                        placeholder="Enter Name"
                        name="name"
                        valid={!validFieldsOnAdd.name}
                      />
                      {validFieldsOnAdd?.name && (
                        <p className="text-red-600 text-[12px]">
                          {validFieldsOnAdd?.name}
                        </p>
                      )}
                    </div>
                    <div>
                      <CustomLabel label="Roles" required={true} />

                      <MultiSelect
                        data={USER_ROLES}
                        onChange={onChangeRolesOnAdd}
                        value={newUser.roles}
                        placeholder="Roles"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end gap-2 pb-4">
                  <Button
                    onClick={() => setShowAddUserDrawer(!showAddUserDrawer)}
                  >
                    Cancel
                  </Button>
                  <Button
                    themeColor={"primary"}
                    onClick={saveUser}
                    disabled={!validateNewUser()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </DrawerContent>
          </Drawer>
        </div>
      </div>
    </>
  );
};
