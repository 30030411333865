import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store/store';

interface CommonState {
  requestsInProgress: number;
  selectedProject: any;
  selectedBBS: any;
  selected: boolean;
  fetchProjects: boolean;
}

const initialState: CommonState = {
  requestsInProgress: 0,
  selectedProject: null,
  selectedBBS: null,
  selected: false,
  fetchProjects: false,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    show: (state) => {
      state.requestsInProgress = state.requestsInProgress + 1;
    },
    hide: (state) => {
      const val = state.requestsInProgress - 1;
      if (val < 0) {
        state.requestsInProgress = 0;
      } else {
        state.requestsInProgress = val;
      }
    },
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    resetSelectedProject: (state) => {
      state.selectedProject = null;
    },
    setSelectedBBS: (state, action) => {
      state.selectedBBS = action.payload;
    },
    resetSelectedBBS: (state) => {
      state.selectedBBS = null;
    },
    triggerFetchProjects: (state) => {
      state.fetchProjects = true;
    },
    resetFetchProjects: (state) => {
      state.fetchProjects = false;
    },
    setSelectedMenu: (state, action) => {
      state.selected = !action.payload;
    },
  },
});

export const {
  show,
  hide,
  setSelectedProject,
  resetSelectedProject,
  setSelectedBBS,
  resetSelectedBBS,
  setSelectedMenu,
  triggerFetchProjects,
  resetFetchProjects,
} = commonSlice.actions;
export const selectCommon = (state: RootState) => state.common;
export default commonSlice.reducer;
