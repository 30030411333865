import {
  Album,
  Box,
  Building2,
  Flag,
  FolderSync,
  LucideMenu,
  PanelsTopLeft,
  TableProperties,
  Tally2,
  User,
  Users,
  UsersRound,
  X,
} from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Menu from "./Menu";
import MenuConfig from "./MenuConfig";
import { Button } from "@progress/kendo-react-buttons";

export interface SidebarItem {
  icon: React.ElementType;
  name: string;
  link: string;
  routes: string[];
  roles: string[];
}

const sidebarItemsProjects: SidebarItem[] = [
  {
    icon: PanelsTopLeft,
    name: "Home",
    link: "/home",
    routes: ["/home"],
    roles: [],
  },
];

const sidebarItemsSettings: SidebarItem[] = [
  {
    icon: Building2,
    name: "Company",
    link: "/settings/companies",
    routes: ["/settings/companies"],
    roles: [],
  },
  {
    icon: Users,
    name: "Subcontractors",
    link: "/settings/subcontractor",
    routes: ["/settings/subcontractor"],
    roles: [],
  },
  {
    icon: Album,
    name: "Standards",
    link: "/settings/standards",
    routes: ["/settings/standards"],
    roles: [],
  },
  {
    icon: FolderSync,
    name: "Mappings",
    link: "/settings/mappings",
    routes: ["/settings/mappings", "/settings/create-mapping"],
    roles: [],
  },
  {
    icon: TableProperties,
    name: "Object Types",
    link: "/settings/object-types",
    routes: ["/settings/object-types"],
    roles: [],
  },
  {
    icon: Box,
    name: "Shapes",
    link: "/settings/shapes",
    routes: ["/settings/shapes"],
    roles: [],
  },
  {
    icon: UsersRound,
    name: "Users",
    link: "/settings/users",
    routes: ["/settings/users"],
    roles: [],
  },
];

interface SidebarProps {
  selected: boolean;
  setSelected: (selected: boolean) => void;
  toggleSelected: any;
}

const Sidebar: React.FC<SidebarProps> = ({
  selected,
  setSelected,
  toggleSelected,
}) => {
  const [selectedConfig, setSelectedConfig] = useState("");
  const [currentRoute, setCurrentRoute] = useState("");
  const location = useLocation();

  const handleOverlayClick = () => {
    setSelected(false);
  };

  useEffect(() => {
    const isVisa = location.pathname.includes("/visa");
    const isLease = location.pathname.includes("/lease");

    if (isVisa) {
      setCurrentRoute("visa");
    } else if (isLease) {
      setCurrentRoute("lease");
    } else {
      setCurrentRoute("settings");
    }
  }, [location]);

  return (
    <>
      <div
        className={`lg:hidden  ${
          selected ? "opacity-100 visible" : " opacity-0 invisible"
        } fixed inset-0 bg-black/30 z-[999] transition-all duration-300`}
        onClick={handleOverlayClick}
      ></div>
      <div
        className={`max-w-full   ${
          selected
            ? "lg:w-[54px!important] w-[280px!important] translate-x-0 "
            : "w-[280px!important] -translate-x-full"
        }  lg:pt-2 pt-2 fixed top-0 bottom-0 z-[999] h-full flex flex-col bg-primary-130 lg:translate-x-0 transition-all duration-300`}
      >
        <div className="flex justify-end">
          <Link
            to={"/home"}
            className="px-3 mb-7"
            style={{ marginRight: "auto" }}
          >
            <img
              src="https://peopleflow-dev.alec.ae/images/brand-white.png"
              alt=""
            />
            <span
              className={`text-white text-lg font-bold font-kanit tracking-[-0.04px] ${
                selected ? "lg:hidden" : ""
              }`}
            >
              {/* Bar Bending */}
            </span>
          </Link>

          <Button
            onClick={toggleSelected}
            className="btn px-4 mb-7 border-none outline-none hover:bg-transparent  bg-transparent"
          >
            <LucideMenu className="text-[10px] text-white" />
          </Button>
        </div>

        <div className="flex-1">
          <Menu
            selected={selected}
            selectedConfig={selectedConfig}
            sidebarItemsProjects={sidebarItemsProjects}
            sidebarItemsSettings={sidebarItemsSettings}
          />
        </div>
        <div className="pt-[10px]">
          <MenuConfig
            selected={selected}
            selectedConfig={selectedConfig}
            setSelectedConfig={setSelectedConfig}
            sidebarItemsProjects={sidebarItemsProjects}
            sidebarItemsSettings={sidebarItemsSettings}
          />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default Sidebar;
