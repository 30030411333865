import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools/pager/Pager";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  GridColumn as Column,
  Grid,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { debounce } from "lodash";
import { Plus } from "lucide-react";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import Export from "../../component/global/Export";
import SearchBox from "../../component/ui/SearchBox";
import Filters from "../../constants/filters";
import { ITemplateMapping } from "../../interfaces/mapping.interface";
import IPageState from "../../interfaces/page.interface";
import { ISearchFilter } from "../../interfaces/searchFilter.interface";
import { ISubContractor } from "../../interfaces/subcontractor.interface";
import subContractorSvc from "../../services/subContractor.service";
import templateMappingSvc from "../../services/templateMapping.service";
import { getPaginationData } from "../../utils/utils";
import WriteDrawer from "./WriteDrawer";
import { columnInterface, generateColumns } from "./columns";
import { setToast } from "../../store/features/toastSlice";
import { useAppDispatch } from "../../store/hooks/hooks";

let mappingChanged = false;

export const Listing = () => {
  const _exporter = createRef<ExcelExport>();
  const excelExport = () => {
    if (_exporter.current) {
      fetchAllSubContractors();
    }
  };

  const Pagination = getPaginationData();

  const debouncedSearch = useRef(
    debounce((value: string) => fetchSubContractors(value), 500)
  ).current;
  const dispatch = useAppDispatch();
  const [addNewSubcontractor, setAddNewSubcontractor] = useState<
    (ISubContractor & { id: string }) | null
  >(null);
  const [allSubContractors, setAllSubContractors] = useState([]);
  const [columnsMapping, setColumnsMapping] = useState<any>(null);
  const [dataState, setDataState] = useState<State>(
    Pagination.initialDataState
  );
  const [page, setPage] = useState<IPageState>(Pagination.initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >();
  const [searchValue, setSearchValue] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [subContractors, setSubContractors] = useState<any>([]);
  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(
      handleEditClick,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    )
  );
  const [total, setTotal] = useState(0);
  const [mappings, setMappings] = useState<ITemplateMapping[]>([]);

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      handleEditClick,
      onColumnsSubmit,
      columnsState,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? subContractors.length : event.page.take;

    fetchSubContractors(
      searchValue,
      null,
      null,
      event.page.skip,
      event.page.take
    );
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length !== 1) {
      debouncedSearch(value);
    }
  };

  const handleChange = (
    e: TextBoxChangeEvent | ComboBoxChangeEvent,
    isDropdown?: boolean
  ) => {
    setAddNewSubcontractor((prev: any) => {
      return {
        ...prev,
        [e.target.name as any]: isDropdown ? e.target.value.id : e.target.value,
      };
    });
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
    fetchSubContractors(
      searchValue,
      event.dataState.filter,
      event.dataState.sort,
      page.skip,
      page.take
    );
  };

  const createSubContractor = () => {
    subContractorSvc
      .create({ ...addNewSubcontractor, code: crypto.randomUUID() })
      .then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Subcontractor added successfully",
            })
          );
        }
        fetchSubContractors(searchValue);
        setAddNewSubcontractor(null);
        setShowDrawer(false);
      });
  };

  const editSubContractor = () => {
    if (!addNewSubcontractor?.id) return;
    subContractorSvc
      .update(addNewSubcontractor.id, addNewSubcontractor)
      .then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Subcontractor updated successfully",
            })
          );
        }
        fetchSubContractors(searchValue);
        setAddNewSubcontractor(null);
        setShowDrawer(false);
      });
  };

  function handleEditClick(data: ISubContractor) {
    setAddNewSubcontractor(data);
    setShowDrawer(true);
  }

  const fetchSubContractors = useCallback(
    async (
      searchQuery = "",
      filter?: CompositeFilterDescriptor | null,
      sort?: any,
      pageNumber = 0,
      pageSize = Pagination.initialDataState.take
    ) => {
      let searchFilters: ISearchFilter[] = [];
      if (searchQuery) {
        searchFilters = [
          { field: "Name", operator: "contains", value: searchQuery },
          { field: "Code", operator: "contains", value: searchQuery },
        ];
      }
      const res = await subContractorSvc.get(
        searchFilters,
        filter,
        sort,
        pageNumber,
        pageSize
      );
      setSubContractors(res.data);
      setTotal(res.total);
      setDataState(Pagination.initialDataState);
      setPage(Pagination.initialDataState);
    },
    []
  );

  const fetchAllSubContractors = async () => {
    subContractorSvc.getAll().then((res) => {
      setAllSubContractors(res.data);
    });
  };

  const fetchMappings = () => {
    const filters: any = {
      filters: [
        {
          logic: "and",
          filters: [
            {
              field: "isEnabled",
              operator: "eq",
              value: true,
            },
          ],
        },
      ],
    };
    templateMappingSvc.getAll([], filters).then((res) => {
      setMappings(res.data);
    });
  };

  useEffect(() => {
    if (subContractors.length) return;
    fetchSubContractors(searchValue);
  }, []);

  useEffect(() => {
    if (!subContractors?.length || !columnsMapping) return;
    const cols = generateColumns(
      handleEditClick,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }, [subContractors, columnsMapping]);

  useEffect(() => {
    if (!allSubContractors?.length) return;
    _exporter.current?.save();
    setAllSubContractors([]);
  }, [allSubContractors]);

  useEffect(() => {
    fetchMappings();
  }, []);

  useEffect(() => {
    if (showDrawer === false) setAddNewSubcontractor(null);
  }, [showDrawer]);

  return (
    <>
      <div className="setting-page">
        <Grid
          style={{ maxHeight: "" }}
          data={subContractors}
          {...dataState}
          onDataStateChange={dataStateChange}
          skip={page.skip}
          take={page.take}
          total={total}
          pageable={{
            ...Pagination.pageSizeValue,
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          className={`customHeight employee`}
          resizable={Filters.resizeable}
          selectable={{
            enabled: true,
            drag: true,
            mode: "multiple",
          }}
        >
          <GridToolbar>
            <div className="flex gap-3 w-full items-center">
              <div className="w-[250px]">
                <SearchBox
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search"
                />
              </div>
              <Button
                themeColor={"primary"}
                className="border border-[#E2E8F0]"
                onClick={() => setShowDrawer(true)}
              >
                <span className="inline-flex items-center gap-2">
                  <Plus className="w-4 h-4" /> Add New
                </span>
              </Button>
              <div
                className="cursor-pointer  sm:ml-0 ml-auto sm:flex-initial flex-1"
                onClick={excelExport}
              >
                <Export />
                <ExcelExport
                  data={allSubContractors}
                  collapsible={true}
                  fileName="Subcontractors.xlsx"
                  ref={_exporter}
                >
                  {stateColumns &&
                    stateColumns.length > 0 &&
                    stateColumns.map((column: any, idx: number) => {
                      if (column.show) {
                        return (
                          <ExcelExportColumn
                            key={idx}
                            field={column.field}
                            title={column.title}
                          />
                        );
                      }
                    })}
                </ExcelExport>
              </div>
            </div>
          </GridToolbar>
          {stateColumns &&
            stateColumns.length > 0 &&
            stateColumns.map((column: any, idx: number) => {
              if (column.show) {
                if (column.cells) {
                  return (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      columnMenu={column.columnMenu}
                      minResizableWidth={200}
                      // width={200}
                      cells={column.cells}
                      filter={column.filter ? column.filter : "text"}
                    />
                  );
                }
                return (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    columnMenu={column.columnMenu}
                    minResizableWidth={200}
                    // width={200}
                    filter={column.filter ? column.filter : "text"}
                  />
                );
              }
            })}
        </Grid>
      </div>
      <WriteDrawer
        showCreateDrawer={showDrawer}
        setShowCreateDrawer={setShowDrawer}
        addNewSubcontractor={addNewSubcontractor}
        handleChange={handleChange}
        onSave={
          addNewSubcontractor?.id ? editSubContractor : createSubContractor
        }
        mappings={mappings}
      />
    </>
  );
};
