import { useState } from "react";
import DashboardMain from "../../component/layout/main/DashboardMain";
import PageTitle from "../../component/ui/PageTitle";
import { AddUser } from "./AddUser";
import { UsersRound } from "lucide-react";

const Users = () => {
  const [showAddUserDrawer, setShowAddUserDrawer] = useState<boolean>(false);

  return (
    <DashboardMain>
      <div className="LookUpTabs">
        <div className="content-lookups xl:p-5 p-3">
          <>
            <div className="card p-3 mb-5">
              <PageTitle icon={UsersRound} pageTitle="Users" />
            </div>
            <AddUser
              showAddUserDrawer={showAddUserDrawer}
              setShowAddUserDrawer={setShowAddUserDrawer}
            />
          </>
        </div>
      </div>
    </DashboardMain>
  );
};

export default Users;
