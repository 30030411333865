import { Icon } from "lucide-react";
import React from "react";

interface PageTitleProps {
  icon: React.ElementType;
  pageTitle: string;
}

const PageTitle = ({ icon: Icon, pageTitle }: PageTitleProps) => {
  return (
    <h2 className="text-[20px] text-txtPrimary tracking-[-0.08px] font-kanit font-light flex items-center">
      <Icon strokeWidth={1} size="20" className="mr-2" />
      {pageTitle}
    </h2>
  );
};

export default PageTitle;
