import { Button } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import {
  ChevronLeft,
  ChevronRight,
  Database,
  FileX2,
  Layers,
  PanelsLeftBottom,
  Plus,
  X,
} from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useResizable } from "react-resizable-layout";
import DashboardMain from "../../component/layout/main/DashboardMain";
import { IBBS, ILine } from "../../interfaces/bbs.interface";
import { IProject } from "../../interfaces/project.interface";
import { ISearchFilter } from "../../interfaces/searchFilter.interface";
import { IShapeCodes } from "../../interfaces/shapes.interface";
import bbsSvc from "../../services/bbs.service";
import LoaderService from "../../services/loader.service";
import projectSvc from "../../services/project.service";
import shapesSvc from "../../services/shapes.service";
import { setSelectedProject } from "../../store/features/commonSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { RootState } from "../../store/store/store";
import { AddProject } from "./components/AddProject";
import { DetailingOffices } from "./components/DetailingOffices";
import { AddSubcontractors } from "./components/Subcontractors";
import BBSListing from "./Listing/BBSListing";
import FirstLevelListing from "./Listing/FirstLevelListing";
import SecondLevelListing from "./Listing/SecondLevelListing";
import ThirdLevelListing from "./Listing/ThirdLevelListing";
import MemberDetails from "./MemberDetails";
import SampleSplitter from "./SampleSplitter";
import TreeViewMain from "./TreeViewMain";
import Stats from "./components/Stats";
import dashboardService from "../../services/dashboard.service";
import { IHomeDashboardStats } from "../../interfaces/dashboard.interface";
import _ from "lodash";
import Members from "./Members";
import { setToast } from "../../store/features/toastSlice";
import BBSStats from "./components/BBSStats";
import standardSvc from "../../services/standard.service";
import { IStandard } from "../../interfaces/standard.interface";
import WeightStats from "./components/WeightStats";
import ProjectStats from "./components/ProjectStats";
import LengthStats from "./components/LengthStats";
export interface RolesStatsTask {
  id: string;
  title: string;
  count: number;
  Icon: React.ElementType;
  iconColor: string;
}

export type TreeViewDataItem = {
  text: string;
  expanded?: boolean;
  checked?: boolean;
  selected?: boolean;
  items?: TreeViewDataItem[];
  level?: number;
  levelCode?: string;
  subLevelCode?: string;
};

const Home = () => {
  const [dashboardStats, setDashboardStats] = useState<IHomeDashboardStats>();
  const [projectDashboardStats, setProjectDashboardStats] =
    useState<IHomeDashboardStats>();

  const dispatch = useAppDispatch();
  const oldProjectId = useRef<string | null>(null);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const anchor = useRef<Button | null>(null);

  const commonState = useAppSelector((state: RootState) => state.common);

  const [distinctMembers, setDistinctMembers] = useState<ILine[]>([]);
  const [selectedLevelBBS, setSelectedLevelBBS] = useState<IBBS | null>(null);
  const [treeView, setTreeView] = useState<TreeViewDataItem[]>([]);
  const [selectedRow, setSelectedRow] = useState<IBBS | null>(null);
  const [show, setShow] = useState(false);
  const [shapeCodes, setShapeCodes] = useState<IShapeCodes[]>([]);
  const [standard, setStandard] = useState<IStandard | null>(null);

  const [showMembersView, setShowMembersView] = useState(false);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [showManageBBS, setShowManageBBS] = useState(false);
  const [showEditProjectDrawer, setShowEditProjectDrawer] =
    useState<boolean>(false);

  const [showAddDetailingOfficeDrawer, setShowAddDetailingOfficeDrawer] =
    useState<boolean>(false);

  const [showAddSubcontractorDrawer, setShowAddSubcontractorDrawer] =
    useState<boolean>(false);

  const [projectToEdit, setProjectToEdit] = useState<
    (IProject & { id: string }) | null
  >(null);

  const [prevProject, setPrevProject] = useState<
    (IProject & { id: string }) | null
  >(null);

  const showStats = () => {
    if (commonState?.selectedProject?.event?.item === undefined) {
      return true;
    }
    return false;
  };

  const showGrid = () => {
    if (showMembersView) return false;

    if (showManageBBS) {
      if (showMembersView) setShowMembersView(false);
      return true;
    }
    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (commonState?.selectedProject?.event?.item?.level === 3) {
      return true;
    }
    return false;
  };

  const showGridFirstLevel = () => {
    if (showMembersView) return false;
    if (showManageBBS) return false;
    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (commonState?.selectedProject?.event?.item?.level === 0) {
      return true;
    }
    return false;
  };

  const showGridSecondLevel = () => {
    if (showMembersView) return false;
    if (showManageBBS) return false;
    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (commonState?.selectedProject?.event?.item?.level === 1) {
      return true;
    }
    return false;
  };

  const showGridThirdLevel = () => {
    if (showMembersView) return false;
    if (showManageBBS) return false;
    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (commonState?.selectedProject?.event?.item?.level === 2) {
      return true;
    }
    return false;
  };

  const fetchShapeCodes = (standardId: string) => {
    shapesSvc.getByStandardId(standardId).then((res) => {
      setShapeCodes(res.data as IShapeCodes[]);
    });
  };

  const fetchStandard = (standardId: string) => {
    standardSvc.getById(standardId).then((res) => {
      setStandard(res.data as IStandard);
    });
  };

  useEffect(() => {
    if (commonState?.selectedProject?.event?.item?.level === 3) {
      const treeViewProj = treeView.find(
        (x: any) =>
          x.projectId === commonState?.selectedProject?.event?.item?.projectId
      );
      if (treeViewProj) {
      }

      setShowMembersView(true);
    } else {
      setShowMembersView(false);
    }
  }, [commonState?.selectedProject?.event?.item, treeView]);

  useEffect(() => {
    if (projects?.length && commonState?.selectedProject?.event?.item?.level) {
      if (
        commonState?.selectedProject?.event?.item?.projectId ===
        oldProjectId.current
      )
        return;

      const project = projects.find(
        (x) => x.id === commonState.selectedProject.event.item.projectId
      );
      if (project) {
        fetchStandard(project.standardId);
        fetchShapeCodes(project.standardId);
        oldProjectId.current = project.id;
      }
    }
  }, [projects, commonState?.selectedProject?.event?.item]);

  useEffect(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    dashboardService
      .getStats()
      .then((response: any) => {
        if (response.data) {
          setDashboardStats(response.data);
        }
      })
      .catch((error) => {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: `Unable to get stats, Please try again.`,
          })
        );
      });
  }, []);

  useEffect(() => {
    dashboardService
      .projectStats()
      .then((response: any) => {
        if (response.data) {
          setProjectDashboardStats(response.data);
        }
      })
      .catch((error) => {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: `Unable to get stats, Please try again.`,
          })
        );
      });
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const refreshProjects = () => {
    fetchProjects();
  };

  const fetchProjects = useCallback(
    (searchValue?: string) => {
      let searchFilters: ISearchFilter[] = [];
      if (searchValue) {
        searchFilters = [
          { field: "name", operator: "contains", value: searchValue },
        ];
      }
      projectSvc.get(searchFilters).then((res) => {
        if (res.data) {
          setProjects(res.data);
        }
      });
    },
    [setProjects]
  );

  const getHeader = () => {
    const item = commonState?.selectedProject?.event?.item;

    const navigateTo = (level: number) => {
      const idx = treeView.findIndex(
        (x: any) =>
          x.projectId === commonState?.selectedProject?.event?.item?.projectId
      );
      if (idx === -1) return;

      let treeViewItem = treeView[idx];
      switch (level) {
        case 0:
          dispatch(
            setSelectedProject({
              event: {
                item: { ...treeViewItem, cloned: true, expanded: true },
                itemHierarchicalIndex: idx.toString(),
              },
              check: [],
              treeView,
              settings: commonState?.selectedProject?.settings,
            })
          );
          break;
        case 1:
          treeViewItem = treeViewItem!.items![item.parentId];
          dispatch(
            setSelectedProject({
              event: {
                item: { ...treeViewItem, cloned: true, expanded: true },
                itemHierarchicalIndex: idx.toString() + "_" + item.idx,
              },
              check: [],
              treeView,
              settings: commonState?.selectedProject?.settings,
            })
          );
          break;
        case 2:
          treeViewItem =
            treeViewItem?.items![item.parentId]?.items![item.subLevelId];
          dispatch(
            setSelectedProject({
              event: {
                item: { ...treeViewItem, cloned: true, expanded: true },
                itemHierarchicalIndex:
                  idx.toString() +
                  "_" +
                  item.parentId.toString() +
                  "_" +
                  item.idx.toString(),
              },
              check: [],
              treeView,
              settings: commonState?.selectedProject?.settings,
            })
          );
          break;
      }
    };
    if (item.level === 0) {
      return (
        <>
          <div className="inline-flex items-center gap-1">
            <h1 className="text-[20px] text-txtPrimary  font-kanit font-light flex items-center">
              {item.text?.split("-Code-")[0]}
            </h1>
          </div>
        </>
      );
    }

    if (item.level === 1) {
      return (
        <div className="inline-flex items-center">
          <h1
            className="text-[20px] text-[#aea69d]  font-kanit font-light flex items-center mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(0)}
          >
            {item.projectName}
          </h1>
          <h1 className="text-[20px] text-[#aea69d]  font-kanit font-light flex items-center mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1 className="text-[20px] text-txtPrimary  font-kanit font-light flex items-center mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black">
            {item.text?.split("-Code-")[0]}
          </h1>
        </div>
      );
    }

    if (item.level === 2) {
      return (
        <div className="inline-flex items-center">
          <h1
            className="text-[20px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(0)}
          >
            {item.projectName}
          </h1>
          <h1 className="text-[20px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1
            className="text-[20px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(1)}
          >
            {item.levelName}
          </h1>
          <h1 className="text-[20px]text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1 className="text-[20px] text-txtPrimary  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black">
            {item.text?.split("-Code-")[0]}
          </h1>
        </div>
      );
    }

    if (item.level === 3) {
      return (
        <div className="flex text-lg">
          <h1 className="text-[20px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer">
            {item.projectName}
          </h1>
          <h1 className="text-[20px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1
            className="text-[20px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(1)}
          >
            {item.levelName}
          </h1>
          <h1 className="text-[20px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1
            className="text-[20px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(2)}
          >
            {item.subLevelName}
          </h1>
          <h1 className="text-[20px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1 className="text-[20px] text-txtPrimary  font-kanit font-light flex mb-1 mt-1">
            {item.text}
          </h1>
        </div>
      );
    }
  };
  const [expanded, setExpanded] = useState(true);
  const [showAddProjectDrawer, setShowAddProjectDrawer] =
    useState<boolean>(false);

  const [selected, setSelected] = useState<boolean>(false);
  const toggleSelected = () => {
    setSelected(!selected);
  };

  const closeManageBBS = () => {
    setShowManageBBS(false);
  };

  // const {
  //   isDragging: isFileDragging,
  //   position: fileW,
  //   splitterProps: fileDragBarProps,
  // } = useResizable({
  //   axis: "x",
  //   initial: 500,
  //   min: 300,
  //   max: 500,
  // });

  return (
    <>
      <AddProject
        showAddProjectDrawer={showAddProjectDrawer}
        setShowAddProjectDrawer={setShowAddProjectDrawer}
        showEditProjectDrawer={showEditProjectDrawer}
        setShowEditProjectDrawer={setShowEditProjectDrawer}
        prevProject={prevProject}
        setPrevProject={setPrevProject}
        projectToEdit={projectToEdit}
        setProjectToEdit={setProjectToEdit}
        fetchProjects={fetchProjects}
      />
      <DetailingOffices
        showAddDetailingOfficeDrawer={showAddDetailingOfficeDrawer}
        setShowAddDetailingOfficeDrawer={setShowAddDetailingOfficeDrawer}
        projectToEdit={projectToEdit}
        setProjectToEdit={setProjectToEdit}
        fetchProjects={fetchProjects}
      ></DetailingOffices>
      <AddSubcontractors
        projectToEdit={projectToEdit}
        setProjectToEdit={setProjectToEdit}
        showAddSubcontractorDrawer={showAddSubcontractorDrawer}
        setShowAddSubcontractorDrawer={setShowAddSubcontractorDrawer}
        fetchProjects={fetchProjects}
      />

      <div
        className="flex flex-1 transition-all duration-500 wrapper"

        // style={{ height: "93vh" }}
      >
        <div
          className={`flex-col flex left-block  ${
            commonState?.selected ? "lg:w-[0px!important]" : ""
          }`}
        >
          {/*  */}

          <div
            className={`fixed top-0 bottom-0 ${
              commonState?.selected ? "lg:w-[0px!important]" : ""
            } z-[999] h-full flex flex-col bg-white w-[400px!important]`}
          >
            <div className="flex-1">
              <div
                className={`bg-white shadow-elevation8 pt-2 bbs-treeView z-10 h-full`}
              >
                <div
                  className={`${
                    commonState?.selected ? "" : "overflow-y-auto"
                  } z-[999] pb-2`}
                >
                  <TreeViewMain
                    treeView={treeView}
                    setTreeView={setTreeView}
                    projects={projects}
                    setProjects={setProjects}
                    setSelectedLevelBBS={setSelectedLevelBBS}
                    setDistinctMembers={setDistinctMembers}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    showAddProjectDrawer={showAddProjectDrawer}
                    setShowAddProjectDrawer={setShowAddProjectDrawer}
                    selected={commonState?.selected}
                    toggleSelected={toggleSelected}
                    showEditProjectDrawer={showEditProjectDrawer}
                    setShowEditProjectDrawer={setShowEditProjectDrawer}
                    showAddDetailingOfficeDrawer={showAddDetailingOfficeDrawer}
                    setShowAddDetailingOfficeDrawer={
                      setShowAddDetailingOfficeDrawer
                    }
                    showAddSubcontractorDrawer={showAddSubcontractorDrawer}
                    setShowAddSubcontractorDrawer={
                      setShowAddSubcontractorDrawer
                    }
                    projectToEdit={projectToEdit}
                    setProjectToEdit={setProjectToEdit}
                    prevProject={prevProject}
                    setPrevProject={setPrevProject}
                    showManageBBS={showManageBBS}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {!commonState?.selected && (
          <SampleSplitter isDragging={isFileDragging} {...fileDragBarProps} />
        )} */}
        <div
          className={`w-full h-full flex justify-center items-center flex-col right-block
          ${!commonState?.selected ? "lg:pl-[400px!important]" : ""}
          `}
        >
          <DashboardMain>
            {dashboardStats &&
              projectDashboardStats &&
              projects?.length != 0 &&
              showStats() && (
                <>
                  <div className="px-5 py-4">
                    <div className="card p-2">
                      <div className="grid xl:grid-cols-5 gap-3 items-start">
                        <div className="inline-flex items-center gap-2">
                          <PanelsLeftBottom strokeWidth={1} size="20" />
                          <h2 className="font-medium font-kanit text-[20px] -tracking-[0.04px]">
                            Stats
                          </h2>
                        </div>

                        <div className="col-span-4">
                          <Stats statsData={dashboardStats} />
                        </div>
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-2 gap-3 mt-3">
                      <div className="card p-3">
                        <h2 className="font-medium font-kanit text-lg -tracking-[0.04px]">
                          Members by Project
                        </h2>
                        <ProjectStats statsData={projectDashboardStats} />
                      </div>
                      <div className="card p-3">
                        <h2 className="font-medium font-kanit text-lg -tracking-[0.04px]">
                          BBS by Project
                        </h2>
                        <BBSStats statsData={projectDashboardStats} />
                      </div>
                      <div className="card p-3">
                        <h2 className="font-medium font-kanit text-lg -tracking-[0.04px]">
                          Total Weight (Ton) by Project
                        </h2>
                        <WeightStats statsData={projectDashboardStats} />
                      </div>
                      <div className="card p-3">
                        <h2 className="font-medium font-kanit text-lg -tracking-[0.04px]">
                          Total Length (m) by Project
                        </h2>
                        <LengthStats statsData={projectDashboardStats} />
                      </div>
                    </div>
                  </div>
                </>
              )}

            {!projects.length && (
              <div className="px-5 py-4 justify-center items-center flex flex-col h-full">
                <FileX2
                  size={72}
                  strokeWidth={2}
                  color="rgba(184, 187, 190, 1)"
                />
                <p
                  className="mt-4 text-2xl"
                  style={{ color: "rgba(0, 0, 0, 0.32)" }}
                >
                  Create new project to continue...
                </p>
                <Button
                  className="border border-[#E2E8F0] px-2 py-[6px] font-medium mt-4"
                  onClick={() => setShowAddProjectDrawer(true)}
                >
                  <div className="flex gap-x-2">
                    <Plus className="text-[#484F61] self-center p-0" />
                    <p className="self-center pr-2">New Project</p>
                  </div>
                </Button>
              </div>
            )}

            {!showStats() && projects?.length ? (
              <div className="px-5 py-4 ">
                {!showMembersView && showManageBBS && (
                  <div className="card p-3 mb-5">
                    <div className="grid sm:grid-cols-[auto_300px] gap-3 items-center ">
                      {getHeader()}
                      {
                        <>
                          <div className="text-end">
                            <div className="sm:inline-block text-left">
                              <Button
                                type="button"
                                themeColor={"light"}
                                className="bg-[#F5F5F5] border border-[#E2E8F0] px-2 font-small w-24"
                                onClick={() => closeManageBBS()}
                              >
                                <ChevronLeft strokeWidth={0.8} />
                                <span className="inline-flex items-center gap-2">
                                  Close
                                </span>
                              </Button>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                )}
                {!showManageBBS && commonState?.selectedProject ? (
                  <div className="card p-3 mb-5">
                    <div className="grid sm:grid-cols-[auto_300px] gap-3 items-center ">
                      {getHeader()}
                      {
                        <>
                          <div className="text-end">
                            <div className="sm:inline-block text-left">
                              {!showMembersView && (
                                <Button
                                  type="button"
                                  className="text-end flex k-button bg-[#0078d4] k-button-md k-button-solid k-button-solid-base k-rounded-md border border-[#E2E8F0] k-button-solid-primary"
                                  onClick={() => setShowManageBBS(true)}
                                  // ref={anchor}
                                >
                                  <span className="inline-flex items-center gap-2">
                                    <i>
                                      <Layers className="w-6 h-6" />
                                    </i>
                                    Manage BBS
                                  </span>
                                </Button>
                              )}
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="flex items-center gap-3 mb-3">
                  <div className="flex-1 flex items-center justify-end gap-2">
                    <Popup
                      anchor={anchor.current && anchor.current.element}
                      show={show}
                      popupClass={"popup-content w-[174px]"}
                      anchorAlign={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                      popupAlign={{ horizontal: "right", vertical: "top" }}
                    >
                      <div ref={popupRef}></div>
                    </Popup>
                  </div>
                </div>
                <div className="flex LookUpTabs">
                  {showGrid() && <BBSListing />}
                  {showGridFirstLevel() && (
                    <FirstLevelListing
                      treeView={treeView}
                      setTreeView={setTreeView}
                      projects={projects}
                      fetchProjects={fetchProjects}
                    />
                  )}
                  {showGridSecondLevel() && (
                    <SecondLevelListing
                      treeView={treeView}
                      setTreeView={setTreeView}
                      projects={projects}
                      fetchProjects={fetchProjects}
                    />
                  )}
                  {showGridThirdLevel() && (
                    <ThirdLevelListing
                      treeView={treeView}
                      setTreeView={setTreeView}
                      distinctMembers={distinctMembers}
                      projects={projects}
                      fetchProjects={fetchProjects}
                    />
                  )}
                </div>
                {!showMembersView &&
                  showManageBBS &&
                  commonState?.selectedBBS && (
                    <Members selectedRow={commonState?.selectedBBS} />
                  )}
                <div>
                  {showMembersView && (
                    <MemberDetails
                      selectedLevelBBS={selectedLevelBBS}
                      shapeCodes={shapeCodes}
                      standard={standard}
                      refreshProjects={refreshProjects}
                    />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </DashboardMain>
        </div>
      </div>
    </>
  );
};

export default Home;
