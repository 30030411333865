import { useMsal } from "@azure/msal-react";
import { Avatar } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { House, LogOut, Menu, Settings } from "lucide-react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import { getInitials } from "../../../utils/utils";
import ImportDrawer from "../../ImportDrawer";
import { Button } from "@progress/kendo-react-buttons";
import {
  setSelectedMenu,
  setSelectedProject,
} from "../../../store/features/commonSlice";
import { useDispatch } from "react-redux";

const TopBar = ({ toggleSelected }: any) => {
  const location = useLocation();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const commonState = useAppSelector((state: RootState) => state.common);
  const userState = useAppSelector((state: RootState) => state.user);

  const [showDrawer, setShowDrawer] = useState(false);
  const [headerTitle, setHeaderTitle] = React.useState<string>();
  const anchor = React.useRef<HTMLButtonElement | null>(null);
  const [show, setShow] = React.useState(false);
  const navigateTo = useNavigate();

  React.useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const firstSegment = pathSegments.length > 1 ? pathSegments[1] : "";
    switch (firstSegment) {
      case "Projects":
        setHeaderTitle("Projects");
        break;

      case "lease":
        setHeaderTitle("Lease Management System");
        break;

      case "visa":
        setHeaderTitle("Visa Management System");
        break;
    }
  }, [location]);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  const logout = () => {
    localStorage.removeItem("atToken");
    localStorage.removeItem("rtToken");
    localStorage.removeItem("user");
    if (userState?.user?.idProvider === "EntraId") {
      instance.logoutRedirect();
    }
    navigateTo("/");
  };

  const handleMenuClick = () => {
    dispatch(setSelectedMenu(commonState?.selected));
  };
  return (
    <>
      <div>
        <ImportDrawer show={showDrawer} setShow={setShowDrawer} />
        <div className="flex items-center px-4 py-[12px]  bg-[#F0F9FF] gap-4">
          {window.location.pathname === "/home" && (
            <Button
              className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md btn p-0 border-none outline-none hover:bg-transparent bg-transparent"
              onClick={() => handleMenuClick()}
            >
              <Menu className="text-[8px] text-[#484F61]" />
            </Button>
          )}
          {/* <div className='flex-1 flex items-center lg:gap-4 gap-2'>

        <Button fillMode="outline" themeColor={"primary"} onClick={() => setShowDrawer(true)} disabled={commonState?.selectedProject?.event?.item?.level !== 2}>Import</Button>
        <Button fillMode="outline" themeColor={"primary"} disabled={!commonState?.selectedBBS} onClick={handleExport}>Export</Button>
        <img src="../../images/logo-peopleflow.png" alt="" className='lg:h-6 h-8 lg:hidden block' />
        <h3 className='sm:text-xl text-[12px] tracking-[-0.04px] font-kanit text-[var(--kendo-neutral-160)]'>{headerTitle}</h3>
      </div> */}
          <div className="flex self-center">
            {/* <img src="./images/logo-black.png" alt="" className="self-center" /> */}
            <h3 className="sm:text-xl text-[12px] tracking-[-0.04px] font-kanit text-[var(--kendo-neutral-160)]">
              Bar Bending
            </h3>
          </div>
          <div className="ml-auto flex">
            {window.location.pathname === "/home" ? (
              <>
                <i
                  className="mr-4 cursor-pointer self-center"
                  onClick={() => dispatch(setSelectedProject(null))}
                >
                  <House />
                </i>
                <i
                  className="mr-4 cursor-pointer self-center"
                  onClick={() => navigateTo("/settings/companies")}
                >
                  <Settings />
                </i>
              </>
            ) : (
              <i
                className="mr-4 cursor-pointer self-center"
                onClick={() => navigateTo("/home")}
              >
                <House />
              </i>
            )}
            <div className="flex sm:gap-4 gap-2 items-center">
              <button
                className="p-0 border-none inline-flex items-center gap-[10px] outline-none hover:bg-transparent bg-transparent"
                onClick={onClick}
                ref={anchor}
              >
                <Avatar rounded="full" type="text">
                  {/* <img src="./images/avatar.png" alt="" /> */}
                  {userState?.user?.name
                    ? getInitials(userState?.user?.name)
                    : ""}
                </Avatar>
                <span className="text-sm font-normal sm:block hidden">
                  {userState?.user?.name}
                </span>
              </button>

              <Popup
                anchor={anchor.current}
                show={show}
                anchorAlign={{ horizontal: "right", vertical: "bottom" }}
                popupAlign={{ horizontal: "right", vertical: "top" }}
                margin={{ horizontal: 0, vertical: 9 }}
                popupClass={"menuPopup"}
              >
                <div className="p-2">
                  <p className="block line-clamp-1 break-words text-xs">
                    Signed in as
                  </p>
                  <span className="block line-clamp-1 break-words text-xs">
                    {userState?.user?.email}
                  </span>
                </div>
                <ul>
                  {/* <li className='border-t border-neutral-30'>
                <Link to="#" className='inline-flex items-center py-2 px-2 gap-2 text-txtPrimary hover:bg-neutral-30 w-full transition-all duration-300'><AsteriskSquare className='w-4 h-4' /> Change password</Link>
              </li> */}
                  <li className="border-t border-neutral-30 ">
                    <span
                      className="inline-flex items-center py-2 px-2 gap-2 text-txtPrimary hover:bg-neutral-30 w-full transition-all duration-300 cursor-pointer"
                      onClick={logout}
                    >
                      <LogOut className="w-4 h-4" /> Sign out
                    </span>
                  </li>
                </ul>
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
