
import React from 'react'
import OnBoardingContainer from '../../component/ui/container/OnBoardingContainer'
import RecoverNotify from '../../component/onboarding/RecoverNotify'

const RecoverEmail = () => {
  return (
    <OnBoardingContainer>
      <RecoverNotify />
    </OnBoardingContainer>
  )
}

export default RecoverEmail