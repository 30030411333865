import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Trash2 } from "lucide-react";
import * as React from "react";

export const CommandCell = (props: any) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.ProductID === undefined;
  const [visible, setVisible] = React.useState(false);
  const onDeleteData = () => {
    props.remove(props.dataItem);
    setVisible(!visible);
  };
  const toggleDialog = () => {
    setVisible(!visible);
  };

  return (
    <td
      className="k-command-cell inline-edit-cell"
      style={{
        alignContent: "center",
        justifyContent: "end",
        alignItems: "end",
        textAlign: "end",
      }}
    >
      <Trash2
        className="ml-auto mr-10"
        size={20}
        onClick={() =>
          inEdit
            ? isNewItem
              ? props.discard(dataItem)
              : props.cancel(dataItem)
            : toggleDialog()
        }
      />

      {visible && (
        <Dialog
          title={"Delete Data"}
          onClose={toggleDialog}
          width={350}
          style={{ zIndex: 100000 }}
        >
          <div>
            Are you sure you want to delete item with ID {dataItem.ProductID}?
          </div>
          <DialogActionsBar>
            <Button onClick={onDeleteData}>Delete</Button>
            <Button onClick={toggleDialog}>Cancel</Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </td>
  );
};
