import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools/pager/Pager";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  GridColumn as Column,
  Grid,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { debounce } from "lodash";
import { Plus } from "lucide-react";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import Export from "../../component/global/Export";
import SearchBox from "../../component/ui/SearchBox";
import Filters from "../../constants/filters";
import IPageState from "../../interfaces/page.interface";
import { ISearchFilter } from "../../interfaces/searchFilter.interface";
import { ISubContractor } from "../../interfaces/subcontractor.interface";
import { getPaginationData } from "../../utils/utils";
import WriteDrawer from "./WriteDrawer";
import { columnInterface, generateColumns } from "./columns";
import { IObjectType } from "../../interfaces/objectType.interface";
import objectTypeSvc from "../../services/objectType.service";
import { setToast } from "../../store/features/toastSlice";
import { useAppDispatch } from "../../store/hooks/hooks";

let mappingChanged = false;

export const Listing = () => {
  const _exporter = createRef<ExcelExport>();
  const excelExport = () => {
    if (_exporter.current) {
      fetchAllObjectTypes();
    }
  };
  const dispatch = useAppDispatch();
  const Pagination = getPaginationData();

  const debouncedSearch = useRef(
    debounce((value: string) => fetchObjectTypes(value), 500)
  ).current;

  const [addNewObjectType, setAddNewObjectType] = useState<
    (IObjectType & { id: string }) | null
  >(null);
  const [allObjectTypes, setAllObjectTypes] = useState([]);
  const [columnsMapping, setColumnsMapping] = useState<any>(null);
  const [dataState, setDataState] = useState<State>(
    Pagination.initialDataState
  );
  const [page, setPage] = useState<IPageState>(Pagination.initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >();
  const [searchValue, setSearchValue] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [objectTypes, setObjectTypes] = useState<any>([]);
  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(
      fetchObjectTypesWrapper,
      handleEditClick,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    )
  );
  const [total, setTotal] = useState(0);

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      fetchObjectTypesWrapper,
      handleEditClick,
      onColumnsSubmit,
      columnsState,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? objectTypes.length : event.page.take;

    fetchObjectTypes(searchValue, null, event.page.skip, event.page.take);
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length !== 1) {
      debouncedSearch(value);
    }
  };

  const handleChange = (e: TextBoxChangeEvent) => {
    setAddNewObjectType((prev: any) => {
      return { ...prev, [e.target.name as any]: e.target.value };
    });
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
    fetchObjectTypes(
      searchValue,
      event.dataState.filter,
      event.dataState.sort,
      page.skip,
      page.take
    );
  };

  const createObjectType = () => {
    objectTypeSvc.create(addNewObjectType).then((res) => {
      if (!res.error) {
        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "Object Type added successfully",
          })
        );
      }
      fetchObjectTypes(searchValue);
      setAddNewObjectType(null);
      setShowDrawer(false);
    });
  };

  const editObjectType = () => {
    if (!addNewObjectType?.id) return;
    objectTypeSvc.update(addNewObjectType.id, addNewObjectType).then((res) => {
      if (!res.error) {
        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "Object Type updated successfully",
          })
        );
      }
      fetchObjectTypes(searchValue);
      setAddNewObjectType(null);
      setShowDrawer(false);
    });
  };

  function handleEditClick(data: ISubContractor) {
    setAddNewObjectType(data);
    setShowDrawer(true);
  }

  const fetchObjectTypes = useCallback(
    async (
      searchQuery = "",
      filter?: CompositeFilterDescriptor | null,
      sort?: any,
      pageNumber = 0,
      pageSize = Pagination.initialDataState.take
    ) => {
      let searchFilters: ISearchFilter[] = [];
      if (searchQuery) {
        searchFilters = [
          { field: "Name", operator: "contains", value: searchQuery },
          { field: "Code", operator: "contains", value: searchQuery },
        ];
      }
      const res = await objectTypeSvc.get(
        searchFilters,
        filter,
        sort,
        pageNumber,
        pageSize
      );
      if (res.data) {
        setObjectTypes(res.data);
        setTotal(res.data.total);
      }
    },
    []
  );

  const fetchAllObjectTypes = async () => {
    objectTypeSvc.getAll().then((res) => {
      setAllObjectTypes(res.data);
    });
  };

  useEffect(() => {
    if (objectTypes.length) return;
    fetchObjectTypes(searchValue);
  }, []);

  useEffect(() => {
    if (!objectTypes?.length || !columnsMapping) return;
    const cols = generateColumns(
      fetchObjectTypesWrapper,
      handleEditClick,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }, [objectTypes, columnsMapping]);

  useEffect(() => {
    if (!allObjectTypes?.length) return;
    _exporter.current?.save();
    setAllObjectTypes([]);
  }, [allObjectTypes]);

  function fetchObjectTypesWrapper() {
    fetchObjectTypes(searchValue);
  }

  return (
    <>
      <div className="setting-page">
        <Grid
          style={{ maxHeight: "" }}
          data={objectTypes}
          {...dataState}
          onDataStateChange={dataStateChange}
          skip={page.skip}
          take={page.take}
          total={total}
          pageable={{
            ...Pagination.pageSizeValue,
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          className={`customHeight employee`}
          resizable={Filters.resizeable}
          selectable={{
            enabled: true,
            drag: true,
            mode: "multiple",
          }}
        >
          <GridToolbar>
            <div className="flex gap-3 w-full items-center">
              <div className="w-[250px]">
                <SearchBox
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search"
                />
              </div>
              <Button
                themeColor={"primary"}
                className="border border-[#E2E8F0]"
                onClick={() => setShowDrawer(true)}
              >
                <span className="inline-flex items-center gap-2">
                  <Plus className="w-4 h-4" /> Add New
                </span>
              </Button>
              <div
                className="cursor-pointer  sm:ml-0 ml-auto sm:flex-initial flex-1"
                onClick={excelExport}
              >
                <Export />
                <ExcelExport
                  data={allObjectTypes}
                  collapsible={true}
                  fileName="Object Types.xlsx"
                  ref={_exporter}
                >
                  {stateColumns &&
                    stateColumns.length > 0 &&
                    stateColumns.map((column: any, idx: number) => {
                      if (column.show) {
                        return (
                          <ExcelExportColumn
                            key={idx}
                            field={column.field}
                            title={column.title}
                          />
                        );
                      }
                    })}
                </ExcelExport>
              </div>
            </div>
          </GridToolbar>
          {stateColumns &&
            stateColumns.length > 0 &&
            stateColumns.map((column: any, idx: number) => {
              if (column.show) {
                if (column.cells) {
                  return (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      columnMenu={column.columnMenu}
                      minResizableWidth={200}
                      // width={200}
                      cells={column.cells}
                      filter={column.filter ? column.filter : "text"}
                    />
                  );
                }
                return (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    columnMenu={column.columnMenu}
                    minResizableWidth={200}
                    // width={200}
                    filter={column.filter ? column.filter : "text"}
                  />
                );
              }
            })}
        </Grid>
      </div>
      <WriteDrawer
        showCreateDrawer={showDrawer}
        setShowCreateDrawer={setShowDrawer}
        addNewObjectType={addNewObjectType}
        handleChange={handleChange}
        onSave={addNewObjectType?.id ? editObjectType : createObjectType}
      />
    </>
  );
};
