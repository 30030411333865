import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldWrapper, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { ArrowLeft, Mail } from "lucide-react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IForgotPassword } from "../../interfaces/auth.interface";
import authService from "../../services/auth.service";
import EmailInputWithValidation from "../../shared-components/EmailInputWithValidation";
import { EMAIL_VALIDATOR } from "../../utils/utils";

const ForgotPasswordForm = () => {
  const [forgotPassword] =
    React.useState<IForgotPassword>({
      email: ""
    });

  const navigateTo = useNavigate();

  const handleSubmit = (dataItem: { [name: string]: any }) => {
    authService.forgotPassword(dataItem.email).then((response: any) => {
      if(!response.error){
        navigateTo("/recover-email");
      }
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <div className="w-full">
      <div className="mb-4">
        <Link to="/login">
          <Button fillMode="link" themeColor={"primary"} className="p-0">
            <span className="inline-flex items-center">
              <ArrowLeft className="w-4 h-4" /> <span className="ml-1">Back</span>
            </span>
          </Button>
        </Link>
      </div>

      <h3 className="text-2xl font-kanit mb-8">Recover your Password</h3>

      <Form
        initialValues={forgotPassword}
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement className="mb-4">
            <fieldset className={"k-form-fieldset"}>
              <FieldWrapper>
                <div className="k-form-field-wrap">
                  <div className="relative loginField">
                    <Field
                      name={"email"}
                      type={"email"}
                      component={EmailInputWithValidation}
                      label={"Email ID"}
                      placeholder={"Enter Email ID"}
                      labelClassName="font-cabin font-medium mb-2 text-[var(--kendo-neutral-160)]"
                      validator={EMAIL_VALIDATOR}
                    />
                    <Mail className="absolute top-[37px] -translate-y-1/2 right-2 w-4 h-4" />
                  </div>
                </div>
              </FieldWrapper>
            </fieldset>

            <div className="k-form-buttons">
              <Button
                className="w-full"
                themeColor={"primary"}
                disabled={!formRenderProps.allowSubmit}
              >
                Continue
              </Button>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
}

export default ForgotPasswordForm;
