import { Button } from "@progress/kendo-react-buttons";
import { Switch } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Plus } from "lucide-react";
import React from "react";
import SearchBox from "../ui/SearchBox";
import ExportExcel from "./ExportExcel";
import { isAuthorize } from "../../utils/utils";

function SettingsGridToolbar({
  searchValue,
  handleSearchChange,
  handleAddClick,
  setShowArchived,
  data,
  excelColumns,
  excelFileName,
  showArchived,
}: {
  searchValue: string;
  handleSearchChange: (value: string) => void;
  handleAddClick: () => void;
  setShowArchived: React.Dispatch<React.SetStateAction<boolean>>;
  data: any[];
  excelColumns: any[];
  excelFileName: string;
  showArchived?: boolean;
}) {
  return (
    <div className="flex sm:gap-2 flex-wrap items-center w-full">
      <div className="w-[250px]">
        <SearchBox
          value={searchValue}
          onChange={handleSearchChange}
          placeholder="Search"
        />
      </div>
      <Button
        themeColor={"primary"}
        className="border border-[#E2E8F0]"
        onClick={handleAddClick}
      >
        <span className="inline-flex items-center gap-2">
          <Plus className="w-4 h-4" /> Add New
        </span>
      </Button>
      {showArchived === true && <div className="flex items-center gap-2 md:order-3 sm:order-2 order-4 sm:mt-0 mt-2 sm:ml-0 ml-auto">
        <Label className="font-medium text-sm font-cabin">
          Show Inactive
        </Label>
        <Switch
          defaultChecked={false}
          onChange={(e) => {
            setShowArchived(e.value);
          }}
        />
      </div>}
      <ExportExcel
        data={data}
        columns={excelColumns}
        fileName={excelFileName}
      />
    </div>
  );
}

export default SettingsGridToolbar;
