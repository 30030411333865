import { Button } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import { Building2, ChevronsUpDown, Settings, WalletCards } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PROJECT_ADMIN,
  PROJECT_CONTRIBUTOR,
  VISITOR,
  SUPER_USER,
} from "../../../utils/roles.constant";
import { isAuthorize } from "../../../utils/utils";
import { SidebarItem } from "./Sidebar";

interface ConfigItem {
  icon: React.ElementType;
  name: string;
  roles: string[];
}

const configItems: ConfigItem[] = [
  {
    icon: Building2,
    name: "Projects",
    roles: [SUPER_USER, PROJECT_ADMIN, PROJECT_CONTRIBUTOR],
  },
  {
    icon: Settings,
    name: "Settings",
    roles: [SUPER_USER, PROJECT_ADMIN],
  },
];

interface SidebarProps {
  selected: boolean;
  selectedConfig: string;
  setSelectedConfig: (opt: string) => void;
  sidebarItemsProjects: SidebarItem[];
  sidebarItemsSettings: SidebarItem[];
}

const MenuConfig: React.FC<SidebarProps> = ({
  selected,
  selectedConfig,
  setSelectedConfig,
  sidebarItemsProjects,
  sidebarItemsSettings,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    handleOptionSelected();
  }, []);
  const handleOptionSelected = () => {
    for (let leaseItem of sidebarItemsProjects) {
      if (location.pathname.includes(leaseItem.link)) {
        setSelectedConfig("Projects");
      }
    }
    for (let settingsItem of sidebarItemsSettings) {
      if (settingsItem.routes.includes(location.pathname)) {
        setSelectedConfig("Settings");
      }
    }
    hidePopup();
  };

  const anchor = React.useRef<any>();
  const [show, setShow] = React.useState(false);

  const [selectedItem, setSelectedItem] = useState<string>("");

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  const hidePopup = () => {
    setShow(false);
  };

  const handleItemClick = (item: string) => {
    if (item !== selectedItem) {
      setSelectedItem(item);
      switch (item) {
        case "Projects":
          navigate("/home");
          break;

        case "Settings":
          navigate("/settings/subcontractor");
          break;
      }
    }

    hidePopup();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (anchor.current && !anchor.current.contains(event.target as Node)) {
      hidePopup();
    }
  };

  return (
    <ul className="text-white flex flex-col">
      <li>
        <button
          className="w-full text-left flex px-4 py-[9px] gap-3 bg-info-190"
          onClick={onClick}
          ref={anchor}
        >
          <span className={`flex-1 ${selected ? "lg:hidden" : ""}`}>
            {" "}
            {selectedConfig}
          </span>
          <ChevronsUpDown />
        </button>
        <Popup
          anchor={anchor.current}
          show={show}
          popupClass={`${selected ? "lg:ml-2 mt-2" : ""} w-[280px] rounded-0`}
        >
          {configItems.map((item, index) => {
            return (
              <Button
                fillMode={"flat"}
                key={index}
                className="flex items-center px-4 py-[9px] gap-3 rounded-none btnMenu"
                onClick={() => handleItemClick(item.name)}
              >
                <span>{item.name}</span>
              </Button>
            );
          })}
        </Popup>
      </li>
    </ul>
  );
};

export default MenuConfig;
