import { Controls, Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../animation.json";
import { selectCommon } from "../../store/features/commonSlice";
import { useAppSelector } from "../../store/hooks/hooks";

interface CustomLoaderProps {
  isStartup?: boolean;
}

const CustomLoader = ({ isStartup }: CustomLoaderProps) => {
  const commonState = useAppSelector(selectCommon);

  return (
    <>
      {(commonState.requestsInProgress > 0 || isStartup) && (
        <div className="absolute inset-0 flex items-center justify-center bg-white/20 z-[10003] h-full w-full">
          <Player
            autoplay
            loop
            src={animationData}
            className="w-[80px]"
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
        </div>
      )}
    </>
  );
}

export default CustomLoader;
