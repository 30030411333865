import ForgotPasswordForm from "../../component/onboarding/ForgotPasswordForm"
import OnBoardingContainer from "../../component/ui/container/OnBoardingContainer"



const ForgotPassword = () => {
  return (
    <OnBoardingContainer>
      <ForgotPasswordForm />
    </OnBoardingContainer>
  )
}

export default ForgotPassword