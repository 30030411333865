import { Button } from "@progress/kendo-react-buttons";

function Export() {
  return (
    <Button className="border border-[#E2E8F0]">
      <span className="inline-flex items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 36 36"
        >
          <path
            fill="#201f1e"
            d="M6 13.61h7.61V6H24v8.38h2V6a2 2 0 0 0-2-2H10.87L4 10.87V30a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2H6Zm0-1.92L11.69 6H12v6H6Z"
            className="clr-i-outline clr-i-outline-path-1"
          />
          <path
            fill="#201f1e"
            d="M28.32 16.35a1 1 0 0 0-1.41 1.41L30.16 21H18a1 1 0 0 0 0 2h12.19l-3.28 3.28a1 1 0 1 0 1.41 1.41L34 22Z"
            className="clr-i-outline clr-i-outline-path-2"
          />
          <path fill="none" d="M0 0h36v36H0z" />
        </svg>
        <span>Export</span>
      </span>
    </Button>
  );
}

export default Export;
