import { api } from '../lib';
import { BaseService } from './base.service';
import LoaderService from './loader.service';

class TemplateMappingService extends BaseService {
  constructor() {
    super('TemplateMappingInterim');
  }

  uploadExcelFile = async (
    file: any,
    projectId: string,
    levelCode: string,
    subLevelCode: string
  ) => {
    try {
      LoaderService.showLoader();
      const formData = new FormData();
      formData.append('file', file);
      formData.append('ProjectId', projectId);
      formData.append('LevelCode', levelCode);
      formData.append('SubLevelCode', subLevelCode);
      const res = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/BBSFileUpload/upload`,
        formData
      );
      LoaderService.hideLoader();
      if (res.ok) {
        if (res.data) {
          return { data: res.data, error: null };
        }
        return { data: null, error: res.data };
      }
    } catch (error) {
      LoaderService.hideLoader();
      return { data: null, error: error };
    }
  };
}

const templateMappingSvc = new TemplateMappingService();

export default templateMappingSvc;
