import ResetPasswordForm from "../../component/onboarding/ResetPasswordForm"
import OnBoardingContainer from "../../component/ui/container/OnBoardingContainer"

const ResetPassword = () => {
  return (
    <OnBoardingContainer>
      <ResetPasswordForm />
    </OnBoardingContainer>
  )
}

export default ResetPassword;
