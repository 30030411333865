import { Button } from "@progress/kendo-react-buttons";
import {
  Checkbox,
  CheckboxChangeEvent,
  TextBox,
  TextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import BBSTextField from "../../component/inputs/BBSTextField";
import { ICompany } from "../../interfaces/company.interface";
import { Upload } from "@progress/kendo-react-upload";
import { IShapeCodes } from "../../interfaces/shapes.interface";
import CustomLabel from "../../component/global/RequiredMark";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { IStandard } from "../../interfaces/standard.interface";
import ErrorMessage from "../../component/global/ErrorMessage";
interface IProps {
  handleFileUpload: any;
  showCreateDrawer: boolean;
  setShowCreateDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  addNewShape: (IShapeCodes & { id: string }) | null;
  handleChange: (e: any, isDropdown?: boolean) => void;
  onSave: () => void;
  onCancel: () => void;
  setLogoFile: React.Dispatch<any>;
  logoFile: any;
  standards: IStandard[];
}

var Dimensions = [
  { name: "A", checked: false },
  { name: "B", checked: false },
  { name: "C", checked: false },
  { name: "D", checked: false },
  { name: "E", checked: false },
  { name: "F", checked: false },
  { name: "G", checked: false },
  { name: "H", checked: false },
  { name: "J", checked: false },
  { name: "K", checked: false },
  { name: "R", checked: false },
  { name: "H", checked: false },
  { name: "dimH2", checked: false },
  { name: "H1", checked: false },
  { name: "O", checked: false },
];

function WriteDrawer({
  handleFileUpload,
  showCreateDrawer,
  setShowCreateDrawer,
  addNewShape,
  handleChange,
  onSave,
  onCancel,
  setLogoFile,
  logoFile,
  standards,
}: IProps) {
  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});

  const [validFields, setValidFields] = useState<any>({
    formula: null,
  });

  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev: any) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  const validateSave = () => {
    if (
      !addNewShape?.code ||
      !addNewShape?.imagePath ||
      !addNewShape?.formula ||
      !addNewShape?.standardId
    ) {
      return false;
    }

    return true;
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    var name =
      e.target?.name == "dimH2"
        ? e.target?.name
        : e.target?.name?.replace("dim", "");
    Dimensions[Dimensions.findIndex((x) => x.name == name)].checked = e.value;
    if (addNewShape?.formula) {
      validateFormula(addNewShape?.formula);
    }
  };

  const handleFormulaChange = (e: TextBoxChangeEvent) => {
    const val = e.value as string;
    validateFormula(val);
  };

  const validateFormula = (val: string) => {
    const alphabet = Dimensions.filter((x) => x.checked)
      .map((x) => x.name)
      .join("")
      .toLowerCase();

    const operators = "+\\-*/()"; // Escaped the hyphen

    const regex = new RegExp(
      `[^${alphabet}${alphabet.toUpperCase()}${operators}\\d\\s]`,
      "g"
    );
    const containsInvalidChars = regex.test(val);
    const containsValidDimension = new RegExp(
      `[${alphabet}${alphabet.toUpperCase()}]`
    ).test(val);

    setValidFields((prev: any) => {
      return {
        ...prev,
        ["formula"]:
          !containsInvalidChars && containsValidDimension ? null : "Invalid",
      };
    });
  };

  useEffect(() => {
    if (!logoFile) {
      setInvalidFields({});
    }
  }, [logoFile]);

  const getStandardValue = () => {
    const standard = standards?.find(
      (std) => std.id === addNewShape?.standardId
    );
    if (!standard) return "";
    return standard;
  };

  useEffect(() => {
    if (!addNewShape) return;
    Dimensions = Dimensions.map((dimension) => {
      const key = `dim${dimension.name}`; // Create the key corresponding to each dimension name
      if (addNewShape.hasOwnProperty(key)) {
        //@ts-ignore
        dimension.checked = addNewShape[key];
      }
      return dimension;
    });
  }, [addNewShape]);

  return (
    <Drawer
      expanded={showCreateDrawer}
      position={"start"}
      mode={"overlay"}
      animation={{ duration: 400 }}
      className="customDrawer2 transition-all duration-500"
    >
      <DrawerContent>
        <div
          className="overlay"
          onClick={() => setShowCreateDrawer(false)}
        ></div>
        <div
          className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 overflow-y-scroll ${
            showCreateDrawer ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="py-3 flex items-center justify-between gap-2 border-b border-neutral-30">
            <h2 className="font-medium text-lg">
              {addNewShape?.id ? "Edit Shape" : "Add Shape"}
            </h2>
            <Button
              fillMode="flat"
              className="p-2 bg-none outline-none"
              onClick={() => setShowCreateDrawer(false)}
            >
              <X className="w-4 h-4" />{" "}
            </Button>
          </div>
          <div className="flex-1 py-2">
            <div className="grid gap-2">
              <div>
                <CustomLabel label="Standard" required={true} />

                <ComboBox
                  data={standards || []}
                  onChange={(e) => {
                    handleChange(e, true);
                  }}
                  value={getStandardValue()}
                  name="standardId"
                  clearButton={false}
                  placeholder="Select Standard"
                  textField="name"
                  dataItemKey="id"
                />
              </div>
              <div>
                <BBSTextField
                  name="code"
                  value={addNewShape?.code}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Code"
                  required={true}
                  placeholder="Enter Code"
                  disabled={false}
                  // error="Invalid Name"
                />
              </div>

              <div className="py-2 flex items-center justify-between gap-2 border-b border-neutral-30">
                <h3 className="font-medium text-lg">Dimensions</h3>
              </div>

              <div className="grid grid-cols-3 gap-2">
                <div>
                  <Checkbox
                    name="dimA"
                    value={addNewShape?.dimA || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"A"}
                  />
                  <Checkbox
                    name="dimB"
                    value={addNewShape?.dimB || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"B"}
                  />
                  <Checkbox
                    name="dimC"
                    value={addNewShape?.dimC || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"C"}
                  />
                  <Checkbox
                    name="dimD"
                    value={addNewShape?.dimD || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"D"}
                  />
                  <Checkbox
                    name="dimE"
                    value={addNewShape?.dimE || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"E"}
                  />
                </div>
                <div>
                  <Checkbox
                    name="dimF"
                    value={addNewShape?.dimF || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"F"}
                  />
                  <Checkbox
                    name="dimG"
                    value={addNewShape?.dimG || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"G"}
                  />
                  <Checkbox
                    name="dimH"
                    value={addNewShape?.dimH || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"H"}
                  />
                  <Checkbox
                    name="dimJ"
                    value={addNewShape?.dimJ || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"J"}
                  />
                  <Checkbox
                    name="dimK"
                    value={addNewShape?.dimK || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"K"}
                  />
                </div>
                <div>
                  <Checkbox
                    name="dimR"
                    value={addNewShape?.dimR || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"R"}
                  />
                  <Checkbox
                    name="dimH1"
                    value={addNewShape?.dimH1 || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"H1"}
                  />
                  <Checkbox
                    name="dimH2"
                    value={addNewShape?.dimH2 || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"dimH2"}
                  />
                  <Checkbox
                    name="dimO"
                    value={addNewShape?.dimO || false}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                      handleChange(e);
                    }}
                    label={"O"}
                  />
                </div>
              </div>

              <div className="py-2 flex items-center justify-between gap-2 border-b border-neutral-30">
                <h3 className="font-medium text-lg">Functions</h3>
              </div>
              <div>
                <BBSTextField
                  name="dimensionsConstraint"
                  value={addNewShape?.dimensionsConstraint}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Dimensions Constraint"
                  required={false}
                  placeholder="Enter Dimensions Constraint"
                  disabled={false}
                  // regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                  // error="Invalid Name"
                />
              </div>
              <div>
                <BBSTextField
                  name="condition"
                  value={addNewShape?.condition}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Condition"
                  required={false}
                  placeholder="Enter Condition"
                  disabled={false}
                  // regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                  // error="Invalid Name"
                />
              </div>
              <div>
                {/* <BBSTextField
                  name="formula"
                  value={addNewShape?.formula}
                  onChange={(e) => {
                    handleFormulaChange(e);
                    handleChange(e);
                  }}
                  onValidate={handleValidation}
                  label="Formula"
                  required={true}
                  placeholder="Enter Formula"
                  disabled={false}
                  // regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                   error="Formula contains invalid dimensions, Please check the dimensions selected above"
                  // error="Invalid Name"
                /> */}

                <CustomLabel label="Formula" required={true} />
                <TextBox
                  value={addNewShape?.formula}
                  onChange={(e) => {
                    handleFormulaChange(e);
                    handleChange(e);
                  }}
                  // onValidate={handleValidation}
                  placeholder="Enter Formula"
                  name="formula"
                  valid={!validFields.formula}
                />
                {validFields?.formula && (
                  <ErrorMessage
                    message="*Formula contains invalid dimensions, Please check the
                    dimensions selected above"
                  />
                )}
              </div>
              <div className="flex items-center justify-between gap-2">
                <h3 className="font-medium text-lg">
                  Image <span className="text-red-600 ml-1">*</span>
                </h3>
              </div>
              <div>
                <Upload
                  batch={false}
                  multiple={false}
                  withCredentials={false}
                  files={logoFile ? [logoFile] : []}
                  onAdd={handleFileUpload}
                  onRemove={() => {
                    setLogoFile(null);
                    addNewShape!.imagePath = "";
                  }}
                />

                {addNewShape?.imagePath && (
                  <div className="flex justify-center py-2">
                    <img
                      src={addNewShape?.imagePath}
                      style={{ maxWidth: "200px" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 pb-4">
            <Button className="border border-[#E2E8F0]" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="border border-[#E2E8F0]"
              themeColor={"primary"}
              onClick={onSave}
              disabled={!validateSave()}
            >
              Save
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default WriteDrawer;
