import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import React, { createRef } from "react";
import Export from "./Export";
import { IExcelColumn } from "../../interfaces/excel-columns.interface";

function ExportExcel({
  data,
  columns,
  fileName,
}: {
  data: any[];
  columns: IExcelColumn[];
  fileName: string;
}) {
  const _exporter = createRef<ExcelExport>();

  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };
  return (
    <div className="cursor-pointer" onClick={excelExport}>
      <Export />
      <ExcelExport
        data={data}
        collapsible={true}
        fileName={fileName}
        ref={_exporter}
      >
        {columns?.map((col, idx) => {
          return (
            <ExcelExportColumn
              key={idx}
              field={col.field}
              title={col.title}
              width={col.width}
            />
          );
        })}
      </ExcelExport>
    </div>
  );
}

export default ExportExcel;
