import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import App from '../App';
import settingsSvc from '../services/settings.service';
import { setSettings } from '../store/features/settingsSlice';
import { useAppDispatch } from '../store/hooks/hooks';
import CustomLoader from './loader';

function Startup() {
  const [msalInstance, setMsalInstance] = useState<any>(null);
  const [isMsalInitialized, setIsMsalInitialized] = useState(false);
  const [scope, setScope] = useState("");
  const [settingsData, setSettingsData] = useState<any>(null);
  const dispatch = useAppDispatch();

  const getEntraIdData = (settings: any) => {
    const authData = settings.auth;
    return authData.find((item: any) => item.type === "EntraID");
  }

  const storeSettings = (settings: any) => {
    const pagination = settings?.pagination;
    if(pagination){
      sessionStorage.setItem("Pagination", JSON.stringify(pagination));
    }
  }

  const initializeAuth = async () => {
    const { data, error } = await settingsSvc.get();
    const entraIdData = getEntraIdData(data);
    storeSettings(data);

    dispatch(setSettings(data))
    setSettingsData(data);

    if (data) {
      setScope(entraIdData.frontend.backendScope)
      const msalConfig = {
        auth: {
          clientId: entraIdData.frontend.clientId!,
          authority: entraIdData.frontend.authority,
          redirectUri: `${window.location.protocol}//${window.location.host}/sso`,
          postLogoutRedirectUri: "/",
          // navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        }
      }
      const msalInstance = new PublicClientApplication(msalConfig);

      msalInstance.initialize();

      const activeAccount = msalInstance.getActiveAccount();


      if (!activeAccount) {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0]);
        }
      }

      msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const authenticationResult = event.payload as AuthenticationResult;
          const account = authenticationResult.account;
          msalInstance.setActiveAccount(account);
        }
      });

      msalInstance.enableAccountStorageEvents();

      setIsMsalInitialized(true);
      setMsalInstance(msalInstance);
    }
  }

  useEffect(() => {
    initializeAuth();
  }, [])

  if (!isMsalInitialized) {
    return <CustomLoader isStartup={true} />
  }
  return (
    <MsalProvider instance={msalInstance}>
      <App msalInstance={msalInstance} msalScope={scope} settings={settingsData}/>
    </MsalProvider>
  )
}

export default Startup;
