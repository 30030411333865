import * as React from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { ISubContractor } from "../../../interfaces/subcontractor.interface";
import { IBarSize } from "../../../interfaces/standard.interface";

export const BarsizeDropdownCell = (
  props: GridCellProps & {
    barSizes: IBarSize[] | undefined;
  }
) => {
  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: props.dataItem.idx,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.size,
      });
    }
  };

  const { dataItem } = props;
  const field = "size" || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  return (
    <td>
      {dataItem.inEdit ? (
        <DropDownList
          style={{ width: 75, height: 30 }}
          onChange={handleChange}
          value={props.barSizes?.find((c) => c.size == dataValue)}
          data={props.barSizes}
          dataItemKey="size"
          textField="size"
        />
      ) : dataValue == null ? (
        ""
      ) : (
        dataValue
      )}
    </td>
  );
};
