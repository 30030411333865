import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SidebarItem } from "./Sidebar";

interface SidebarProps {
  selected: boolean;
  selectedConfig: string;
  sidebarItemsProjects: SidebarItem[];
  sidebarItemsSettings: SidebarItem[];
}

const Menu: React.FC<SidebarProps> = ({
  selected,
  selectedConfig,
  sidebarItemsProjects,
  sidebarItemsSettings,
}) => {
  const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>([]);
  const location = useLocation();

  useEffect(() => {
    switch (selectedConfig) {
      case "Projects":
        setSidebarItems(sidebarItemsProjects);
        break;

      case "Settings":
        setSidebarItems(sidebarItemsSettings);
        break;
    }
  }, [
    selectedConfig,
    sidebarItemsProjects,
    sidebarItemsSettings,
  ]);

  const handleClick = (opt: string) => {
    localStorage.setItem("menuOption", opt);
  };

  const handleOptionSelected = () => {
    for (let leaseItem of sidebarItemsProjects) {
      if (leaseItem.routes.includes(location.pathname)) {
        return leaseItem.name;
      } else if (/^\/lease\/\d+$/.test(location.pathname)) {
        return "Projects";
      }
    }

    for (let settingsItem of sidebarItemsSettings) {
      if (settingsItem.routes.includes(location.pathname)) {
        return settingsItem.name;
      }
    }
  };
  return (
    <ul className="text-white flex flex-col">
      {sidebarItems.map((item, index) => (
        <React.Fragment key={index}>
          {item.name === "LookUp" && index > 0 && (
            <li
              key={`separator-${index}`}
              className="w-full h-px bg-gray-400 opacity-50 mb-3"
            ></li>
          )}

          <li key={index} className="w-full mb-3 last-of-type:mb-0">
            <NavLink
              to={item.link}
              className={`flex relative  items-center text-sm gap-3 px-4 py-[9px] hover:bg-[var(--kendo-neutral-30)]  hover:text-black  transition-all duration-300 before:content-[''] before:left-[2px] before:absolute  before:w-[1.5px] before:top-[1px] before:bottom-[1px] ${item.name === handleOptionSelected()
                  ? "text-black bg-[var(--kendo-neutral-30)] before:bg-primary-130"
                  : "text-white bg-transparent before:bg-transparent "
                }`}
              onClick={() => {
                handleClick(item.name);
              }}
            >
              <item.icon size={20} strokeWidth={1.67} />
              <span className={`flex-1 ${selected ? "lg:hidden" : " "} `}>
                {item.name}
              </span>
            </NavLink>
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
};

export default Menu;
