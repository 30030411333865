import { Button } from "@progress/kendo-react-buttons";
import { Trash2 } from "lucide-react";

function BtnDelete({ onClickHandler }: { onClickHandler: () => void }) {
  return (
    <Button className="border border-[#E2E8F0]" onClick={onClickHandler}>
      <div className="flex gap-x-2">
        <Trash2 className="text-[#484F61] self-center p-0" />
        <p className="self-center pr-2">Delete</p>
      </div>
    </Button>
  );
}

export default BtnDelete;
