import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store/store'

interface UserState {
  token: string | null;
  refreshToken: string | null;
  user: UserInterface | null;
}

interface UserInterface {
  name: string;
  email: string;
  idProvider: string;
  passwordSalt: string;
  passwordHash: string;
  lastLoginDate: string;
  modifiedBy: number;
  modifiedOn: string;
  id: number;
  isDeleted: boolean;
}

interface AuthResponseInterface {
  token: string;
  refreshToken: string;
  user: UserInterface;
}

const initialState: UserState = {
  token: null,
  refreshToken: null,
  user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AuthResponseInterface>) => {
      localStorage.setItem("atToken", action.payload.token);
      localStorage.setItem("rtToken", action.payload.refreshToken);
      localStorage.setItem("user", JSON.stringify(action.payload.user));

      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.user = action.payload.user;
    },
    clearUser: (state) => {
      localStorage.clear();

      state.token = null;
      state.refreshToken = null;
      state.user = null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
