import * as React from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { IShapeCodes } from "../../../interfaces/shapes.interface";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";

export const DImensionTextboxCell = (
  props: GridCellProps & {
    shapeCodes: IShapeCodes[];
  }
) => {
  const handleChange = (e: TextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: props.dataItem.idx,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value,
      });
    }
  };

  const { dataItem, field } = props;
  var value = dataItem[field!];

  const shapeCodeObj = props.shapeCodes?.find(
    (shape: IShapeCodes) => shape.code === dataItem.shapeCode
  );

  var editable = true;

  if (shapeCodeObj) {
    editable = shapeCodeObj[field as keyof IShapeCodes] as boolean;
  }

  return (
    <td>
      {dataItem.inEdit && editable ? (
        <TextBox
          style={{
            width: 65,
            height: 30,
            padding: "0px 10px",
            fontSize: "10px",
          }}
          onChange={handleChange}
          value={value}
        />
      ) : value == null ? (
        ""
      ) : (
        value
      )}
    </td>
  );
};
