import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ShowColumnsFilter } from "../../component/global/ColumnMenu";
import { IStandard } from "../../interfaces/standard.interface";
import { WeightCell } from "../../shared-components/custom-cells";
import { MIN_RESIZABLE_WIDTH_KENDO_TABLE } from "../../utils/utils";
import { BarsizeDropdownCell } from "./components/BarsizeDropdownCell";
import { IShapeCodes } from "../../interfaces/shapes.interface";

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
  editor?: "text" | "numeric" | "date" | "boolean" | undefined;
  editable?: boolean;
}

export const generateColumns = (
  onColumnsSubmit: any,
  standard: IStandard | null,
  columnsExternal?: any,
  columnsMapping?: any,
  changed?: boolean
): any => {
  const isVisible = (field: string) => {
    if (!columnsMapping?.length) return false;
    if (columnsMapping.includes(field)) {
      return true;
    }

    return false;
  };

  const columns = [
    {
      title: "Bar \r\n Mark",
      field: "mark",
      show: true,
      width: 75,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      editor: "text",
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "Bar \r\nType",
      field: "type",
      show: true,
      width: 75,
      // width: 65,
      editor: "text",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      cells: {},
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "Bar \r\nSize",
      field: "size",
      show: true,
      // width: 65,
      cells: {
        data: (props: any) => (
          <BarsizeDropdownCell {...props} barSizes={standard?.barSizes} />
        ),
      },
      // editor: "text",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["size"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "Per \r\nMember",
      field: "noInEach",
      show: true,
      // width: 75,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "Length",
      field: "length",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: false,
    },
    // {
    //   title: "No. Of Members",
    //   field: "noOfMem",
    //   show: true,
    //   // width: 65,
    //   editor: "numeric",
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       disabledColumns={["type"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    //   editable: false,
    // },
    {
      title: "Shape Code",
      field: "shapeCode",
      show: true,
      // width: 65,
      editor: "text",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "A",
      field: "dimA",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "B",
      field: "dimB",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "C",
      field: "dimC",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "D",
      field: "dimD",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "E",
      field: "dimE",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "F",
      field: "dimF",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "G",
      field: "dimG",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "H",
      field: "dimH",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "K",
      field: "dimK",
      editor: "numeric",
      show: true,
      width: 65,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "J",
      field: "dimJ",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "R",
      field: "dimR",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "I",
      field: "dimI",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "H2",
      field: "dimH2",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "O",
      field: "dimO",
      show: true,
      width: 65,
      editor: "numeric",
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: true,
    },
    {
      title: "Weight (KG)",
      field: "weight",
      show: true,
      format: "{0,0.000}",
      width: 100,
      editor: "numeric",
      cells: {
        data: (props: any) => <WeightCell {...props} />,
      },
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          disabledColumns={["type"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      editable: false,
    },
  ];

  return columns;
};
