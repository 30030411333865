import { ShowColumnsFilter } from "../../../component/global/ColumnMenu";
import { WeightCell } from "../../../shared-components/custom-cells";
import { MIN_RESIZABLE_WIDTH_KENDO_TABLE } from "../../../utils/utils";

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}

export const generateColumns = (
  onColumnsSubmit: any,
  columnsExternal?: any,
  columnsMapping?: any,
  changed?: boolean
): any => {
  const isVisible = (field: string) => {
    if (!columnsMapping?.length) return false;
    if (columnsMapping.includes(field)) {
      return true;
    }

    return false;
  };
  const columns = [
    {
      title: "Member Name",
      field: "name",
      show: true,
      // width: 200,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["name"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    // {
    //   title: "Bar Mark",
    //   field: "mark",
    //   show: changed ? isVisible("name") : true,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "Bar Type",
    //   field: "type",
    //   show: changed ? isVisible("type") : true,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "Per Member",
    //   field: "noInEach",
    //   show: changed ? isVisible("noInEach") : true,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    {
      title: "No. of Members",
      field: "noOfMembers",
      show: changed ? isVisible("noOfMembers") : true,
      width: 150,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["noOfMembers"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    // {
    //   title: "No. Of Members",
    //   field: "noOfMem",
    //   show: changed ? isVisible("noOfMem") : true,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "Shape Code",
    //   field: "shapeCode",
    //   show: changed ? isVisible("shapeCode") : true,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "A(mm)",
    //   field: "dimA",
    //   show: changed ? isVisible("dimA") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "B(mm)",
    //   field: "dimB",
    //   show: changed ? isVisible("dimB") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "C(mm)",
    //   field: "dimC",
    //   show: changed ? isVisible("dimC") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "D(mm)",
    //   field: "dimD",
    //   show: changed ? isVisible("dimD") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "E(mm)",
    //   field: "dimE",
    //   show: changed ? isVisible("dimE") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "F(mm)",
    //   field: "dimF",
    //   show: changed ? isVisible("dimF") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "G(mm)",
    //   field: "dimG",
    //   show: changed ? isVisible("dimG") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "H(mm)",
    //   field: "dimH",
    //   show: changed ? isVisible("dimH") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "K(mm)",
    //   field: "dimK",
    //   show: changed ? isVisible("dimK") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "J(mm)",
    //   field: "dimJ",
    //   show: changed ? isVisible("dimJ") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "R(mm)",
    //   field: "dimR",
    //   show: changed ? isVisible("dimR") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "I(mm)",
    //   field: "dimI",
    //   show: changed ? isVisible("dimI") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "H2(mm)",
    //   field: "dimH2",
    //   show: changed ? isVisible("dimH2") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    // {
    //   title: "O(mm)",
    //   field: "dimO",
    //   show: changed ? isVisible("dimO") : false,
    //   width: 150,
    //   columnMenu: (props: any) => (
    //     <ShowColumnsFilter
    //       {...props}
    //       columns={columns || columnsExternal}
    //       onColumnsSubmit={onColumnsSubmit}
    //       showColumnMenu={true}
    //       disabledColumns={["name"]}
    //     />
    //   ),
    //   minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    // },
    {
      title: "Weight (KG)",
      field: "weight",
      show: changed ? isVisible("weight") : true,
      width: 150,
      cells: {
        data: (props: any) => <WeightCell {...props} />,
      },
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["name"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
  ];

  return columns;
};
