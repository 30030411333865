import { State, process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  GridColumn as Column,
  Grid,
  GridDataStateChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import {
  NumericTextBoxChangeEvent,
  TextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { Plus, X } from "lucide-react";
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ShowColumnsFilter } from "../../../component/global/ColumnMenu";
import Export from "../../../component/global/Export";
import CustomLabel from "../../../component/global/RequiredMark";
import BBSNumberField from "../../../component/inputs/BBSNumberField";
import BBSTextField from "../../../component/inputs/BBSTextField";
import PageTitle from "../../../component/ui/PageTitle";
import SearchBox from "../../../component/ui/SearchBox";
import Filters from "../../../constants/filters";
import IPageState from "../../../interfaces/page.interface";
import { IBar, IStandard } from "../../../interfaces/standard.interface";
import { ActionCellPopupSettings } from "../../../shared-components/custom-cells";
import { setToast } from "../../../store/features/toastSlice";
import {
  MIN_RESIZABLE_WIDTH_KENDO_TABLE,
  getPaginationData,
} from "../../../utils/utils";

const Pagination = getPaginationData();
let mappingChanged = false;

interface IBarWithIds extends IBar {
  idx: number;
}
interface IProps {
  standards: IStandard[];
  bars: IBarWithIds[];
  total: number;
  handleChange: (
    e: TextBoxChangeEvent | DatePickerChangeEvent | NumericTextBoxChangeEvent
  ) => void;
  handleValidation: (name: string, isValid: boolean) => void;
  actionFor: string;
  handleCreate: any;
  handleEdit: any;
  handleDelete: any;
  setBars: any;
  action: string;
}

function Bars({
  standards,
  setBars,
  bars,
  total,
  handleChange,
  handleValidation,
  actionFor,
  handleCreate,
  handleDelete,
  handleEdit,
  action,
}: IProps) {
  const _exporter = createRef<ExcelExport>();
  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current?.save();
    }
  };

  const initialRender = useRef(false);
  const dispatch = useDispatch();

  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [oldBars, setOldBars] = useState<IBar[]>([]);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >();
  const [page, setPage] = useState<IPageState>(Pagination.initialDataState);
  const [searchValue, setSearchValue] = useState("");
  const [columnsMapping, setColumnsMapping] = useState<any>(null);
  const [filterValues, setAllFilterValues] = useState<any>(null);
  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(
      action,
      handleEditClick,
      deleteBar,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    )
  );

  const [addNewBar, setAddNewBar] = useState<{
    typeCode: string;
    size: number;
    r: number;
    n: number;
    h: number;
    standardId: string;
  }>();
  const [editBarValue, setEditBarValue] = useState<
    IBar & { idx: number; standardId: string; standardName: string }
  >();

  const createDataState = (dataState: State, data: any) => {
    let createdState: any = {
      result: [],
      dataState: dataState,
    };
    if (data?.data) {
      const temp = data.data;
      createdState = {
        result: process(temp.slice(0), dataState),
        dataState: dataState,
      };
    } else if (data) {
      createdState = {
        result: process(data?.slice(0), dataState),
        dataState: dataState,
      };
    }
    return createdState;
  };

  let initialState = createDataState(
    {
      take: 20,
      skip: 0,
    },
    bars
  );

  const [dataState, setDataState] = useState<State>(initialState.dataState);

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      action,
      handleEditClick,
      deleteBar,
      onColumnsSubmit,
      columnsState,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }

  function handleEditClick(e: any) {
    setShowEditDrawer(true);
    setEditBarValue(e);
  }

  const handleValueChange = (e: any) => {
    setAddNewBar((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.value,
      };
    });
  };

  const handleEditChange = (e: any) => {
    setEditBarValue((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.value,
      };
    });
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    if (event.dataState.filter === undefined) {
      createDataState(
        {
          take: 20,
          skip: 0,
        },
        bars
      );
      setBars(oldBars);
      setDataState(initialState.dataState);
      return;
    }
    let updatedState = createDataState(event.dataState, bars);
    setBars(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length) {
      const filteredData = oldBars.filter((bars) =>
        bars.typeCode.toLowerCase().includes(value.toLowerCase())
      );
      setBars(filteredData);
    }
    if (!value) {
      setBars(oldBars);
    }
  };

  const createBar = () => {
    if (addNewBar) {
      handleCreate(addNewBar, "bars");
      setShowCreateDrawer(false);
    }
  };

  const editBar = () => {
    // for (let bar of bars) {
    //   // if (bar.typeCode === editBarValue?.typeCode) {
    //   //   if (bar.idx !== editBarValue?.idx) {
    //   //     dispatch(
    //   //       setToast({
    //   //         toastType: "error",
    //   //         toastMsg: "Code must be unique",
    //   //       })
    //   //     );
    //   //     return;
    //   //   }
    //   // }
    // }
    handleEdit(editBarValue, "bars");
    setShowEditDrawer(false);
  };

  function deleteBar(e: any) {
    handleDelete(e, "bars");
  }

  const getDropdownValue = () => {
    const standard = standards?.find(
      (std) => std.id === editBarValue?.standardId
    );
    if (!standard) return;
    return standard.name;
  };

  useEffect(() => {
    if (!bars?.length || !columnsMapping) return;
    const cols = generateColumns(
      action,
      handleEditClick,
      deleteBar,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }, [filterValues, bars, columnsMapping]);

  useEffect(() => {
    if (bars?.length && initialRender.current === false) {
      setOldBars(bars);
      initialRender.current = true;
    }
  }, [bars]);

  return (
    <>
      <div className="setting-page">
        <Grid
          style={{ maxHeight: "" }}
          data={bars}
          {...dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          pageable={true}
          pageSize={20}
          total={total}
          className={`customHeight employee`}
          resizable={Filters.resizeable}
        >
          <GridToolbar>
            <div className="flex gap-3 w-full items-center">
              <div className="w-[250px]">
                <SearchBox
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search"
                />
              </div>
              {action !== "read" && (
                <Button
                  themeColor={"primary"}
                  className="border border-[#E2E8F0]"
                  onClick={() => setShowCreateDrawer(true)}
                >
                  <span className="inline-flex items-center gap-2">
                    <Plus className="w-4 h-4" /> Add New
                  </span>
                </Button>
              )}
              <div
                className="cursor-pointer  sm:ml-0 ml-auto sm:flex-initial flex-1"
                onClick={excelExport}
              >
                <Export />
                <ExcelExport
                  data={oldBars}
                  collapsible={true}
                  fileName="Bars.xlsx"
                  ref={_exporter}
                >
                  {stateColumns &&
                    stateColumns.length > 0 &&
                    stateColumns.map((column: any, idx: number) => {
                      if (column.show) {
                        return (
                          <ExcelExportColumn
                            key={idx}
                            field={column.field}
                            title={column.title}
                          />
                        );
                      }
                    })}
                </ExcelExport>
              </div>
            </div>
          </GridToolbar>
          {stateColumns &&
            stateColumns.length > 0 &&
            stateColumns.map((column: any, idx: number) => {
              if (column.show) {
                if (column.cells) {
                  return (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      columnMenu={column.columnMenu}
                      minResizableWidth={200}
                      // width={200}
                      cells={column.cells}
                      filter={column.filter ? column.filter : "text"}
                    />
                  );
                }
                return (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    columnMenu={column.columnMenu}
                    minResizableWidth={200}
                    // width={200}
                    filter={column.filter ? column.filter : "text"}
                  />
                );
              }
            })}
        </Grid>
      </div>
      {/*Create Drawer*/}
      <Drawer
        expanded={showCreateDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500"
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() => setShowCreateDrawer(false)}
          ></div>
          <div
            className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showCreateDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Add New Bar</h2>
              <Button
                fillMode="flat"
                className="border border-[#E2E8F0]"
                onClick={() => setShowCreateDrawer(false)}
              >
                <X className="w-4 h-4" />{" "}
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div>
                  <BBSNumberField
                    name="size"
                    value={addNewBar?.size}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="Size"
                    required={false}
                    placeholder="Enter Size"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="r"
                    value={addNewBar?.r}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="R"
                    required={false}
                    placeholder="Enter R"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="h"
                    value={addNewBar?.h}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="H"
                    required={false}
                    placeholder="Enter H"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="n"
                    value={addNewBar?.n}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="N"
                    required={false}
                    placeholder="Enter N"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSTextField
                    name="typeCode"
                    value={addNewBar?.typeCode}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="Type Code"
                    required={false}
                    placeholder="Enter Type Code"
                    disabled={false}
                    error="Invalid Type Code"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                onClick={() => setShowCreateDrawer(false)}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                themeColor={"primary"}
                onClick={createBar}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
      {/*Edit Drawer*/}
      <Drawer
        expanded={showEditDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500"
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() => setShowEditDrawer(false)}
          ></div>
          <div
            className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showEditDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Edit Bar</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() => setShowEditDrawer(false)}
              >
                <X className="w-4 h-4" />{" "}
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div>
                  <BBSNumberField
                    name="size"
                    value={editBarValue?.size}
                    onChange={handleEditChange}
                    onValidate={handleValidation}
                    label="Size"
                    required={false}
                    placeholder="Enter Size"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="h"
                    value={editBarValue?.h}
                    onChange={handleEditChange}
                    onValidate={handleValidation}
                    label="H"
                    required={false}
                    placeholder="Enter H"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="n"
                    value={editBarValue?.n}
                    onChange={handleEditChange}
                    onValidate={handleValidation}
                    label="N"
                    required={false}
                    placeholder="Enter N"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSTextField
                    name="typeCode"
                    value={editBarValue?.typeCode}
                    onChange={handleEditChange}
                    onValidate={handleValidation}
                    label="Type Code"
                    required={false}
                    placeholder="Enter Type Code"
                    disabled={false}
                    error="Invalid Type Code"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                onClick={() => setShowEditDrawer(false)}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                themeColor={"primary"}
                onClick={editBar}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Bars;

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}

export const generateColumns = (
  action: string,
  handleEditClick: any,
  deleteBar: any,
  onColumnsSubmit: any,
  columnsExternal?: any,
  columnsMapping?: any,
  changed?: boolean
): any => {
  const isVisible = (field: string) => {
    if (!columnsMapping?.length) return false;
    if (columnsMapping.includes(field)) {
      return true;
    }
    return false;
  };
  const columns = [
    {
      title: "Type Code",
      field: "typeCode",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["typeCode"]}
        />
      ),
      cells: {
        data: (props: any) => (
          <ActionCellPopupSettings
            rowData={props.dataItem}
            handleEditClick={handleEditClick}
            handleDeleteClick={deleteBar}
            dataKey="typeCode"
            action={action}
          />
        ),
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Size",
      field: "size",
      show: changed ? isVisible("size") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["typeCode"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "N",
      field: "n",
      show: changed ? isVisible("n") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["typeCode"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "H",
      field: "h",
      show: changed ? isVisible("h") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["typeCode"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
  ];

  return columns;
};
