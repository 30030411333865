import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store/store';

interface SettingsState {
  settings: any;
}

const initialState: SettingsState = {
  settings: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    }
  },
});

export const { setSettings } = settingsSlice.actions;
export const selectCommon = (state: RootState) => state.settings;
export default settingsSlice.reducer;
