import { hide, show } from "../store/features/commonSlice";
import { store } from "../store/store/store";

class LoaderService {
  static showLoader() {
    store.dispatch(show());
  }

  static hideLoader() {
    store.dispatch(hide());
  }
}

export default LoaderService;
