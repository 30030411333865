import { ILoginCredentials } from '../interfaces/auth.interface';
import { auth } from '../lib';
import { setToast } from '../store/features/toastSlice';
import { setUser } from '../store/features/userSlice';
import { store } from '../store/store/store';
import LoaderService from './loader.service';

class AuthService {
  async refreshToken() {
    try {
      LoaderService.showLoader();
      const token = localStorage.getItem('rtToken');
      const res: any = await auth.post(
        `${process.env.REACT_APP_BASE_URL}/api/Auth/refresh`,
        {
          RefreshToken: token,
        }
      );

      if (res.ok && res.data) {
        store.dispatch(setUser(res.data));
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async login(dataItem: { [name: string]: ILoginCredentials }) {
    try {
      LoaderService.showLoader();
      const res = await auth.post(
        `${process.env.REACT_APP_BASE_URL}/api/Auth/login`,
        {
          email: dataItem.email,
          password: dataItem.password,
        }
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async forgotPassword(email: string) {
    try {
      LoaderService.showLoader();
      const res = await auth.post(
        `${process.env.REACT_APP_BASE_URL}/api/Auth/forgot-password`,
        {
          email: email,
        }
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async resetPassword(token: string, newPassword: string) {
    try {
      LoaderService.showLoader();
      const res = await auth.put(
        `${process.env.REACT_APP_BASE_URL}/api/Auth/reset-password`,
        {
          token,
          newPassword,
        }
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async verifyToken(token: string) {
    try {
      LoaderService.showLoader();
      const res = await auth.post(
        `${process.env.REACT_APP_BASE_URL}/api/Auth/token`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
  async createUser(payload: any) {
    try {
      LoaderService.showLoader();
      const res = await auth.post(
        `${process.env.REACT_APP_BASE_URL}/api/Auth/register`,
        payload
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg: res.data as string,
        })
      );
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const authService = new AuthService();

export default authService;
