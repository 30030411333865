import { BaseService } from './base.service';

class ObjectTypeService extends BaseService {
  constructor() {
    super('ObjectType');
  }
}

const objectTypeSvc = new ObjectTypeService();

export default objectTypeSvc;
