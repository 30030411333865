import { DebouncedFunc } from "lodash";
import { ShowColumnsFilter } from "../../../component/global/ColumnMenu";
import { IBBS } from "../../../interfaces/bbs.interface";
import {
  BBSActionCellPopup,
  DateCell,
  TotalWeightCell,
} from "../../../shared-components/custom-cells";
import { MIN_RESIZABLE_WIDTH_KENDO_TABLE } from "../../../utils/utils";

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}

export const generateColumns = (
  handleEditClick: any,
  handleImportBBS: any,
  handleExport: any,
  onColumnsSubmit: any,
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>,
  handleDelete: () => void,
  columnsExternal?: any,
  columnsMapping?: any,
  changed?: boolean
): any => {
  const isVisible = (field: string) => {
    if (!columnsMapping?.length) return false;
    if (columnsMapping.includes(field)) {
      return true;
    }

    return false;
  };
  const columns = [
    {
      title: "BBS Number",
      field: "no",
      show: true,
      width: 200,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      cells: {
        data: (props: any) => (
          <BBSActionCellPopup
            {...props}
            handleEditClick={handleEditClick}
            handleImport={handleImportBBS}
            handleExport={handleExport}
            handleDelete={handleDelete}
          />
        ),
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Level",
      field: "levelName",
      show: changed ? isVisible("levelName") : true,
      width: 150,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["levelName"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Sub Level",
      field: "subLevelName",
      show: changed ? isVisible("subLevelName") : true,
      width: 150,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["subLevelName"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "BBS Name",
      field: "name",
      width: 200,
      show: changed ? isVisible("name") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "BBS Revision",
      field: "revNo",
      show: changed ? isVisible("revNo") : true,
      width: 100,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Created On",
      field: "createdOn",
      show: changed ? isVisible("createdOn") : true,
      width: 175,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => <DateCell {...props} />,
      },
    },
    {
      title: "Shop Drawing No.",
      field: "shopDrawNo",
      show: changed ? isVisible("shopDrawNo") : true,
      width: 175,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Shop Drawing Rev No.",
      field: "shopDrawRevNo",
      show: changed ? isVisible("shopDrawRevNo") : true,
      width: 150,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Status",
      field: "status",
      show: changed ? isVisible("status") : true,
      width: 100,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Weight (Kg)",
      field: "wbs",
      show: changed ? isVisible("wbs") : true,
      width: 110,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => <TotalWeightCell {...props} />,
      },
    },
    {
      title: "Prepared By",
      field: "preparedBy",
      show: changed ? isVisible("preparedBy") : false,
      width: 150,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Prepared On",
      field: "preparedOn",
      show: changed ? isVisible("preparedOn") : false,
      //width: 250,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => <DateCell {...props} />,
      },
    },
    {
      title: "Checked By",
      field: "checkedBy",
      show: changed ? isVisible("checkedBy") : false,
      //width: 200,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Checked On",
      field: "checkedOn",
      show: changed ? isVisible("checkedOn") : false,
      width: 250,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => <DateCell {...props} />,
      },
    },
    {
      title: "Detailing Office",
      field: "detailingOff",
      show: changed ? isVisible("detailingOff") : true,
      width: 125,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Object Type",
      field: "objectType",
      show: changed ? isVisible("objectType") : true,
      width: 125,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
  ];

  return columns;
};
