import { ReactNode, useEffect, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import TopBar from "../topbar/TopBar";

const DashboardMain = ({ children }: { children: ReactNode }) => {
  const location = window.location.pathname;
  const [selected, setSelected] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("show-menu");
    return storedValue ? storedValue === "true" : false;
  });
  const [currentRoute, setCurrentRoute] = useState("");
  const toggleSelected = () => {
    setSelected(!selected);
  };

  useEffect(() => {
    if (selected) {
      document.body.classList.add("show-menu");
    } else {
      document.body.classList.remove("show-menu");
    }
    localStorage.setItem("show-menu", String(selected));
  }, [selected]);

  useEffect(() => {
    setCurrentRoute(location);
  }, [location]);

  return (
    <div className="h-screen bg-[#FAFDFF] flex flex-col  w-full">
      {currentRoute !== "/home" && (
        <Sidebar
          selected={selected}
          setSelected={setSelected}
          toggleSelected={toggleSelected}
        />
      )}
      <div
        className={`flex flex-col flex-1 transition-all duration-300 min-h-full ${
          currentRoute !== "/home"
            ? selected
              ? "lg:pl-[54px_!important]"
              : "lg:pl-[280px_!important]"
            : ""
        }`}
      >
        <div className="flex-1 flex flex-col min-h-screen">
          <TopBar />
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardMain;
