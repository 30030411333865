import React from 'react'
import OnBoardingContainer from '../../component/ui/container/OnBoardingContainer'
import LoginForm from '../../component/onboarding/LoginForm'

const LoginHome = () => {
  return (

    <OnBoardingContainer>
      <LoginForm />
    </OnBoardingContainer>
  )
}

export default LoginHome