import { State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  getSelectedState,
  Grid,
  GridColumn,
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import { useCallback, useEffect, useRef, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import SearchBox from "../../../component/ui/SearchBox";
import Filters from "../../../constants/filters";
import { IProject, Level } from "../../../interfaces/project.interface";
import projectSvc from "../../../services/project.service";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import { Pencil, Plus, SquarePen } from "lucide-react";
import BtnAddLevel from "../components/BtnAddLevel";
import BtnRename from "../components/BtnRename";
import { CreatedOnDateCell } from "../../../shared-components/custom-cells";
import { setToast } from "../../../store/features/toastSlice";
import { TreeViewDataItem } from "..";

const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);

function FirstLevelListing({
  treeView,
  setTreeView,
  projects,
  fetchProjects,
}: {
  treeView: TreeViewDataItem[];
  setTreeView: React.Dispatch<React.SetStateAction<TreeViewDataItem[]>>;
  projects: IProject[];
  fetchProjects: any;
}) {
  const commonState = useAppSelector((state: RootState) => state.common);
  const oldData = useRef<any>([]);
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [action, setAction] = useState("Create");
  const [inputVal, setInputVal] = useState<string>("");
  const [showDialog, setShowDialog] = useState(false);
  const [gridData, setGridData] = useState<any>([]);
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});
  const [dataState, setDataState] = useState<State>({
    skip: 0,
    take: 10,
  });

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      if (
        newSelectedState &&
        selectedState &&
        JSON.stringify(newSelectedState) === JSON.stringify(selectedState)
      ) {
        setSelectedState({});
        return;
      }
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: HTMLInputElement = event.syntheticEvent
        .target as HTMLInputElement;
      const checked = checkboxElement.checked;
      const newSelectedState: { [id: string]: boolean | number[] } = {};
      gridData?.forEach((item: any) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length !== 1) {
      const filteredData = gridData.filter((data: any) =>
        data.text.toLowerCase().includes(value.toLowerCase())
      );
      setGridData(filteredData);
    }

    if (value === "") {
      setGridData(oldData.current);
    }
  };

  useEffect(() => {
    loadGridData();
  }, [commonState.selectedProject]);

  const loadGridData = () => {
    if (commonState?.selectedProject?.event?.item) {
      const currLevelItems = commonState?.selectedProject?.event?.item?.items;

      const listData = currLevelItems?.map((x: any, index: number) => {
        let numOfMembers = 0;
        for (let i of x.items) {
          if (i.items?.length) {
            numOfMembers += i.items.length;
          }
        }
        return {
          id: index,
          text: x.text?.split("-Code-")[0],
          numOfLevels: x.items?.length ? 1 : 0,
          numOfMembers: numOfMembers,
          createdOn: x.createdOn,
        };
      });
      oldData.current = JSON.parse(JSON.stringify(listData));
      setGridData(listData);
    }
  };

  const handleSave = () => {
    if (action === "Create") {
      handleAddNewLevel();
    } else {
      handleEditLevel();
    }
  };

  const handleAddNewLevel = async () => {
    if (commonState.selectedProject?.event?.item) {
      const proj = projects.find(
        (x) => x.id === commonState.selectedProject?.event?.item?.projectId
      );
      if (proj) {
        const newLevel: Level = {
          name: inputVal,
          subLevels: [],
          code: crypto.randomUUID(),
        };
        proj?.levels.push(newLevel);
        await projectSvc
          .update(commonState.selectedProject?.event?.item?.projectId, proj)
          .then((res) => {
            if (!res.error) {
              dispatch(
                setToast({
                  toastType: "success",
                  toastMsg: "Level added successfully",
                })
              );
            }
            fetchProjects();
            setShowDialog(false);
            setInputVal("");
            loadGridData();
          });
      }
    }
  };

  const handleEditLevel = async () => {
    const proj = projects.find(
      (p) => p.id === commonState?.selectedProject?.event?.item?.projectId
    );
    if (proj) {
      proj.name = inputVal;
      await projectSvc.update(proj?.id, proj).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Level updated successfully",
            })
          );
        }
        fetchProjects();
      });
    }
    setShowDialog(false);
    setInputVal("");
    loadGridData();
  };

  return (
    <>
      <Grid
        style={{ width: "100%", maxWidth: "100vw" }}
        data={gridData?.map((item: any) => ({
          ...item,
          [SELECTED_FIELD]: selectedState[idGetter(item)],
        }))}
        // {...dataState}
        // onDataStateChange={dataStateChange}
        // skip={page.skip}
        // take={page.take}
        // total={total}
        selectedField={SELECTED_FIELD}
        // pageable={{
        //   ...Pagination.pageSizeValue,
        //   pageSizeValue: pageSizeValue,
        // }}
        // onPageChange={pageChange}
        className={`customHeight employee`}
        resizable={Filters.resizeable}
        selectable={{
          enabled: true,
          drag: true,
          mode: "single",
        }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
      >
        <GridToolbar>
          <div className="flex gap-3 w-full items-center justify-between">
            <div className="flex gap-x-4 w-full">
              <div className="w-[250px]">
                <SearchBox
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search"
                />
              </div>
              <BtnAddLevel onClickHandler={() => setShowDialog(true)} />
              {/* <BtnRename
                onClickHandler={() => {
                  setAction("Edit");
                  setShowDialog(true);
                  setInputVal(commonState?.selectedProject?.event?.item?.text?.split("-Code-")[0]);
                }}
              /> */}
            </div>
          </div>
        </GridToolbar>
        {/* <GridColumn
          field={SELECTED_FIELD}
          width="50px"
          headerSelectionValue={
            gridData?.findIndex(
              (item: any) => !selectedState[idGetter(item)]
            ) === -1
          }
        /> */}
        <GridColumn
          field={"text"}
          title="Buildings"
          minResizableWidth={350}
          width={250}
        />
        <GridColumn
          field={"createdOn"}
          title="Created On"
          minResizableWidth={350}
          cell={CreatedOnDateCell}
        />
        <GridColumn
          field={"numOfLevels"}
          title="Total No. Of Levels"
          minResizableWidth={350}
        />
        <GridColumn
          field={"numOfMembers"}
          title="Total No. Of Members"
          minResizableWidth={350}
        />
      </Grid>
      {showDialog && (
        <Dialog
          width={350}
          title={"Add Building"}
          onClose={() => setShowDialog(false)}
          className="custom-dialog"
        >
          <div className="my-4">
            <CustomLabel label="Building" required={true} />
            <Input
              type="text"
              placeholder={"Enter Name"}
              value={inputVal}
              onChange={(e: any) => {
                setInputVal(e.target.value);
              }}
            />
          </div>
          <DialogActionsBar>
            <div className="flex justify-end gap-3">
              <Button
                themeColor={"primary"}
                disabled={!inputVal}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                onClick={() => setShowDialog(false)}
                themeColor={"base"}
                fillMode="outline"
              >
                Cancel
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}

export default FirstLevelListing;
