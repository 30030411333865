import { TableProperties } from "lucide-react";
import DashboardMain from "../../component/layout/main/DashboardMain";
import PageTitle from "../../component/ui/PageTitle";
import { Listing } from "./Listing";

const ObjectTypes = () => {
  return (
    <DashboardMain>
      <div className="LookUpTabs">
        <div className="content-lookups xl:p-5 p-3 overflow-hidden">
          <>
            <div>
              <div className="card mb-5 p-3">
                <div className="inline-flex items-center gap-2">
                  <PageTitle icon={TableProperties} pageTitle="Object Types" />
                </div>
              </div>
              <Listing />
            </div>
          </>
        </div>
      </div>
    </DashboardMain>
  );
};

export default ObjectTypes;
