import { api } from '../lib';
import { BaseService } from './base.service';
import LoaderService from './loader.service';

class ShapesService extends BaseService {
  constructor() {
    super('Shapes');
  }

  async getByStandardId(standardId: string) {
    try {
      LoaderService.showLoader();
      const res = await api.get(`/Shapes/by-standard/${standardId}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const shapesSvc = new ShapesService();

export default shapesSvc;
