import { Label } from "@progress/kendo-react-labels";

function CustomLabel({
  label,
  isValid,
  required,
  flexDir
}: {
  label: string;
  isValid?: boolean;
  required: boolean;
  flexDir?: string;
}) {
  return (
    <div className={`flex self-center ${flexDir === "row" ? 'w-1/3' : ''}`}>
      <Label
        className={`text-sm font-cabin block font-medium mb-1 ${
          isValid === false ? "text-[#A80000]" : "text-txtPrimary"
        }`}
      >
        {label}
      </Label>
      {required && <div className="text-red-600 ml-1">*</div>}
    </div>
  );
}

export default CustomLabel;
