import React, { useState } from "react";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import SearchBox from "../../../../component/ui/SearchBox";
import { Plus } from "lucide-react";

interface Product {
  ProductID: string;
  ProductName: string;
  UnitPrice: string;
  UnitsInStock: string;
}

const products: Product[] = [
  {
    ProductID: "Grid Cell",
    ProductName: "Grid Cell",
    UnitPrice: "Grid Cell",
    UnitsInStock: "Grid Cell",
  },
  {
    ProductID: "Grid Cell",
    ProductName: "Grid Cell",
    UnitPrice: "Grid Cell",
    UnitsInStock: "Grid Cell",
  },
  {
    ProductID: "Grid Cell",
    ProductName: "Grid Cell",
    UnitPrice: "Grid Cell",
    UnitsInStock: "Grid Cell",
  },
  {
    ProductID: "Grid Cell",
    ProductName: "Grid Cell",
    UnitPrice: "Grid Cell",
    UnitsInStock: "Grid Cell",
  },
  {
    ProductID: "Grid Cell",
    ProductName: "Grid Cell",
    UnitPrice: "Grid Cell",
    UnitsInStock: "Grid Cell",
  },
  {
    ProductID: "Grid Cell",
    ProductName: "Grid Cell",
    UnitPrice: "Grid Cell",
    UnitsInStock: "Grid Cell",
  },
  {
    ProductID: "Grid Cell",
    ProductName: "Grid Cell",
    UnitPrice: "Grid Cell",
    UnitsInStock: "Grid Cell",
  },
  {
    ProductID: "Grid Cell",
    ProductName: "Grid Cell",
    UnitPrice: "Grid Cell",
    UnitsInStock: "Grid Cell",
  },
];

const ContractorListing = () => {
  const [searchValue, setSearchValue] = useState("");

  const [dataState, setDataState] = useState<State>({
    skip: 0,
    take: 10,
  });

  const data = process(products, dataState);

  const onDataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Grid
      data={data}
      pageable
      {...dataState}
      onDataStateChange={onDataStateChange}
      style={{ height: "750px" }}
    >
      <GridToolbar>
        <div className="flex gap-3 w-full items-center">
          <div className="flex-[0_0_247px]">
            <SearchBox
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search"
            />
          </div>
          <button className="px-[1.25rem] py-[0.25rem] h-[34px] gap-2 bg-primaryMain text-white rounded-sm inline-flex items-center justify-center">
            <span className="inline-flex items-center gap-2">
              <Plus className="w-4 h-4" />
              <span className="inline-flex items-center gap-1">
                <span>Add Subcontractor</span>
              </span>
            </span>
          </button>

          <div className="md:flex-1 flex-[0_0_100%] justify-end"></div>
        </div>
      </GridToolbar>
      <GridColumn field="ProductID" title="Header Cell" />
      <GridColumn field="ProductName" title="Header Cell" />
      <GridColumn field="UnitPrice" title="Header Cell" />
      <GridColumn field="UnitsInStock" title="Header Cell" />
    </Grid>
  );
};

export default ContractorListing;
