import * as React from "react";

import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { useSelector } from "react-redux";
import { RootState, store } from "../../store/store/store";
import { setToast } from "../../store/features/toastSlice";
interface Props {
  toastType?: "none" | "success" | "error" | "warning" | "info" | undefined;
  toastMsg?: string;
}
const Toast: React.FC<Props> = () =>
  // {toastType, toastMsg}
  {
    const [toastState, setToastState] = React.useState(false);
    const position = {
      topRight: { top: 0, right: 0, alignItems: "flex-end" },
      topCenter: { top: 0, left: "50%", transform: "translateX(-50%)" },
    };
    const { toastType, toastMsg } = useSelector(
      (state: RootState) => state.toast
    );
    // Set success to true after 1 second and false after 60 seconds
    React.useEffect(() => {
      if (!toastType) return;
      setToastState(true);
      const successTimeout = setTimeout(() => {
        setToastState(false);
        store.dispatch(
          setToast({
            toastType: undefined,
            toastMsg: "",
          })
        );
      }, 8000);

      return () => clearTimeout(successTimeout);
    }, [toastType]);
    return (
      <div>
        <NotificationGroup style={position.topRight}>
          <Fade>
            {toastState && (
              <Notification
                type={{ style: toastType, icon: true }}
                closable={true}
                onClose={() => setToastState(false)}
              >
                <span>{toastMsg}</span>
              </Notification>
            )}
          </Fade>
        </NotificationGroup>
      </div>
    );
  };

export default Toast;
