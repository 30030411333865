import { BaseService } from './base.service';

class StandardService extends BaseService {
  constructor() {
    super('Standards');
  }
}

const standardSvc = new StandardService();

export default standardSvc;
