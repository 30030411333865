import { Button } from "@progress/kendo-react-buttons";
import { ShowColumnsFilter } from "../../component/global/ColumnMenu";
import { CompanyActionCellPopup } from "../../shared-components/custom-cells";
import { MIN_RESIZABLE_WIDTH_KENDO_TABLE } from "../../utils/utils";
import { Switch } from "@progress/kendo-react-inputs";
import { DownloadCloudIcon, EyeIcon } from "lucide-react";

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}

export const generateColumns = (
  handleDownload: any,
  handlePreview: any,
  handleEnableDisable: any,
  fetchCompanies: any,
  handleEditClick: any,
  onColumnsSubmit: any,
  columnsExternal?: any,
  columnsMapping?: any,
  changed?: boolean
): any => {
  // const isVisible = (field: string) => {
  //   if(!columnsMapping?.length) return false;
  //   if(columnsMapping.includes(field)){
  //     return true;
  //   }

  //   return false;
  // }

  const columns = [
    {
      title: "Name",
      field: "name",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
        />
      ),
      cells: {
        data: (props: any) => (
          <CompanyActionCellPopup
            {...props}
            handleEditClick={handleEditClick}
            handleEnableDisable={handleEnableDisable}
            fetchCompanies={fetchCompanies}
          />
        ),
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Address",
      field: "address",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Logo",
      field: "logoPath",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) =>
          props.dataItem.logo ? (
            <td>
              <div
                className={`relative group transition-all duration-500 flex items-center justify-center flex-col border-neutral-30 bg-neutral-10 cursor-pointer w-[100px]  overflow-hidden 'pointer-events-none'`}
              >
                {/* <Button
                  onClick={() =>
                    handleDownload(props.dataItem.logo, props.dataItem.name)
                  }
                  className="text-primaryMain hover:text-primaryDark focus:text-primaryDark"
                >
                  Download
                </Button> */}

                <img src={props.dataItem.logoPath} width={100} />
                <div className="absolute inset-0 flex items-center justify-center gap-2  bg-black/70 transition-all duration-500 opacity-0 invisible group-hover:opacity-100 group-hover:visible">
                  <Button
                    className="rounded p-2 text-white border border-white"
                    fillMode={"outline"}
                    onClick={() => handlePreview(props.dataItem.logoPath)}
                  >
                    <EyeIcon size={16} className="w-4 h-4" />
                  </Button>
                  <Button
                    fillMode={"outline"}
                    className="rounded p-2 text-white border border-white"
                    onClick={() =>
                      handleDownload(
                        props.dataItem.logoPath,
                        props.dataItem.name
                      )
                    }
                  >
                    <DownloadCloudIcon size={16} className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            </td>
          ) : (
            <td>
              <div className="flex justify-center w-full h-full"></div>
            </td>
          ),
      },
    },
    {
      title: "Active",
      field: "isActive",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => (
          <td>
            <Switch
              checked={props.dataItem.isActive}
              onLabel="Yes"
              offLabel="No"
              name="isActive"
              value={props.dataItem.isActive}
              onChange={(e) => handleEnableDisable(props.dataItem)}
            ></Switch>
          </td>
        ),
      },
    },
  ];

  return columns;
};
