import React from "react";
import DashboardMain from "../../../../component/layout/main/DashboardMain";
import PageTitle from "../../../../component/ui/PageTitle";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Users, X } from "lucide-react";
import { Label } from "@progress/kendo-react-labels";
import { TextBox } from "@progress/kendo-react-inputs";
import CustomLabel from "../../../../component/global/RequiredMark";
import { Button } from "@progress/kendo-react-buttons";

const CreateProject = () => {
  const [tabSelected, setTabSelected] = React.useState<number>(0);
  const handleSelectTab = (e: TabStripSelectEventArguments) => {
    setTabSelected(e.selected);
  };
  return (
    <DashboardMain>
      <div className="content-lookups xl:p-5 p-3 overflow-hidden">
        <div className="card mb-5 p-3">
          {/* <PageTitle pageTitle="Title" /> */}
        </div>
        <TabStrip
          selected={tabSelected}
          onSelect={handleSelectTab}
          className={`customTabs px-0 `}
        >
          <TabStripTab
            title={
              <span className="text-txtPrimary inline-flex items-center gap-2">
                <Users className="w-4 h-4 text-txtPrimary" strokeWidth={1} />
                Overview
              </span>
            }
          >
            <div className=" card p-3">
              <div className="grid lg:grid-cols-3 gap-3">
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab
            title={
              <span className="text-txtPrimary inline-flex items-center gap-2">
                <Users className="w-4 h-4 text-txtPrimary" strokeWidth={1} />
                Subcontractors
              </span>
            }
          >
            <div className=" card p-3">
              <div className="grid lg:grid-cols-3 gap-3">
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab
            title={
              <span className="text-txtPrimary inline-flex items-center gap-2">
                <Users className="w-4 h-4 text-txtPrimary" strokeWidth={1} />
                Standard
              </span>
            }
          >
            <div className=" card p-3">
              <div className="grid lg:grid-cols-3 gap-3">
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab
            title={
              <span className="text-txtPrimary inline-flex items-center gap-2">
                <Users className="w-4 h-4 text-txtPrimary" strokeWidth={1} />
                Object types
              </span>
            }
          >
            <div className=" card p-3">
              <div className="grid lg:grid-cols-3 gap-3">
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label=" Revision No" required={true} />
                  <TextBox placeholder="Placeholder" type="" />
                </div>
                <div>
                  <CustomLabel label="Full Name (ERP)" required={true} />
                  <TextBox name="" placeholder="Placeholder" type="" />
                </div>
              </div>
            </div>
          </TabStripTab>
        </TabStrip>
        <div className="bg-white lg:px-8 px-4 py-6 gap-2 flex items-center justify-end sm:flex-row flex-col">
          <div className="sm:inline-flex flex items-start gap-2 sm:w-auto w-full">
            <Button
              className="sm:w-full w-full"
              themeColor={"secondary"}
              fillMode="outline"
            >
              <span className="inline-flex items-center gap-2">
                <X className="w-4 h-4 sm:inline-block hidden" strokeWidth={1} />
                Cancel
              </span>
            </Button>
            <Button
              className="sm:w-full w-full"
              themeColor={"primary"}
              fillMode="outline"
            >
              <span className="inline-flex items-center gap-2">Next</span>
            </Button>
          </div>
        </div>
      </div>
    </DashboardMain>
  );
};

export default CreateProject;
