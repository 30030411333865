import { SortDescriptor, State, process } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridItemChangeEvent,
  GridRowClickEvent,
  GridSortChangeEvent,
  GridToolbar,
  GridColumn,
} from "@progress/kendo-react-grid";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ILine } from "../../interfaces/bbs.interface";
import { IShapeCodes } from "../../interfaces/shapes.interface";
import { setToast } from "../../store/features/toastSlice";
import { useAppSelector } from "../../store/hooks/hooks";
import { RootState } from "../../store/store/store";
import { columnInterface, generateColumns } from "./MembersListingColumns";
import { Button } from "@progress/kendo-react-buttons";
import { IBar, IBarSize, IStandard } from "../../interfaces/standard.interface";
import { BarsizeDropdownCell } from "./components/BarsizeDropdownCell";
import { ShapeCodeDropdownCell } from "./components/ShapeCodeDropdownCell";
import { DImensionTextboxCell } from "./components/DimensionTextboxCell";

interface IProps {
  gridData: ILine[];
  onChange: (gridLines: ILine[]) => void;
  shapeCodes: IShapeCodes[];
  selectedShape: IShapeCodes | undefined;
  setSelectedShape: React.Dispatch<
    React.SetStateAction<IShapeCodes | undefined>
  >;
  standard: IStandard | null;
  editID: number | null;
  setEditID: (id: number | null) => void;
  handleBtnDisable: () => boolean;
  updateBBS: () => void;
  cancelUpdate: () => void;
  errMsg: string;
  setErrMsg: React.Dispatch<React.SetStateAction<string>>;
}

const DATA_ITEM_KEY: string = "idx";
const SELECTED_FIELD: string = "selected";
let mappingChanged = false;

const dimensions = [
  "dimA",
  "dimB",
  "dimC",
  "dimD",
  "dimE",
  "dimF",
  "dimG",
  "dimH",
  "dimK",
  "dimJ",
  "dimR",
  "dimI",
  "dimH2",
  "dimO",
];

const initialSort: Array<SortDescriptor> = [{ field: "idx", dir: "asc" }];

function MembersListing({
  gridData,
  onChange,
  shapeCodes,
  selectedShape,
  setSelectedShape,
  standard,
  editID,
  setEditID,
  handleBtnDisable,
  updateBBS,
  cancelUpdate,
  errMsg,
  setErrMsg,
}: IProps) {
  const dispatch = useDispatch();

  const commonState = useAppSelector((state: RootState) => state.common);
  const [sort, setSort] = useState(initialSort);
  const gridDataTemp = useRef<ILine[] | null>(null);
  const [lines, setLines] = useState<ILine[]>([]);
  const [dataState, setDataState] = useState<State>({
    take: 20,
    skip: 0,
    sort: initialSort,
  });
  const [columnsMapping, setColumnsMapping] = useState<any>(null);

  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(
      onColumnsSubmit,
      standard,
      null,
      columnsMapping,
      mappingChanged
    )
  );

  const rowClick = (event: GridRowClickEvent) => {
    event.dataItem.inEdit = true;
    setEditID(event.dataItem.idx);
  };

  const calculateValue = (line: ILine, prevLines: ILine[]) => {
    const barsSizes: IBarSize[] = standard?.barSizes!;
    const bars: IBar[] = standard?.bars!;
    const shapeCodeObj = shapeCodes?.find(
      (shape: IShapeCodes) => shape.code === line.shapeCode
    );

    if (!shapeCodeObj) {
      setErrMsg(`ShapeCode ${line.shapeCode} not found`);
      // dispatch(
      //   setToast({
      //     toastType: "error",
      //     toastMsg: `ShapeCode ${line.shapeCode} not found`,
      //   })
      // );
      setLines(prevLines);
      //setEditID(null);
      //return;
    }

    const dimensions = {
      A: line.dimA,
      B: line.dimB,
      C: line.dimC,
      D: line.dimD,
      E: line.dimE,
      F: line.dimF,
      G: line.dimG,
      H: line.dimH,
      J: line.dimJ,
      K: line.dimK,
      O: line.dimO,
      R: line.dimR,
      I: line.dimI,
      H2: line.dimH2,
    };

    const barSizeObj = barsSizes.find((x) => x.size === Number(line.size));
    const bar = bars.find((x) => x.typeCode === line.type);

    if (barSizeObj && bar && shapeCodeObj) {
      const { radius, size, unitWeight } = barSizeObj;
      const { n, h } = bar;
      const { formula, condition } = shapeCodeObj;

      if (condition) {
        let conditionToEvaluate = condition;

        for (const [key, value] of Object.entries(dimensions)) {
          const regex = new RegExp(`\\b${key}\\b`, "g");
          conditionToEvaluate = conditionToEvaluate.replace(
            regex,
            String(value)
          );
        }

        conditionToEvaluate = conditionToEvaluate
          .replace(/r/g, String(radius))
          .replace(/d/g, String(size))
          .replace(/n/g, String(n))
          .replace(/h/g, String(h));

        try {
          if (conditionToEvaluate && !eval(conditionToEvaluate)) {
            return { ...line, isInvalidBarLength: true };
          }
        } catch (error) {
          setErrMsg(
            `Error evaluating condition for line with shape code ${line.shapeCode}: ${error}`
          );
          // dispatch(
          //   setToast({
          //     toastType: "error",
          //     toastMsg: `Error evaluating condition for line with shape code ${line.shapeCode}: ${error}`,
          //   })
          // );
          setLines(prevLines);
          //setEditID(null);
          //return;
        }
      }

      let length: string | number = formula;
      for (const [key, value] of Object.entries(dimensions)) {
        const regex = new RegExp(`\\b${key}\\b`, "g");
        length = length.replace(regex, String(value));
      }

      length = length
        .replace(/r/g, String(radius))
        .replace(/d/g, String(size))
        .replace(/n/g, String(n))
        .replace(/h/g, String(h));

      try {
        length = eval(length);
      } catch (error) {
        setErrMsg(
          `Error evaluating condition for line with shape code ${line.shapeCode}: ${error}`
        );
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `Error evaluating formula for line with shape code ${line.shapeCode}: ${error}`,
        //   })
        // );
        setLines(prevLines);
        //setEditID(null);
        //return;
      }

      var totalNumbers = line.totalNo;

      if (!unitWeight) {
        setErrMsg(`Unit weight for bar size ${line.size} not found`);
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `Unit weight for bar size ${line.size} not found`,
        //   })
        // );
        //setEditID(null);
        //setLines(prevLines);
        //return;
      }
      if (totalNumbers == 0) {
        totalNumbers = Number(line.noOfMem) * line.noInEach;
      }

      const weight = (unitWeight * totalNumbers * Number(length)) / 1000;
      if (length > commonState.selectedProject.event.item.barLength) {
        setErrMsg(
          `Bar length should not be greater than ${commonState.selectedProject.event.item.barLength}`
        );
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `Bar length should not be greater than ${commonState.selectedProject.event.item.barLength}`,
        //   })
        // );
        setLines(prevLines);
        //setEditID(null);
        //return;
      }
      return {
        ...line,
        length: Number(length),
        weight: weight,
      };
    }
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const inEditID = event.dataItem.idx;
    const field = event.field || "";
    const idx = lines.findIndex((item) => item.idx === inEditID);
    const otherLines = lines.filter((item) => item.idx !== inEditID);

    if (field === "mark") {
      var hasSameMark = otherLines.find((item) => item.mark == event.value);
      if (hasSameMark) {
        setErrMsg(`Mark ${event.value} already exists`);
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `Mark ${event.dataItem.mark} already exists`,
        //   })
        // );
        event.value = event.dataItem.mark;
        // setLines(lines);
        return;
      }
    }

    if (field === "length") {
      if (event.value > commonState.selectedProject.event.item.barLength) {
        setErrMsg(
          `Bar length should not be greater than ${commonState.selectedProject.event.item.barLength}`
        );
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `Bar length should not be greater than ${commonState.selectedProject.event.item.barLength}`,
        //   })
        // );
        //setEditID(null);
        //return;
      }
    }

    if (field === "noOfMem") {
      if (event.value !== 1) {
        setErrMsg(`No. of members should be 1`);
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `No. of members should be 1`,
        //   })
        // );
        //setEditID(null);
        //return;
      }
    }

    if (field === "shapeCode") {
      var shapeCode = shapeCodes.find((item) =>
        item.code.includes(event.value)
      );
      if (event.value > 1 && !shapeCode) {
        setErrMsg(
          `Shape code ${event.value} is not available in the selected standard`
        );
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `Shape code ${event.value} is not available in the selected standard`,
        //   })
        // );
        //setEditID(null);
        //return;
      }

      setSelectedShape(shapeCode);
    }

    if (idx !== -1) {
      const prevLines = JSON.parse(JSON.stringify(lines));
      const prevLine = { ...lines[idx] };

      (lines[idx] as any)[field] = event.value;

      const afterCalculation = calculateValue(lines[idx], prevLines);

      if (afterCalculation) {
        lines[idx] = afterCalculation;
        onChange(lines);
      } else {
        lines[idx] = prevLine;
        onChange(lines);
      }
    }
  };

  useEffect(() => {
    if (!gridData) {
      setLines([]);
      return;
    }

    const cols = generateColumns(
      onColumnsSubmit,
      standard,
      null,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
    setLines(gridData);
  }, [gridData, standard]);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  const paginatedData = () => {
    return process(lines.slice(), dataState);
  };

  useEffect(() => {
    if (gridData?.length) {
      gridDataTemp.current = JSON.parse(JSON.stringify(gridData));
    }
  }, [gridData]);

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      onColumnsSubmit,
      standard,
      null,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }

  useEffect(() => {
    if (errMsg) {
      const timer = setTimeout(() => {
        setErrMsg("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errMsg]);

  return (
    <>
      <div className="mb-5 w-full col-span-2">
        <div className="setting-page">
          <Grid
            style={{ minHeight: "200px", height: "400px" }}
            {...dataState}
            onDataStateChange={dataStateChange}
            pageable={true}
            pageSize={dataState.take}
            total={lines?.length || 0}
            sortable={true}
            sort={sort}
            className="customHeight members-editor"
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: "multiple",
            }}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
              setDataState({ ...dataState, sort: e.sort });
            }}
            editField="inEdit"
            onRowClick={rowClick}
            onItemChange={itemChange}
            // onSelectionChange={onSelectionChange}
            // onHeaderSelectionChange={onHeaderSelectionChange}
            resizable={true}
            data={paginatedData()?.data?.map((item: ILine) => ({
              ...item,
              inEdit: item.idx === editID,
            }))}
            scrollable="scrollable"
          >
            {stateColumns &&
              stateColumns.length > 0 &&
              stateColumns.map((column: any, idx: number) => {
                var dimensionColumn = dimensions.some(
                  (x) => x === column.field
                );

                if (column.show) {
                  if (column.field == "size") {
                    return (
                      <Column
                        key={idx}
                        field="size"
                        title={"Bar \r\n Size"}
                        width={100}
                        cell={(props) => (
                          <BarsizeDropdownCell
                            {...props}
                            barSizes={standard?.barSizes}
                          />
                        )}
                      />
                    );
                  }

                  if (column.field == "shapeCode") {
                    return (
                      <Column
                        key={idx}
                        field="shapeCode"
                        title={"Shape Code"}
                        width={125}
                        cell={(props) => (
                          <ShapeCodeDropdownCell
                            {...props}
                            shapeCodes={shapeCodes}
                          />
                        )}
                      />
                    );
                  }
                  //shapeCodes={shapeCodes}

                  if (dimensionColumn) {
                    return (
                      <Column
                        key={idx}
                        field={column.field}
                        title={column.title}
                        width={125}
                        cell={(props) => (
                          <DImensionTextboxCell
                            {...props}
                            shapeCodes={shapeCodes}
                          />
                        )}
                      />
                    );
                  }

                  if (column.cells) {
                    return (
                      <Column
                        key={idx}
                        field={column.field}
                        title={column.title}
                        // columnMenu={column.columnMenu}
                        // minResizableWidth={200}
                        width={column.width}
                        cells={column.cells}
                        filter={column.filter ? column.filter : "text"}
                        editor={column.editor}
                        editable={column.editable}
                      />
                    );
                  }
                  return (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      // columnMenu={column.columnMenu}
                      // minResizableWidth={200}
                      width={column.width}
                      filter={column.filter ? column.filter : "text"}
                      editor={column.editor}
                      editable={column.editable}
                    />
                  );
                }
              })}
            <GridToolbar>
              <div className="flex gap-3 w-full items-center justify-between">
                <div className="flex gap-x-3 w-full">
                  <Button
                    // disabled={handleBtnDisable()}
                    onClick={cancelUpdate}
                    className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
                  >
                    <span className="inline-flex items-center gap-2">
                      Cancel
                    </span>
                  </Button>
                  <Button
                    themeColor={"primary"}
                    disabled={handleBtnDisable()}
                    onClick={updateBBS}
                    // className="mb-3"
                  >
                    <span className="inline-flex items-center gap-2">Save</span>
                  </Button>
                  {errMsg && (
                    <p className="text-lg text-red-400 text-left w-full font-bold">
                      {errMsg}
                    </p>
                  )}
                </div>
              </div>
            </GridToolbar>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default MembersListing;
