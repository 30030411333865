import { jwtDecode } from 'jwt-decode';
import {
  Database,
  FileAxis3D,
  FileCheck,
  FileClockIcon,
  FileMinus,
  FilePenIcon,
  FilePenLine,
  FileX,
  Files,
  FolderKanban,
  List,
  Parentheses,
  Weight,
} from 'lucide-react';
import DefaultPaginationConfig from '../constants/pagination';
import { IDashboardStats } from '../interfaces/dashboard.interface';
import {
  STATUS_CANCELLED,
  STATUS_COMPLETED,
  STATUS_EXPIRED,
  STATUS_NEW,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_RENEWED,
  STATUS_STARTED,
} from './lease-status';
import { SUPER_USER } from './roles.constant';

const EMAIL_REGEX: RegExp = new RegExp(/\S+@\S+\.\S+/);
export const NAME_REGEX: RegExp = new RegExp(/\d/g);
export const EMAIL_VALIDATOR = (value: string) =>
  EMAIL_REGEX.test(value) ? '' : 'Please enter a valid email.';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm a';
export const MIN_RESIZABLE_WIDTH_KENDO_TABLE = 80;

export const getDashboardStats = (
  dashboardCounterKey: string,
  count: number
): IDashboardStats => {
  const counters: Record<
    string,
    { title: string; icon: any; iconColor: string; filter: string }
  > = {
    newLease: {
      title: 'New',
      icon: FilePenIcon,
      iconColor: '#5FD255',
      filter: STATUS_NEW,
    },
    totalLease: {
      title: 'Total',
      icon: Files,
      iconColor: '#FB8A4F',
      filter: '',
    },
    totalLeaseExpired: {
      title: 'Expired',
      icon: FileX,
      iconColor: '#C64541',
      filter: STATUS_EXPIRED,
    },
    totalPendingLease: {
      title: 'Under Process',
      icon: FileClockIcon,
      iconColor: '#FFC80A',
      filter: STATUS_PENDING,
    },
    totalRenewedLease: {
      title: 'Renewed',
      icon: FileAxis3D,
      iconColor: '#0078D4',
      filter: STATUS_RENEWED,
    },
  };

  const { title, icon, iconColor, filter } =
    counters[dashboardCounterKey] || {};

  return {
    title: title || '',
    icon: icon || null,
    iconColor: iconColor || '',
    count,
    filter: filter || '',
  };
};

export const getVisaDashboardStats = (
  dashboardCounterKey: string,
  count: number
): IDashboardStats => {
  const counters: Record<
    string,
    { title: string; icon: any; iconColor: string; filter: string }
  > = {
    totalNewHires: {
      title: 'Total',
      icon: Files,
      iconColor: '#605E5C',
      filter: '',
    },
    startedNewHires: {
      title: 'Started',
      icon: FilePenLine,
      iconColor: '#03274E',
      filter: STATUS_STARTED,
    },
    completedNewHires: {
      title: 'Completed',
      icon: FileCheck,
      iconColor: '#107C10',
      filter: STATUS_COMPLETED,
    },
    cancelledNewHires: {
      title: 'Cancelled',
      icon: FileX,
      iconColor: '#DEB341',
      filter: STATUS_CANCELLED,
    },
    rejectedNewHires: {
      title: 'Rejected',
      icon: FileMinus,
      iconColor: '#D83B01',
      filter: STATUS_REJECTED,
    },
  };

  const { title, icon, iconColor, filter } =
    counters[dashboardCounterKey] || {};

  return {
    title: title || '',
    icon: icon || null,
    iconColor: iconColor || '',
    count,
    filter: filter || '',
  };
};

export const getInitials = (name: string) => {
  if (!name) return '';

  const words = name.split(' ');

  const initials = words.map((word) => word.charAt(0).toUpperCase()).join('');

  return initials.slice(0, 2);
};

export const isAuthorize = (requiredRoles: string[] | undefined) => {
  if (!requiredRoles || !requiredRoles.length) return true;

  const user = localStorage.getItem('atToken');

  if (!user) return false;

  const userData: any = jwtDecode(user as string);
  if (userData && userData.roles) {
    if (typeof userData.roles === 'string') {
      if (userData.roles === SUPER_USER) return true;
      const idx = requiredRoles.findIndex(
        (role: string) => role === userData.roles
      );
      if (idx !== -1) {
        return true;
      } else {
        return false;
      }
    }

    if (typeof userData.roles === 'object') {
      if (userData.roles.includes(SUPER_USER)) return true;
      for (let role of userData.roles) {
        const idx = requiredRoles.findIndex(
          (reqRole: string) => reqRole === role
        );
        if (idx !== -1) {
          return true;
        }
      }
      return false;
    }
  }
};

export const formatDate = (date: Date | string): string => {
  const dateObject = typeof date === 'string' ? new Date(date) : date;
  if (isNaN(dateObject.getTime())) {
    throw new Error('Invalid date');
  }
  const year = dateObject.getFullYear();
  const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
  const day = ('0' + dateObject.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

export const getDaysInMonth = (date: Date): number => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero indexed
  return new Date(year, month, 0).getDate();
};

export const getDaysLeftInMonth = (date: Date): number => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
  const currentDay = date.getDate();
  const daysLeft = lastDayOfMonth - currentDay + 1;
  return daysLeft;
};

export const getDaysPassedInMonth = (selectedDate: Date): number => {
  const clonedDate = new Date(selectedDate);
  clonedDate.setDate(1);
  const differenceInMs = selectedDate.getTime() - clonedDate.getTime();
  const daysPassed = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  return daysPassed + 1;
};

export const convertToTwoDecimalPlaces = (value: number): number => {
  if (value === 0) {
    return value;
  }

  return parseFloat(value.toFixed(2));
};

export const convertToThreeDecimalPlaces = (value: number): number => {
  if (value === 0) {
    return value;
  }

  return parseFloat(value.toFixed(3));
};

export const getPaginationData = () => {
  let Pagination: any = sessionStorage.getItem('Pagination');

  if (!Pagination) {
    Pagination = DefaultPaginationConfig;
  } else {
    Pagination = JSON.parse(Pagination);
  }

  return Pagination;
};

export const getHomeDashboardStats = (
  dashboardCounterKey: string,
  count: number
): IDashboardStats => {
  const counters: Record<
    string,
    { title: string; icon: any; iconColor: string }
  > = {
    totalProjects: {
      title: 'Projects',
      icon: FolderKanban,
      iconColor: '#605E5C',
    },
    totalBBS: { title: 'BBS', icon: Database, iconColor: '#03274E' },
    totalMembers: { title: 'Members', icon: List, iconColor: '#107C10' },
    totalWeight: {
      title: 'Total Weight (Ton)',
      icon: Weight,
      iconColor: '#DEB341',
    },
    totalCallOffs: {
      title: 'Call Offs',
      icon: Parentheses,
      iconColor: '#107C10',
    },
  };

  const { title, icon, iconColor } = counters[dashboardCounterKey] || {};

  return {
    title: title || '',
    icon: icon || null,
    iconColor: iconColor || '',
    count,
    filter: '',
  };
};
