import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { useEffect, useRef, useState } from "react";
import ErrorMessage from "../global/ErrorMessage";
import CustomLabel from "../global/RequiredMark";

interface IProps {
  name: string;
  value: string | undefined;
  required: boolean;
  label: string;
  placeholder: string;
  disabled: boolean;
  onChange: (e: TextBoxChangeEvent) => void;
  regex?: RegExp;
  error?: string;
  onValidate: (name: string, isValid: boolean) => void;
  flexDir?: string;
  className?: string;
}

function BBSTextField({
  name,
  value,
  required,
  label,
  placeholder,
  disabled,
  onChange,
  regex,
  error,
  onValidate,
  flexDir,
  className,
}: IProps) {
  const valueChanged = useRef(false);
  const [isValid, setIsValid] = useState(true);
  const [dirty, setDirty] = useState(false);

  const handleChange = (e: TextBoxChangeEvent) => {
    valueChanged.current = true;
    const input = e.target.value as string;

    let valid = true;
    if (regex) {
      valid = !regex.test(input);
    } else {
      valid = !!input;
    }

    if (required && input === "") {
      valid = false;
    } else {
      valid = true;
    }
    if (input === "" && !required) {
      setIsValid(true);
      onValidate(name, valid);
    } else {
      setIsValid(valid);
      onValidate(name, valid);
    }
    onChange(e);
  };

  useEffect(() => {
    if (required && !value && valueChanged.current === false) {
      onValidate(name, false);
    }

    if (
      required &&
      value !== null &&
      value !== undefined &&
      valueChanged.current === false
    ) {
      onValidate(name, true);
    }
  }, [value, required]);

  return (
    <div className={flexDir === "row" ? "flex gap-x-4" : ""}>
      {label && label != "" && (
        <CustomLabel
          label={label}
          required={disabled ? false : required}
          flexDir={flexDir}
        />
      )}
      <div className={`flex-1 ${flexDir ? "w-2/3" : ""}`}>
        <TextBox
          placeholder={disabled ? "" : placeholder}
          name={name}
          onChange={handleChange}
          disabled={disabled}
          value={value || ""}
          valid={isValid}
          onBlur={() => setDirty(true)}
          className={`self-center ${className}`}
          autoComplete="off"
        />
        {required && dirty && !value && (
          <ErrorMessage message="*Field can't be empty." />
        )}
        {!isValid && dirty && value && <ErrorMessage message={`*${error}`} />}
      </div>
    </div>
  );
}

export default BBSTextField;
