const Filters = {
  filterOptions: {
    'text': [
      { text: 'grid.filterContainsOperator', operator: 'contains' },
      { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
      { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
      { text: 'grid.filterEqOperator', operator: 'eq' },
      { text: 'grid.filterNotEqOperator', operator: 'neq' },
    ],
    'numeric': [
      { text: 'grid.filterEqOperator', operator: 'eq' },
      { text: 'grid.filterNotEqOperator', operator: 'neq' },
    ],
    'date': [
      {
        "text": "grid.filterEqOperator",
        "operator": "eq"
      },
      {
        "text": "grid.filterNotEqOperator",
        "operator": "neq"
      },
      {
        "text": "grid.filterAfterOrEqualOperator",
        "operator": "gte"
      },
      {
        "text": "grid.filterAfterOperator",
        "operator": "gt"
      },
      {
        "text": "grid.filterBeforeOperator",
        "operator": "lt"
      },
      {
        "text": "grid.filterBeforeOrEqualOperator",
        "operator": "lte"
      }
    ]
  },
  resizeable: true,
  filterable: true,
}

export default Filters;
