import { ReactNode } from "react"

const OnBoardingContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className='min-h-screen flex items-center relative flex-col bg-white'>
      <div className=" flex-1 flex w-full">

        <div className='grid lg:grid-cols-3 items-center flex-1 w-full'>
          <div className='text-center lg:col-span-2 lg:order-1 order-2 lg:block hidden h-full'>
            <div className="flex flex-col items-start 2xl:px-16 xl:px-8 px-4  h-full py-8 relative z-10">
              <img src="./images/brand-icon.png" alt="" />
              {/* <h2 className="mt-4 text-3xxl font-kanit max-w-lg text-left font-light tracking-tight leading-[40px] mb-8">Manage your <span className="font-normal uppercase text-[var(--kendo-primary-100)]">LEASE and Immigration</span> at one place</h2>
              <div className='flex-1 flex flex-col justify-center items-center py-8'>
                <img src="./images/visa.png" alt="" className='max-w-[747px]' />
              </div> */}
            </div>
          </div>
          <div className="2xl:px-16 xl:px-8 px-4 lg:order-2 order-1 lg:pt-[100px] lg:pb-[40px] py-12 relative z-10 h-full flex flex-col">
            <div className='flex-1 flex flex-col justify-center max-w-md mx-auto w-full '>

              <h2 className="md:text-6xl text-4xl font-kanit  w-full font-light">Bar Bending</h2>
              <div className='flex-1 flex flex-col justify-center items-center py-8'>
                {children}
              </div>
            </div>
            <div className='flex justify-between text-base  w-full  max-w-md mx-auto '>
              <p className="text-[#9CA3AF]">All Rights Reserved</p>
              <p className="text-[#6B7280]">Copyrights © 2024 ALEC</p>
            </div>
          </div>
        </div>
      </div>
      <img src="./images/bgBottom.png" alt="" className="absolute bottom-0 left-0 lg:max-w-max max-w-[250px]" />
    </div>
  )
}

export default OnBoardingContainer
