import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { X } from "lucide-react";
import { useState } from "react";
import CustomLabel from "../../component/global/RequiredMark";
import BBSTextField from "../../component/inputs/BBSTextField";
import { ITemplateMapping } from "../../interfaces/mapping.interface";
import { ISubContractor } from "../../interfaces/subcontractor.interface";

interface IProps {
  showCreateDrawer: boolean;
  setShowCreateDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  addNewSubcontractor: (ISubContractor & { id: string }) | null;
  handleChange: (
    e: TextBoxChangeEvent | ComboBoxChangeEvent,
    isDropdown?: boolean
  ) => void;
  onSave: () => void;
  mappings: ITemplateMapping[];
}

function WriteDrawer({
  showCreateDrawer,
  setShowCreateDrawer,
  addNewSubcontractor,
  handleChange,
  onSave,
  mappings,
}: IProps) {
  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});

  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev: any) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  const validateSave = () => {
    return Object.values(invalidFields).every((field) => !field);
  };

  const getDropdownValue = () => {
    return mappings?.find(
      (item: any) => item.id === addNewSubcontractor?.templateMappingId
    );
  };

  return (
    <Drawer
      expanded={showCreateDrawer}
      position={"start"}
      mode={"overlay"}
      animation={{ duration: 400 }}
      className="customDrawer2 transition-all duration-500"
    >
      <DrawerContent>
        <div
          className="overlay"
          onClick={() => setShowCreateDrawer(false)}
        ></div>
        <div
          className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
            showCreateDrawer ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
            <h2 className="font-medium text-lg">
              {addNewSubcontractor?.id
                ? "Edit Subcontractor"
                : "Add New Subcontractor"}
            </h2>
            <Button
              fillMode="flat"
              className="border border-[#E2E8F0]"
              onClick={() => setShowCreateDrawer(false)}
            >
              <X className="w-4 h-4" />{" "}
            </Button>
          </div>
          <div className="flex-1 py-4">
            <div className="grid gap-4">
              <div>
                <BBSTextField
                  name="name"
                  value={addNewSubcontractor?.name}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Name"
                  required={true}
                  placeholder="Enter Name"
                  disabled={false}
                  regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                  error="Invalid Name"
                />
              </div>
              <div>
                <BBSTextField
                  name="address"
                  value={addNewSubcontractor?.address}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Address"
                  required={false}
                  placeholder="Enter Address"
                  disabled={false}
                  error="Invalid Address"
                />
              </div>
              <div>
                <BBSTextField
                  name="contactPerson"
                  value={addNewSubcontractor?.contactPerson}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Contact Person"
                  required={false}
                  placeholder="Enter Contact Person"
                  disabled={false}
                  error="Invalid Contact Person"
                />
              </div>
              <div>
                <CustomLabel label="Default Mapping" required={false} />
                <ComboBox
                  data={mappings || []}
                  placeholder="Select Mapping"
                  name="templateMappingId"
                  value={getDropdownValue()}
                  onChange={(e) => handleChange(e, true)}
                  disabled={false}
                  clearButton={false}
                  dataItemKey="id"
                  textField="mappingName"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 pb-4">
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              onClick={() => setShowCreateDrawer(false)}
            >
              Cancel
            </Button>
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              themeColor={"primary"}
              onClick={onSave}
              disabled={!validateSave()}
            >
              Save
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default WriteDrawer;
