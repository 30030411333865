import { Button } from "@progress/kendo-react-buttons";
import { Plus } from "lucide-react";
import {
  IDashboardStats,
  IHomeDashboardProps,
  IHomeDashboardStats,
} from "../../../interfaces/dashboard.interface";
import React from "react";
import { getHomeDashboardStats } from "../../../utils/utils";
import StatsHomeCard from "../../../component/ui/cards/StatsHomeCard";

const Stats = ({ statsData }: IHomeDashboardProps) => {
  const dashboardCounterList: string[] = [
    "totalProjects",
    "totalBBS",
    "totalMembers",
    "totalWeight",
    "totalCallOffs",
  ];

  const [dashboardStatsData, setDashboardStatsData] = React.useState<
    IDashboardStats[]
  >([]);

  React.useEffect(() => {
    const dashboardStats: IDashboardStats[] = [];

    for (const key of dashboardCounterList) {
      if (statsData.hasOwnProperty(key)) {
        const count: any = (statsData as any)[key];
        dashboardStats.push(getHomeDashboardStats(key, count));
      }
    }

    setDashboardStatsData(dashboardStats);
  }, [statsData]);

  return (
    <>
      <div className="grid md:grid-cols-5 py-3 bg-white">
        {dashboardStatsData &&
          dashboardStatsData.length > 0 &&
          dashboardStatsData.map((data, index) => (
            <span
              className="flex flex-col bg-white lg:px-4 px-2 lg:py-0 py-2 first-of-type:pt-0 last-of-type:pb-0 lg:border-r md:border-b-0 border-neutral-30 last-of-type:border-none shadow-shadow-elevation4 group duration-300 transition-all"
              key={index}
            >
              <StatsHomeCard
                title={data.title}
                count={data.count}
                Icon={data.icon}
                iconColor={data.iconColor}
              />
            </span>
          ))}
      </div>
    </>
  );
};

export default Stats;
