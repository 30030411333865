import { Button } from "@progress/kendo-react-buttons";
import { ShowColumnsFilter } from "../../component/global/ColumnMenu";
import {
  ActionCellPopupSettings,
  CompanyActionCellPopup,
} from "../../shared-components/custom-cells";
import { MIN_RESIZABLE_WIDTH_KENDO_TABLE } from "../../utils/utils";
import { Download, DownloadCloudIcon, EyeIcon } from "lucide-react";

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
  handleDeleteClick: any;
}

export const generateColumns = (
  handleDeleteClick: any,
  handleDownload: any,
  handlePreview: any,
  fetchCompanies: any,
  handleEditClick: any,
  onColumnsSubmit: any,
  columnsExternal?: any,
  columnsMapping?: any,
  changed?: boolean
): any => {
  // const isVisible = (field: string) => {
  //   if(!columnsMapping?.length) return false;
  //   if(columnsMapping.includes(field)){
  //     return true;
  //   }

  //   return false;
  // }

  const columns = [
    {
      title: "Code",
      field: "code",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
        />
      ),
      cells: {
        data: (props: any) => (
          <ActionCellPopupSettings
            {...props}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            fetchCompanies={fetchCompanies}
            dataKey="code"
            rowData={props.dataItem}
          />
        ),
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Dimensions Constraint",
      field: "dimensionsConstraint",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Condition",
      field: "condition",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Formula",
      field: "formula",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },

    {
      title: "Image",
      field: "imagePath",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) =>
          props.dataItem.imagePath ? (
            <td>
              <div
                className={`relative group transition-all duration-500 flex items-center justify-center flex-col border-neutral-30 bg-neutral-10 cursor-pointer w-[100px]  overflow-hidden 'pointer-events-none'`}
              >
                <img src={props.dataItem.imagePath} width={100} />
                <div className="absolute inset-0 flex items-center justify-center gap-2  bg-black/70 transition-all duration-500 opacity-0 invisible group-hover:opacity-100 group-hover:visible">
                  <Button
                    className="rounded p-2 text-white border border-white"
                    fillMode={"outline"}
                    onClick={() =>
                      handlePreview(
                        props.dataItem.imagePath,
                        props.dataItem.code
                      )
                    }
                  >
                    <EyeIcon size={16} className="w-4 h-4" />
                  </Button>
                  <Button
                    fillMode={"outline"}
                    className="rounded p-2 text-white border border-white"
                    onClick={() =>
                      handleDownload(
                        props.dataItem.imagePath,
                        props.dataItem.code
                      )
                    }
                  >
                    <DownloadCloudIcon size={16} className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            </td>
          ) : (
            <td>
              <div className="flex justify-center w-full h-full">
                <p>No Image</p>
              </div>
            </td>
          ),
      },
    },
  ];

  return columns;
};
