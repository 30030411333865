import { IApplication } from '../../interfaces/peopleflow/application.interface';
import { IEmployee } from '../../interfaces/employee.interface';
import { IGovernmentID } from '../../interfaces/peopleflow/govermentID.interface';
import { IILOE } from '../../interfaces/peopleflow/iloe.interface';
import { ILaborCard } from '../../interfaces/peopleflow/laborcard.interface';
import { IPassport } from '../../interfaces/peopleflow/passport.interface';
import { IVisa } from '../../interfaces/peopleflow/visa.interface';
import { IWorkPermit } from '../../interfaces/peopleflow/workpermit.interface';
import { api } from '../../lib';
import { setToast } from '../../store/features/toastSlice';
import { store } from '../../store/store/store';
import { getPaginationData } from '../../utils/utils';
import LoaderService from '.././loader.service';

interface ApiResponse<T> {
  value: T[];
}

const Pagination = getPaginationData();
class ApplicationService {
  async create(appRequest: IApplication) {
    try {
      LoaderService.showLoader();

      appRequest.ActivePassport = undefined;
      appRequest.ActiveVisa = undefined;
      appRequest.ActiveWorkpermit = undefined;
      appRequest.EmployeeClass = undefined;
      appRequest.EmployeeDetails = undefined;
      appRequest.ActiveLaborCard = undefined;
      appRequest.ActiveGovernmentId = undefined;
      appRequest.ActiveILOE = undefined;

      const res = await api.post(
        `${process.env.REACT_APP_BASE_URL}/data/Applications`,
        appRequest
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg:
            'An error occurred while creating the application. Please try again later.',
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async bulkComplete(applicationIds: number[], stepId: number) {
    try {
      LoaderService.showLoader();

      const res = await api.post(
        `${process.env.REACT_APP_BASE_URL}/ApplicationBulk/complete`,
        { applicationIds, stepId }
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg:
            'An error occurred while completing the applications. Please try again later.',
        })
      );

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg:
            'An error occurred while completing the applications. Please try again later.',
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async update(appRequest: IApplication) {
    try {
      LoaderService.showLoader();
      appRequest.ActivePassport = undefined;
      appRequest.ActiveVisa = undefined;
      appRequest.ActiveWorkpermit = undefined;
      appRequest.EmployeeDetails = undefined;
      appRequest.ActiveLaborCard = undefined;
      appRequest.ActiveGovernmentId = undefined;
      appRequest.ActiveILOE = undefined;

      const res = await api.put(
        `${process.env.REACT_APP_BASE_URL}/data/Applications/${appRequest.Id}`,
        appRequest
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg:
            'An error occurred while updating the application. Please try again later.',
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async findById(id: string) {
    try {
      LoaderService.showLoader();
      const applicationRes = await api.get<IApplication>(
        `${process.env.REACT_APP_BASE_URL}/data/Applications/${id}`
      );

      if (applicationRes.ok) {
        const filterParam = `EmployeeId eq ${applicationRes.data?.EmployeeId} and IsDeleted eq false and IsActive eq true`;
        const employeeFilterParam = `Id eq ${applicationRes.data?.EmployeeId}`;
        const workPermitRes = await api.get<ApiResponse<IWorkPermit>>(
          `${process.env.REACT_APP_BASE_URL}/data/WorkPermits?$filter=${filterParam}&$count=true`
        );
        const visaRes = await api.get<ApiResponse<IVisa>>(
          `${process.env.REACT_APP_BASE_URL}/data/Visas?$filter=${filterParam}&$count=true`
        );
        const passportRes = await api.get<ApiResponse<IPassport>>(
          `${process.env.REACT_APP_BASE_URL}/data/Passports?$filter=${filterParam}&$count=true`
        );
        const employeeRes = await api.get<ApiResponse<IEmployee>>(
          `${process.env.REACT_APP_BASE_URL}/data/Employees?$filter=${employeeFilterParam}&$count=true`
        );
        const laborCardRes = await api.get<ApiResponse<ILaborCard>>(
          `${process.env.REACT_APP_BASE_URL}/data/LaborCards?$filter=${filterParam}&$count=true`
        );
        const governmentIdRes = await api.get<ApiResponse<IGovernmentID>>(
          `${process.env.REACT_APP_BASE_URL}/data/GovernmentIDs?$filter=${filterParam}&$count=true`
        );
        const iloeRes = await api.get<ApiResponse<IILOE>>(
          `${process.env.REACT_APP_BASE_URL}/data/UnemploymentInsurances?$filter=${filterParam}&$count=true`
        );

        if (workPermitRes.ok && applicationRes.data) {
          if (workPermitRes.data && workPermitRes.data.value.length > 0) {
            applicationRes.data.ActiveWorkpermit = workPermitRes.data.value[0];
          } else {
            applicationRes.data.ActiveWorkpermit = undefined;
          }
        }

        if (visaRes.ok && applicationRes.data) {
          if (visaRes.data && visaRes.data.value.length > 0) {
            applicationRes.data.ActiveVisa = visaRes.data.value[0];
          } else {
            applicationRes.data.ActiveVisa = undefined;
          }
        }

        if (passportRes.ok && applicationRes.data) {
          if (passportRes.data && passportRes.data.value.length > 0) {
            applicationRes.data.ActivePassport = passportRes.data.value[0];
          } else {
            applicationRes.data.ActivePassport = undefined;
          }
        }

        if (employeeRes.ok && applicationRes.data) {
          if (employeeRes.data && employeeRes.data.value.length > 0) {
            applicationRes.data.EmployeeDetails = employeeRes.data.value[0];
          } else {
            applicationRes.data.EmployeeDetails = undefined;
          }
        }

        if (laborCardRes.ok && applicationRes.data) {
          if (laborCardRes.data && laborCardRes.data.value.length > 0) {
            applicationRes.data.ActiveLaborCard = laborCardRes.data.value[0];
          } else {
            applicationRes.data.ActiveLaborCard = undefined;
          }
        }

        if (governmentIdRes.ok && applicationRes.data) {
          if (governmentIdRes.data && governmentIdRes.data.value.length > 0) {
            applicationRes.data.ActiveGovernmentId =
              governmentIdRes.data.value[0];
          } else {
            applicationRes.data.ActiveGovernmentId = undefined;
          }
        }

        if (iloeRes.ok && applicationRes.data) {
          if (iloeRes.data && iloeRes.data.value.length > 0) {
            applicationRes.data.ActiveILOE = iloeRes.data.value[0];
          } else {
            applicationRes.data.ActiveILOE = undefined;
          }
        }

        return { data: applicationRes.data, error: null };
      }

      return { data: null, error: applicationRes.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async get(
    searchQuery = '',
    pageNumber = 1,
    pageSize = Pagination.initialDataState.take,
    kendoFilter?: string,
    showArchived?: boolean,
    activeTab?: string,
    statusFilter = 0
  ) {
    try {
      LoaderService.showLoader();
      let skip = Math.max((pageNumber - 1) * pageSize, 0);

      let filterParam = '';

      if (kendoFilter) {
        skip = 0;
        filterParam += kendoFilter ? `${kendoFilter} and ` : '';
      }

      const searchParam = searchQuery
        ? isNaN(Number(searchQuery))
          ? `(contains(EmployeeName, '${searchQuery}') or contains(CompanyName, '${searchQuery}') or contains(ApplicationType, '${searchQuery}') or contains(AssigneeName, '${searchQuery}')) and `
          : `(EmployeeId eq ${searchQuery} or CompanyId eq ${searchQuery} or AssigneeId eq ${searchQuery}) and `
        : '';

      filterParam += `${searchParam}IsDeleted eq ${showArchived || false}`;

      if (activeTab) {
        filterParam += ` and ApplicationType eq '${activeTab}'`;
      }
      // Status filter 0 means All
      // Status filter 1 means Unassigned
      // Status filter other than 0 and 2 means assigned to me

      if (statusFilter !== 0 && statusFilter === 1) {
        filterParam += ` and AssigneeId eq null`;
      }
      if (statusFilter !== 0) {
        filterParam += ` and AssigneeId eq ${statusFilter}`;
      }

      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/data/ApplicationView?$filter=${filterParam}&$orderby=Id desc&$skip=${skip}&$top=${pageSize}&$count=true`
      );

      if (res.ok) {
        return {
          data: res.data.value,
          total: res.data['@odata.count'],
          error: null,
        };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg:
            'An error occurred while fetching applications. Please try again later.',
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getAll(showArchived?: boolean) {
    try {
      LoaderService.showLoader();

      const res: any = await api.get(
        `${
          process.env.REACT_APP_BASE_URL
        }/data/ApplicationView?$filter=IsDeleted eq ${showArchived || false}`
      );
      if (res.ok) {
        return { data: res.data.value, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg:
            'An error occurred while fetching applications. Please try again later.',
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getStats() {
    try {
      LoaderService.showLoader();
      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/Dashboard/application-stats`
      );
      if (res?.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg:
            'An error occurred while fetching application stats. Please try again later.',
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async cancelApplication(applicationId: number) {
    try {
      LoaderService.showLoader();

      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/data/Applications/${applicationId}`
      );

      if (res.ok) {
        await api.put(
          `${process.env.REACT_APP_BASE_URL}/data/Applications/${applicationId}`,
          { ...res.data, Status: 'Cancelled' }
        );
        return { data: res.data.value, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg:
            'An error occurred while cancelling the application. Please try again later.',
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async assignToMyself(userId: number, applicationId: number) {
    try {
      LoaderService.showLoader();

      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/data/Applications/${applicationId}`
      );

      if (res.ok) {
        await api.put(
          `${process.env.REACT_APP_BASE_URL}/data/Applications/${applicationId}`,
          { ...res.data, AssigneeId: userId }
        );
        return { data: res.data.value, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      store.dispatch(
        setToast({
          toastType: 'error',
          toastMsg:
            'An error occurred while assigning the application. Please try again later.',
        })
      );
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const applicationService = new ApplicationService();

export default applicationService;
