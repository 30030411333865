import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import BBSDatePicker from "../../../component/inputs/BBSDatePicker";
import BBSTextField from "../../../component/inputs/BBSTextField";
import { IBBS } from "../../../interfaces/bbs.interface";
import { IObjectType } from "../../../interfaces/objectType.interface";
import { IDetailingOffice } from "../../../interfaces/project.interface";
import objectTypeSvc from "../../../services/objectType.service";
import projectSvc from "../../../services/project.service";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";

interface IProps {
  showCreateDrawer: boolean;
  setShowCreateDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  editBBS: (IBBS & { id: string }) | null;
  handleChange: (
    e: TextBoxChangeEvent | ComboBoxChangeEvent | DatePickerChangeEvent,
    isDropdown?: boolean
  ) => void;
  onSave: () => void;
}

function WriteDrawer({
  showCreateDrawer,
  setShowCreateDrawer,
  editBBS,
  handleChange,
  onSave,
}: IProps) {
  const commonState = useAppSelector((state: RootState) => state.common);

  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});

  const [detailingOffices, setDetailingOffices] = useState<IDetailingOffice[]>(
    []
  );
  const [objectTypes, setObjectTypes] = useState<IObjectType[]>([]);

  const fetchObjectTypes = () => {
    objectTypeSvc.getAll().then((res) => {
      setObjectTypes(res.data);
    });
  };

  const fetchDetailingOffices = (projectId: string) => {
    projectSvc.getById(editBBS?.projectId as string).then((res: any) => {
      setDetailingOffices(res?.data?.detailingOffices);
    });
  };

  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev: any) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  const validateSave = () => {
    return Object.values(invalidFields).every((field) => !field);
  };

  const getDetailingOfficeValue = () => {
    return detailingOffices?.find(
      (item: any) => item.name === editBBS?.detailingOff
    );
  };

  const getObjectTypeValue = () => {
    return objectTypes?.find((item: any) => item.name === editBBS?.objectType);
  };

  useEffect(() => {
    fetchObjectTypes();
  }, []);

  const isDetailingFetched = useRef(false);
  useEffect(() => {
    if (editBBS?.projectId && !isDetailingFetched.current) {
      fetchDetailingOffices(editBBS?.projectId);
      isDetailingFetched.current = true;
    }
  }, [editBBS]);

  useEffect(() => {
    if (showCreateDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showCreateDrawer]);

  return (
    <Drawer
      expanded={showCreateDrawer}
      position={"start"}
      mode={"overlay"}
      animation={{ duration: 400 }}
      className="customDrawer2 transition-all duration-500"
    >
      <DrawerContent>
        <div
          className="overlay"
          onClick={() => setShowCreateDrawer(false)}
        ></div>
        <div
          className={`max-w-[700px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-screen w-full transform transition-all duration-500 overflow-y-scroll ${
            showCreateDrawer ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
            <h2 className="font-medium text-lg">Edit BBS</h2>
            <Button
              fillMode="flat"
              className="p-2 bg-none outline-none"
              onClick={() => setShowCreateDrawer(false)}
            >
              <X className="w-4 h-4" />{" "}
            </Button>
          </div>
          <div className="flex-1 py-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <BBSTextField
                  name="name"
                  value={editBBS?.name}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="BBS Name"
                  required={true}
                  placeholder="Enter Name"
                  disabled={false}
                  regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                  error="Invalid Name"
                />
              </div>
              <div>
                <BBSTextField
                  name="no"
                  value={editBBS?.no}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="BBS Number"
                  required={false}
                  placeholder="Enter Number"
                  disabled={false}
                  error="Invalid Number"
                />
              </div>
              <div>
                <BBSTextField
                  name="revision"
                  value={editBBS?.revNo}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="BBS Revision"
                  required={false}
                  placeholder="Enter Address"
                  disabled={true}
                  error="Invalid Address"
                />
              </div>
              <div>
                <BBSTextField
                  name="shopDrawingNo"
                  value={editBBS?.shopDrawNo}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Shop Drawing No."
                  required={false}
                  placeholder="Enter Shop Drawing No"
                  disabled={false}
                  error="Invalid Contact Person"
                />
              </div>
              <div>
                <BBSTextField
                  name="shopDrawingRevNo"
                  value={editBBS?.shopDrawRevNo}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Shop Drawing Rev No."
                  required={false}
                  placeholder="Enter Shop Drawing Rev No."
                  disabled={false}
                  error="Invalid Contact Person"
                />
              </div>

              <div>
                <CustomLabel label="Detailing Office" required={true} />
                <ComboBox
                  data={detailingOffices || []}
                  placeholder="Select Detailing Office"
                  name="detailingOff"
                  value={getDetailingOfficeValue()}
                  onChange={(e) => handleChange(e, true)}
                  disabled={false}
                  clearButton={false}
                  dataItemKey="name"
                  textField="name"
                />
              </div>
              <div>
                <CustomLabel label="Object Type" required={true} />
                <ComboBox
                  data={objectTypes || []}
                  placeholder="Select Object Type"
                  name="objectType"
                  value={getObjectTypeValue()}
                  onChange={(e) => handleChange(e, true)}
                  disabled={false}
                  clearButton={false}
                  dataItemKey="name"
                  textField="name"
                />
              </div>
              <div>
                <BBSTextField
                  name="preparedBy"
                  value={editBBS?.preparedBy}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Prepared By"
                  required={false}
                  placeholder="Enter Prepared By."
                  disabled={false}
                  error="Invalid Contact Person"
                />
              </div>
              <div>
                <BBSDatePicker
                  name="preparedOn"
                  value={
                    editBBS?.preparedOn ? new Date(editBBS?.preparedOn) : null
                  }
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Prepared On"
                  required={false}
                  placeholder="Enter Prepared On."
                  disabled={false}
                />
              </div>
              <div>
                <BBSTextField
                  name="checkedBy"
                  value={editBBS?.checkedBy}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Checked By"
                  required={false}
                  placeholder="Enter Checked By."
                  disabled={false}
                  error="Invalid Contact Person"
                />
              </div>
              <div>
                <BBSDatePicker
                  name="checkedOn"
                  value={
                    editBBS?.checkedOn ? new Date(editBBS?.checkedOn) : null
                  }
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Checked On"
                  required={false}
                  placeholder="Enter Check On."
                  disabled={false}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 pb-4 sticky-buttons">
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              onClick={() => setShowCreateDrawer(false)}
            >
              Cancel
            </Button>
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              themeColor={"primary"}
              onClick={onSave}
              disabled={!validateSave()}
            >
              Save
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default WriteDrawer;
