import { BaseService } from './base.service';

class SubContractorService extends BaseService {
  constructor() {
    super('Subcontractor');
  }
}

const subContractorSvc = new SubContractorService();

export default subContractorSvc;
