import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CheckboxOption } from '../../component/ui/MultiSelectCheckboxFilter';
import type { RootState } from '../store/store';

const initialState: CheckboxOption[] = [
  { label: 'New', defaultChecked: false, isDefault: true },
  { label: 'Renewed', defaultChecked: false, isDefault: true },
  { label: 'Cancelled', defaultChecked: false, isDefault: true },
  { label: 'Expired', defaultChecked: false, isDefault: true },
  { label: 'Pending', defaultChecked: false, isDefault: true },
];

export const leaseStatusSlice = createSlice({
  name: 'leaseFilteredStatus',
  initialState,
  reducers: {
    addFilters: (state, action: PayloadAction<CheckboxOption>) => {
      const index = state.findIndex(x => x.label === action.payload.label);
      if (index !== -1) {
        state[index].defaultChecked = action.payload.defaultChecked;
        return state;
      }

      return [
        ...state,
        action.payload,
      ]
    },
    updateFilters: (state, action: PayloadAction<CheckboxOption[]>) => {
      return action.payload;
    },
    clearFilters: (state) => {
      return initialState;
    },
  },
});

export const { addFilters, updateFilters, clearFilters } = leaseStatusSlice.actions;
export const selectDefaultLeaseStatus = (state: RootState) => state.leaseFilteredStatus.filter(x => x.isDefault);
export const selectLeaseSelectedStatus = (state: RootState) => state.leaseFilteredStatus;
export default leaseStatusSlice.reducer;
