import { IDialogInfo } from "../interfaces/dialog-info.interface";

export const getDialogInfo = (action: string): IDialogInfo => {
  let dialog: IDialogInfo | undefined = undefined;

  switch (action) {
    case 'delete':
      dialog = {
        title: 'Delete',
        message: 'Are you sure you want to delete?',
      };

      break;

    case 'delete-standard':
      dialog = {
        title: 'Delete',
        message: 'This will also delete related Bars, Bar Sizes and Bar Types. Are you sure you want to delete?',
      };

      break;

    case 'cancel':
      dialog = {
        title: 'Cancellation',
        message: 'Are you sure you want to cancel this lease?',
      };

      break;

    case 'cancel-dependent':
      dialog = {
        title: 'Cancellation',
        message: 'Are you sure you want to cancel this dependant?',
      };

      break;

    case 'discard':
      dialog = {
        title: 'Discard Changes',
        message: 'Are you sure you want to discard changes?',
      };

      break;

    case 'save':
      dialog = {
        title: 'Save Changes',
        message: 'Are you sure you want to save the changes?',
      };

      break;

    case 'completeStep':
      dialog = {
        title: 'Complete Step',
        message: 'Are you sure you want to complete this application step?',
      };

      break;

    case 'renewApplication':
      dialog = {
        title: 'Renew Application',
        message: 'Are you sure you want to start the process of Renewal?',
      };

      break;

    case 'cancelApplication':
      dialog = {
        title: 'Cancel Application',
        message: 'Are you sure you want to start the process of Cancellation?',
      };

      break;

    case 'abscondApplication':
      dialog = {
        title: 'Abscond Application',
        message: 'Are you sure you want to start the process of Absconding?',
      };

      break;
  }

  if (!dialog) {
    throw new Error('Invalid action');
  }

  return dialog;
};
