import { Button } from "@progress/kendo-react-buttons";
import { ListItemProps, TagData } from "@progress/kendo-react-dropdowns";
import { GridCellProps, GridCustomCellProps } from "@progress/kendo-react-grid";
import { Popup } from "@progress/kendo-react-popup";
import { jwtDecode } from "jwt-decode";
import {
  ArrowUpSquare,
  ChevronRight,
  Eye,
  FileX,
  FileX2,
  History,
  Info,
  MoreHorizontal,
  Pencil,
  PencilLine,
  Repeat2,
  ShieldCheck,
  ShieldMinus,
  Trash2,
} from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import applicationService from "../services/peopleflow/application.service";
import dependantSvc from "../services/peopleflow/dependant.service";
import leaseService from "../services/peopleflow/lease.service";
import sharedSvc from "../services/shared.service";
import { useAppSelector } from "../store/hooks/hooks";
import { RootState } from "../store/store/store";
import {
  APPLICATION_TYPE_COLORS,
  STEPS_NAME_COLORS,
  STEPS_STATUS_COLORS,
} from "../utils/application.utils";
import { getDialogInfo } from "../utils/dialog.utils";
import {
  STATUS_CANCELLED,
  STATUS_COMPLETED,
  STATUS_EXPIRED,
  STATUS_NEW,
  STATUS_PENDING,
  STATUS_RENEWED,
  STATUS_STARTED,
} from "../utils/lease-status";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../utils/utils";
import { CustomDialogBox } from "./dialog-box";
import subContractorSvc from "../services/subContractor.service";
import { IShapeCodes } from "../interfaces/shapes.interface";
import { SUPER_USER } from "../utils/roles.constant";
import templateMappingSvc from "../services/templateMapping.service";
import objectTypeSvc from "../services/objectType.service";
import companySvc from "../services/company.service";
import bbsSvc from "../services/bbs.service";
import { Switch } from "@progress/kendo-react-inputs";
import { IBar, IBarSize, IStandard } from "../interfaces/standard.interface";
import { ILine } from "../interfaces/bbs.interface";

export const ActionCellPopupSettings = (props: any) => {
  const {
    rowData,
    handleEditClick,
    handleDeleteClick,
    dataKey,
    isStandard,
    action,
  } = props;
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDialog = () => {
    setModalVisible(!modalVisible);
  };

  const handleDialog = (action: string) => {
    if (action === "yes") {
      handleDeleteClick(rowData);
    }

    toggleDialog();
  };

  const modalKey = isStandard ? "delete-standard" : "delete";

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo(modalKey)}
          onToggleDeleteDialog={handleDialog}
        />
      )}

      <td className="text-end ">
        <div className="flex items-center gap-x-2 group">
          <div className="self-center cursor-pointer hover:underline hover:text-primaryMain">
            {rowData && dataKey && rowData[dataKey]}
          </div>
          <div className={`text-end`}>
            {/* <button className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible cta_dropdown  transition-all duration-300 ${show ? 'active' : ''}`} */}
            {action !== "read" && (
              <button
                className={`px-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible cta_dropdown  transition-all duration-300 `}
                onClick={onClick}
                ref={anchor}
              >
                <MoreHorizontal strokeWidth={1} />
              </button>
            )}
            <Popup
              anchor={anchor.current}
              show={show}
              popupClass={"w-[161px]"}
              anchorAlign={{ horizontal: "right", vertical: "top" }}
              popupAlign={{ horizontal: "left", vertical: "top" }}
            >
              <div ref={popupRef}>
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={() => {
                    setShow(false);
                    handleEditClick(rowData);
                  }}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <PencilLine className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">Edit</span>
                  </span>
                </Button>

                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={toggleDialog}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <Trash2 className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">Delete</span>
                  </span>
                </Button>
              </div>
            </Popup>
          </div>
        </div>
      </td>
    </>
  );
};

export const ActionCellPopupStandards = (props: any) => {
  const { rowData, handleStandardAction, dataKey } = props;
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDialog = () => {
    setModalVisible(!modalVisible);
  };

  const handleDialog = (action: string) => {
    if (action === "yes") {
      handleStandardAction("delete", rowData);
    }

    toggleDialog();
  };

  const modalKey = "delete-standard";

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo(modalKey)}
          onToggleDeleteDialog={handleDialog}
        />
      )}

      <td className="text-end ">
        <div className="flex items-center gap-x-2 group">
          <div
            className="self-center cursor-pointer hover:underline hover:text-primaryMain"
            onClick={() => {
              setShow(false);
              handleStandardAction("read", rowData);
            }}
          >
            {rowData && dataKey && rowData[dataKey]}
          </div>
          <div className="text-end">
            {/* <button className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible cta_dropdown  transition-all duration-300 ${show ? 'active' : ''}`} */}
            <button
              className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible cta_dropdown  transition-all duration-300 `}
              onClick={onClick}
              ref={anchor}
            >
              <MoreHorizontal strokeWidth={1} />
            </button>
            <Popup
              anchor={anchor.current}
              show={show}
              popupClass={"w-[161px]"}
              anchorAlign={{ horizontal: "right", vertical: "top" }}
              popupAlign={{ horizontal: "left", vertical: "top" }}
            >
              <div ref={popupRef}>
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={() => {
                    setShow(false);
                    handleStandardAction("edit", rowData);
                  }}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <PencilLine className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">Edit</span>
                  </span>
                </Button>

                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={toggleDialog}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <Trash2 className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">Delete</span>
                  </span>
                </Button>
              </div>
            </Popup>
          </div>
        </div>
      </td>
    </>
  );
};

export const RolesCell = (props: GridCustomCellProps) => {
  const label = props.dataItem.roles?.join(",");
  return <td {...props.tdProps}>{label}</td>;
};

export const CreatedOnDateCell = (props: GridCustomCellProps) => {
  const date = moment(props.dataItem.createdOn).format(DATE_TIME_FORMAT);

  return <td {...props.tdProps}>{date === "Invalid date" ? "" : date}</td>;
};

export const StartDateCell = (props: GridCustomCellProps) => {
  const date = moment(props.dataItem.StartDate).format(DATE_FORMAT);

  return <td {...props.tdProps}>{date}</td>;
};

export const EndDateCell = (props: GridCustomCellProps) => {
  const date = moment(props.dataItem.EndDate).format(DATE_FORMAT);

  return <td {...props.tdProps}>{date}</td>;
};

export const WeightCell = (props: GridCustomCellProps) => {
  return <td {...props.tdProps}>{props.dataItem.weight?.toFixed(3)}</td>;
};

export const TotalWeightCell = (props: GridCustomCellProps) => {
  return (
    <td {...props.tdProps}>
      {props.dataItem.lines
        ?.map((a: ILine) => a.weight)
        .reduce(function (a: any, b: any) {
          return a + b;
        })
        ?.toFixed(3)}
    </td>
  );
};

export const LeaseAmountCell = (props: GridCustomCellProps) => {
  return <td {...props.tdProps}>{props.dataItem.Amount} AED</td>;
};

export const LicenseExpiryDateCell = (props: GridCustomCellProps) => {
  const date = moment(props.dataItem.LicenseExpiryDate).format(DATE_FORMAT);

  return <td {...props.tdProps}>{date}</td>;
};

export const ChequeDateCell = (props: GridCustomCellProps) => {
  const chequeDate = moment
    .utc(props.dataItem.Date)
    .local()
    .format(DATE_FORMAT);

  return <td {...props.tdProps}>{chequeDate}</td>;
};

export const DateTimeCell = (props: GridCustomCellProps) => {
  let dateTime = "";

  if (props.dataItem.lastLoginDate) {
    dateTime = moment(props.dataItem.LastLoginDate).format(DATE_TIME_FORMAT);
  } else {
    dateTime = "---";
  }

  return <td {...props.tdProps}>{dateTime}</td>;
};

export const LeaseMonthCell = (props: GridCustomCellProps) => {
  const label = props.dataItem.LeaseMonths > 1 ? "Months" : "Month";

  return (
    <td {...props.tdProps}>
      {props.dataItem.LeaseMonths} {label}
    </td>
  );
};

export const StatusCell = (props: GridCustomCellProps) => {
  let color = "";
  switch (props.dataItem.Status) {
    case STATUS_EXPIRED:
      color = "bg-red-800 text-white";
      break;

    case STATUS_CANCELLED:
      color = "bg-red-600 text-white";
      break;

    case STATUS_NEW:
    case STATUS_RENEWED:
      color = "bg-primaryMain text-white";
      break;

    case STATUS_PENDING:
      color = "bg-warning-100 text-txtPrimary";
  }
  return (
    <td {...props.tdProps}>
      <span
        className={`${color}  uppercase p-[5px] text-[10px] font-bold rounded-[2px]`}
      >
        {props.dataItem.Status}
      </span>
    </td>
  );
};

export const LeaseListingActionCellPopup = (props: GridCustomCellProps) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const [canRenew, setCanRenew] = useState<boolean>(false);
  const [canCancel, setCanCancel] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = React.useState(false);

  const userState = useAppSelector((state: RootState) => state.user);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCancelApplication = async (leaseData: any) => {
    if (leaseData.Status !== "Expired") {
      const prevLease: any = await leaseService.findLease(
        leaseData.Id.toString()
      );

      await leaseService.updateLease({
        ...prevLease.data,
        Status: "Cancelled",
      });
      navigate("/lease");
    } else {
      console.error(`Lease with status ${leaseData.Status} can't be cancelled`);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const currentDate = moment();
    const endDate = moment(props.dataItem.EndDate);
    const daysUntilExpiration = endDate.diff(currentDate, "days");

    if (daysUntilExpiration <= 30) {
      setCanRenew(true);
    }
  }, [props.dataItem]);

  useEffect(() => {
    const userData: any = jwtDecode(userState.token as string);

    if (
      userData.roles &&
      typeof userData.roles === "string" &&
      userData.roles === SUPER_USER
    ) {
      setCanCancel(true);
    }
    if (
      userData.roles &&
      typeof userData.roles === "object" &&
      userData.roles.includes(SUPER_USER)
    ) {
      setCanCancel(true);
    }
  }, [userState]);

  const toggleDialog = () => {
    setModalVisible(!modalVisible);
  };

  const handleDialog = (action: string) => {
    if (action === "yes") {
      handleCancelApplication(props.dataItem);
    }

    toggleDialog();
  };

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("cancel")}
          onToggleDeleteDialog={handleDialog}
        />
      )}

      <td className="text-end ">
        <div className="flex items-center gap-x-2 group">
          <div
            className="self-center cursor-pointer hover:underline hover:text-primaryMain"
            onClick={() =>
              navigate(`/lease/${props.dataItem.Id}`, {
                state: { action: "view", refNo: props.dataItem.RefNo },
              })
            }
          >
            {props.dataItem.RefNo}
          </div>
          <div className="text-end">
            <button
              className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible cta_dropdown  transition-all duration-300 ${
                show ? "active" : ""
              }`}
              onClick={onClick}
              ref={anchor}
            >
              <MoreHorizontal strokeWidth={1} />
            </button>
            <Popup
              anchor={anchor.current}
              show={show}
              popupClass={"w-[161px]"}
              anchorAlign={{ horizontal: "right", vertical: "top" }}
              popupAlign={{ horizontal: "left", vertical: "top" }}
            >
              <div ref={popupRef}>
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={() =>
                    navigate(`/lease/${props.dataItem.Id}`, {
                      state: { action: "view", refNo: props.dataItem.RefNo },
                    })
                  }
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <Eye className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal"> View</span>
                  </span>
                </Button>
                {props.dataItem.Status === STATUS_PENDING && (
                  <Button
                    className="w-full iconBtn px-1"
                    fillMode="flat"
                    onClick={() =>
                      navigate(`/lease/${props.dataItem.Id}`, {
                        state: { action: "edit", refNo: props.dataItem.RefNo },
                      })
                    }
                  >
                    <span className="inline-flex items-center gap-2 w-full">
                      <PencilLine className="w-4 h-4" />
                      <span className="flex-1 text-sm font-normal"> Edit</span>
                    </span>
                  </Button>
                )}

                {canRenew && (
                  <Button
                    className="w-full iconBtn px-1"
                    fillMode="flat"
                    onClick={() =>
                      navigate(`/lease/${props.dataItem.Id}`, {
                        state: { action: "renew" },
                      })
                    }
                  >
                    <span className="inline-flex items-center gap-2 w-full">
                      <Repeat2 className="w-4 h-4" />
                      <span className="flex-1 text-sm font-normal">
                        {" "}
                        Renew Application
                      </span>
                    </span>
                  </Button>
                )}

                {canCancel && props.dataItem.Status === STATUS_PENDING && (
                  <Button
                    className="w-full iconBtn px-1"
                    fillMode="flat"
                    onClick={toggleDialog}
                  >
                    <span className="inline-flex items-center gap-2 w-full">
                      <FileX className="w-4 h-4" />
                      <span className="flex-1 text-sm font-normal">
                        {" "}
                        Cancel Application
                      </span>
                    </span>
                  </Button>
                )}

                {props.dataItem.Status === STATUS_RENEWED && (
                  <Button
                    className="w-full iconBtn px-1"
                    fillMode="flat"
                    onClick={() =>
                      navigate("/lease/history", {
                        state: {
                          lease: props.dataItem,
                        },
                      })
                    }
                  >
                    <span className="inline-flex items-center gap-2 w-full">
                      <History className="w-4 h-4" />
                      <span className="flex-1 text-sm font-normal">
                        {" "}
                        Lease History
                      </span>
                    </span>
                  </Button>
                )}
              </div>
            </Popup>
          </div>
        </div>
      </td>
    </>
  );
};

export const ArchivedLeaseListingActionCellPopup = (
  props: GridCustomCellProps
) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const popupRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRestore = async (leaseData: any) => {
    await sharedSvc.restore("Lease", leaseData.Id);
    navigate("/lease");
  };

  const navigate = useNavigate();

  return (
    <>
      <td className="text-end ">
        <div className="flex items-center gap-x-2 group">
          <div
            className="self-center cursor-pointer hover:underline hover:text-primaryMain"
            onClick={() =>
              navigate(`/lease/${props.dataItem.Id}`, {
                state: { action: "view", refNo: props.dataItem.RefNo },
              })
            }
          >
            {props.dataItem.RefNo}
          </div>
          <div className="text-end">
            <button
              className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible cta_dropdown  transition-all duration-300 ${
                show ? "active" : ""
              }`}
              onClick={onClick}
              ref={anchor}
            >
              <MoreHorizontal strokeWidth={1} />
            </button>
            <Popup
              anchor={anchor.current}
              show={show}
              popupClass={"w-[161px]"}
              anchorAlign={{ horizontal: "right", vertical: "top" }}
              popupAlign={{ horizontal: "left", vertical: "top" }}
            >
              <div ref={popupRef}>
                {
                  <Button
                    className="w-full iconBtn px-1"
                    fillMode="flat"
                    onClick={handleRestore}
                  >
                    <span className="inline-flex items-center gap-2 w-full">
                      <Pencil className="w-4 h-4" />
                      <span className="flex-1 text-sm font-normal">
                        Restore
                      </span>
                    </span>
                  </Button>
                }
              </div>
            </Popup>
          </div>
        </div>
      </td>
    </>
  );
};

export const LeaseHistoryListingActionCellPopup = (
  props: GridCustomCellProps
) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();
  const popupRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <td className="text-end">
        <div className="text-end">
          <button className="p-2" onClick={onClick} ref={anchor}>
            <MoreHorizontal strokeWidth={1} />
          </button>
          <Popup
            anchor={anchor.current}
            show={show}
            popupClass={"w-[161px]"}
            anchorAlign={{ horizontal: "right", vertical: "bottom" }}
            popupAlign={{ horizontal: "right", vertical: "top" }}
          >
            <div ref={popupRef}>
              <Button
                className="w-full iconBtn px-1"
                fillMode="flat"
                onClick={() =>
                  navigate(`/lease/${props.dataItem.Id}`, {
                    state: { action: "view" },
                  })
                }
              >
                <span className="inline-flex items-center gap-2 w-full">
                  <Eye className="w-4 h-4" />
                  <span className="flex-1 text-sm font-normal"> View</span>
                </span>
              </Button>
            </div>
          </Popup>
        </div>
      </td>
    </>
  );
};

export const CitiesDropdownItemRender = (li: any, itemProps: ListItemProps) => {
  const itemChildren = (
    <div>
      <div className="font-bold">{li.props.children}</div>
      <div className="text-xs">
        {itemProps.dataItem.CountryName} - {itemProps.dataItem.StateName}
      </div>
    </div>
  );

  return React.cloneElement(li, li.props, itemChildren);
};

export const EmployeesDropdownItemRender = (
  li: any,
  itemProps: ListItemProps
) => {
  const itemChildren = (
    <div>
      {itemProps.dataItem.Id} | {itemProps.dataItem.FullName}
    </div>
  );

  return React.cloneElement(li, li.props, itemChildren);
};

export const EmployeesMultiSelectTagRender = (tagData: TagData, li: any) => {
  const itemChildren = (
    <div>
      {tagData.data[0].Id} | {tagData.data[0].FullName}
    </div>
  );

  return React.cloneElement(li, li.props, itemChildren);
};

export const LeaseAllocationActionCellPopup = (props: GridCustomCellProps) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const popupRef = React.useRef<HTMLDivElement>(null);
  const [modalVisible, setModalVisible] = React.useState(false);
  const navigateTo = useNavigate();

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDialog = () => {
    setModalVisible(!modalVisible);
  };

  const handleDialog = (action: string) => {
    if (action === "yes") {
      handleDeleteAllocation(props.dataItem);
    }

    toggleDialog();
  };

  const handleDeleteAllocation = async (allocation: any) => {
    await leaseService.deleteLeaseAllocation(allocation.Id);
    navigateTo("/lease/allocation");
  };

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("delete")}
          onToggleDeleteDialog={handleDialog}
        />
      )}

      <td className="flex justify-between items-center">
        <div>{props.dataItem.EmployeeName}</div>
        <div>
          <button className="p-2" onClick={onClick} ref={anchor}>
            <MoreHorizontal strokeWidth={1} />
          </button>
          <Popup
            anchor={anchor.current}
            show={show}
            popupClass={"w-[161px]"}
            anchorAlign={{ horizontal: "right", vertical: "bottom" }}
            popupAlign={{ horizontal: "right", vertical: "top" }}
          >
            <div ref={popupRef}>
              <Button
                className="w-full iconBtn px-1"
                fillMode="flat"
                onClick={toggleDialog}
              >
                <span className="inline-flex items-center gap-2 w-full">
                  <FileX className="w-4 h-4" />
                  <span className="flex-1 text-sm font-normal">Delete</span>
                </span>
              </Button>
            </div>
          </Popup>
        </div>
      </td>
    </>
  );
};

export const DependantActionCellPopup = (props: GridCustomCellProps) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const popupRef = React.useRef<HTMLDivElement>(null);
  const [modalVisible, setModalVisible] = React.useState(false);
  const navigateTo = useNavigate();

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDialog = () => {
    setModalVisible(!modalVisible);
  };

  const handleDialog = async (action: string) => {
    if (action === "yes") {
      await dependantSvc.markDependentInactive(props.dataItem.DependentId);
      setShow(!show);
      navigateTo("/visa/dependants");
    }

    toggleDialog();
  };

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("cancel-dependent")}
          onToggleDeleteDialog={handleDialog}
        />
      )}
      <td>
        <div className="flex items-center gap-x-2 group">
          <div className="self-center">{props.dataItem.DependantName}</div>
          <button
            className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible group-hover:opacity-100 group-hover:visible  transition-all duration-300 ${
              show ? "active" : ""
            }`}
            onClick={onClick}
            ref={anchor}
          >
            <MoreHorizontal strokeWidth={1} />
          </button>
          <Popup
            anchor={anchor.current}
            show={show}
            popupClass={"w-[161px]"}
            anchorAlign={{ horizontal: "right", vertical: "bottom" }}
            popupAlign={{ horizontal: "right", vertical: "top" }}
          >
            <div ref={popupRef}>
              <Button
                className="w-full iconBtn px-1"
                fillMode="flat"
                onClick={() =>
                  navigateTo(`/visa/dependant`, {
                    state: {
                      action: "view",
                      id: props.dataItem.DependentId,
                      employeeId: props.dataItem.EmployeeId,
                      employeeName: props.dataItem.EmployeeName,
                    },
                  })
                }
              >
                <span className="inline-flex items-center gap-2 w-full">
                  <Eye className="w-4 h-4" />
                  <span className="flex-1 text-sm font-normal">View</span>
                </span>
              </Button>
            </div>
            <div ref={popupRef}>
              <Button
                className="w-full iconBtn px-1"
                fillMode="flat"
                onClick={() =>
                  navigateTo(`/visa/dependant`, {
                    state: {
                      action: "edit",
                      id: props.dataItem.DependentId,
                      employeeId: props.dataItem.EmployeeId,
                      employeeName: props.dataItem.EmployeeName,
                    },
                  })
                }
              >
                <span className="inline-flex items-center gap-2 w-full">
                  <Pencil className="w-4 h-4" />
                  <span className="flex-1 text-sm font-normal">Edit</span>
                </span>
              </Button>
            </div>
            <div ref={popupRef}>
              <Button
                className="w-full iconBtn px-1"
                fillMode="flat"
                onClick={toggleDialog}
              >
                <span className="inline-flex items-center gap-2 w-full">
                  <FileX className="w-4 h-4" />
                  <span className="flex-1 text-sm font-normal">Cancel</span>
                </span>
              </Button>
            </div>
          </Popup>
        </div>
      </td>
    </>
  );
};

export const EmployeeStatusData = (props: GridCustomCellProps) => {
  const [status, setStatus] = React.useState<string>("");

  useEffect(() => {
    const status = props.dataItem.Status || props.dataItem.ApplicationStatus;
    setStatus(status);
  }, [props.dataItem]);

  return (
    <>
      <td>
        <div className="flex items-center gap-x-2 group">
          <div
            className="self-center text-[14px] p-[5px] rounded-sm px-3 text-black"
            style={{
              background:
                STEPS_NAME_COLORS[status as keyof typeof STEPS_NAME_COLORS],
            }}
          >
            {status}
          </div>
        </div>
      </td>
    </>
  );
};

export const EmployeeDependantData = (props: GridCustomCellProps) => {
  const navigateTo = useNavigate();

  const anchor = React.useRef(null);
  const popupRef = React.useRef<HTMLDivElement>(null);

  const [show, setShow] = React.useState(false);
  const [dependant, setDependant] = React.useState<any>(null);
  const [renewModalVisible, setRenewModalVisible] = React.useState(false);

  const toggleRenewDialog = () => {
    setRenewModalVisible(!renewModalVisible);
  };

  const handleRenewDialog = (action: string) => {
    if (action === "yes") {
      let applicationType = "";

      if (props.dataItem.VisaStatus === "Expiring") {
        applicationType = "visa";
      } else if (props.dataItem.PassportStatus === "Expiring") {
        applicationType = "contract";
      } else {
        applicationType = "visacontract";
      }

      navigateTo(`/visa/application/-1`, {
        state: {
          action: "renew",
          employeeClass: props.dataItem.Class,
          employeeId: props.dataItem.Id,
          createdOn: props.dataItem.CreatedOn,
          appType: applicationType,
        },
      });
    }

    toggleRenewDialog();
  };

  const onClick = () => {
    if (!show) {
      if (!dependant) {
        fetchDependents();
      }
    }
    setShow(!show);
  };
  const fetchDependents = () => {
    dependantSvc.getDependentsByEmployeeId(props.dataItem.Id).then((res) => {
      if (res?.data) {
        setDependant(res.data);
      }
    });
  };

  React.useEffect(() => {
    setShow(false);
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {renewModalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("renewApplication")}
          onToggleDeleteDialog={handleRenewDialog}
        />
      )}
      <td>
        <div className="flex items-center gap-x-2 group">
          <div className="self-center">{props.dataItem.FullName}</div>
          <button
            className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible group-hover:opacity-100 group-hover:visible  transition-all duration-300 ${
              show ? "active" : ""
            }`}
            onClick={onClick}
            ref={anchor}
          >
            <MoreHorizontal strokeWidth={1} />
          </button>
          <Popup
            anchor={anchor.current}
            show={show}
            popupClass={"w-[161px]"}
            anchorAlign={{ horizontal: "right", vertical: "bottom" }}
            popupAlign={{ horizontal: "right", vertical: "top" }}
          >
            <div ref={popupRef}>
              <Button
                className="w-full iconBtn px-1"
                fillMode="flat"
                onClick={() =>
                  navigateTo(`/visa/employee/${props.dataItem.Id}`, {
                    state: { action: "view" },
                  })
                }
              >
                <span className="inline-flex items-center gap-2 w-full">
                  <Eye className="w-4 h-4" />
                  <span className="flex-1 text-sm font-normal">View</span>
                </span>
              </Button>
              <Button
                className="w-full iconBtn px-1"
                fillMode="flat"
                onClick={() =>
                  navigateTo(`/visa/employee/${props.dataItem.Id}`, {
                    state: { action: "edit" },
                  })
                }
              >
                <span className="inline-flex items-center gap-2 w-full">
                  <PencilLine className="w-4 h-4" />
                  <span className="flex-1 text-sm font-normal">Edit</span>
                </span>
              </Button>
              {props.dataItem.VisaStatus === "Expiring" && (
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={toggleRenewDialog}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <PencilLine className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">
                      Renew Visa
                    </span>
                  </span>
                </Button>
              )}
              {props.dataItem.PassportStatus === "Expiring" && (
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={toggleRenewDialog}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <PencilLine className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">
                      Renew Contract
                    </span>
                  </span>
                </Button>
              )}
              {props.dataItem.VisaStatus === "Expiring" &&
                props.dataItem.PassportStatus === "Expiring" && (
                  <Button
                    className="w-full iconBtn px-1"
                    fillMode="flat"
                    onClick={toggleRenewDialog}
                  >
                    <span className="inline-flex items-center gap-2 w-full">
                      <PencilLine className="w-4 h-4" />
                      <span className="flex-1 text-sm font-normal">
                        Renew Visa & Contract
                      </span>
                    </span>
                  </Button>
                )}
            </div>
            <div ref={popupRef}>
              {dependant?.length ? (
                <h4 className="px-1 text-primaryMain text-sm font-bold">
                  Dependants
                </h4>
              ) : (
                <></>
              )}
              {dependant?.map((dependant: any, index: number) => {
                return (
                  <Button
                    key={index}
                    className="cursor-pointer w-full iconBtn px-1 font-normal"
                    fillMode="flat"
                    onClick={() =>
                      navigateTo(`/visa/dependant`, {
                        state: {
                          action: "view",
                          id: dependant.DependentId,
                          employeeId: props.dataItem.Id,
                          employeeName: props.dataItem.FullName,
                        },
                      })
                    }
                  >
                    <span className="inline-flex items-center gap-2 w-full justify-between">
                      {dependant.DependantName}
                    </span>
                    <ChevronRight strokeWidth={1} />
                  </Button>
                );
              })}
            </div>
          </Popup>
        </div>
      </td>
    </>
  );
};

export const ApplicationStatusData = (props: GridCustomCellProps) => {
  const [status, setStatus] = React.useState<string>("");

  useEffect(() => {
    const status = props.dataItem.Status || props.dataItem.ApplicationStatus;
    setStatus(status);
  }, [props.dataItem]);

  return (
    <>
      <td>
        <div className="flex items-center gap-x-2 group">
          <div
            className="self-center text-[14px] p-[5px] rounded-sm px-3 text-white"
            style={{
              background:
                STEPS_STATUS_COLORS[status as keyof typeof STEPS_STATUS_COLORS],
            }}
          >
            {status?.toUpperCase()}
          </div>
        </div>
      </td>
    </>
  );
};

export const ApplicationTypeData = (props: GridCustomCellProps) => {
  const [type, setType] = React.useState<string>("");

  useEffect(() => {
    const status =
      props.dataItem.ApplicationType || props.dataItem.ApplicationType;
    setType(status);
  }, [props.dataItem]);

  return (
    <>
      <td>
        <div className="flex items-center gap-x-2 group">
          <div
            className="self-center text-[14px] p-[5px] rounded-sm px-3 text-white"
            style={{
              color:
                APPLICATION_TYPE_COLORS[
                  type as keyof typeof APPLICATION_TYPE_COLORS
                ],
              border: `1px solid ${
                APPLICATION_TYPE_COLORS[
                  type as keyof typeof APPLICATION_TYPE_COLORS
                ]
              }`,
            }}
          >
            {type?.toUpperCase()}
          </div>
        </div>
      </td>
    </>
  );
};

export const AssigneeNameData = (props: GridCustomCellProps) => {
  const navigate = useNavigate();

  const userState = useAppSelector((state: RootState) => state.user);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const [isMyself, setIsMyself] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAssignMySelf = async () => {
    if (userState?.user) {
      await applicationService.assignToMyself(
        userState.user.id,
        props.dataItem.Id
      );
    }
    navigate("/visa/application");
  };

  useEffect(() => {
    if (!userState?.user) return;
    if (userState.user.id === props.dataItem.AssigneeId) {
      setIsMyself(true);
    }
    const status = props.dataItem.AssigneeName || props.dataItem.AssigneeName;
    setName(status);
  }, [props.dataItem, userState]);

  return (
    <>
      <td>
        <div className="flex items-center gap-x-2 group">
          {!isMyself && (
            <div className="self-center text-[14px] p-[5px] rounded-sm px-3">
              {name}
            </div>
          )}
          {isMyself && (
            <div className="self-center text-[14px] p-[5px] rounded-sm px-3 text-[#0078D4]">
              Myself
            </div>
          )}
          {props?.dataItem?.AssigneeId &&
            props.dataItem.AssigneeId !== userState?.user?.id && (
              <>
                <button
                  className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible group-hover:opacity-100 group-hover:visible  transition-all duration-300 ${
                    show ? "active" : ""
                  }`}
                  onClick={onClick}
                  ref={anchor}
                >
                  <MoreHorizontal strokeWidth={1} />
                </button>
                <Popup
                  anchor={anchor.current}
                  show={show}
                  popupClass={"w-[161px]"}
                  anchorAlign={{ horizontal: "right", vertical: "bottom" }}
                  popupAlign={{ horizontal: "right", vertical: "top" }}
                >
                  <div ref={popupRef}>
                    <Button
                      className="w-full iconBtn px-1"
                      fillMode="flat"
                      onClick={handleAssignMySelf}
                    >
                      <span className="inline-flex items-center gap-2 w-full">
                        <Eye className="w-4 h-4" />
                        <span className="flex-1 text-sm font-normal">
                          Assign To Myself
                        </span>
                      </span>
                    </Button>
                  </div>
                </Popup>
              </>
            )}
        </div>
      </td>
    </>
  );
};

export const CurrentStepData = (props: GridCustomCellProps) => {
  return (
    <>
      <td>
        {props.dataItem.IncompleteSteps?.split("|")
          .map((x: any) => x.split(":")[1])
          .join(", ") || ""}
      </td>
    </>
  );
};

export const ApplicationDateCell = (props: GridCustomCellProps) => {
  const date = moment(props.dataItem.ModifiedOn).format(DATE_FORMAT);

  return <td {...props.tdProps}>{date}</td>;
};

export const ApplicationListingActionCellPopup = (
  props: GridCustomCellProps
) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();

  const popupRef = React.useRef<HTMLDivElement>(null);
  const [cancelModalVisible, setCancelModalVisible] = React.useState(false);
  const [abscondModalVisible, setAbscondModalVisible] = React.useState(false);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleCancelDialog = () => {
    setCancelModalVisible(!cancelModalVisible);
  };

  const handleCancelDialog = (action: string) => {
    if (action === "yes") {
      navigate(`/visa/application/-1`, {
        state: {
          action: "cancellation",
          employeeClass: props.dataItem.EmployeeClass,
          employeeId: props.dataItem.EmployeeId,
          createdOn: props.dataItem.CreatedOn,
        },
      });
    }

    toggleCancelDialog();
  };

  const toggleAbscondDialog = () => {
    setAbscondModalVisible(!abscondModalVisible);
  };

  const handleAbsondDialog = (action: string) => {
    if (action === "yes") {
      navigate(`/visa/application/-1`, {
        state: {
          action: "absconding",
          employeeClass: props.dataItem.EmployeeClass,
          employeeId: props.dataItem.EmployeeId,
          createdOn: props.dataItem.CreatedOn,
        },
      });
    }

    toggleAbscondDialog();
  };

  return (
    <>
      {cancelModalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("cancelApplication")}
          onToggleDeleteDialog={handleCancelDialog}
        />
      )}

      {abscondModalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("abscondApplication")}
          onToggleDeleteDialog={handleAbsondDialog}
        />
      )}

      <td className="text-end ">
        <div className="flex items-center gap-x-2 group">
          <div className="self-center">{props.dataItem.EmployeeName}</div>
          <div className="text-end">
            <button
              className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible group-hover:opacity-100 group-hover:visible  transition-all duration-300 ${
                show ? "active" : ""
              }`}
              onClick={onClick}
              ref={anchor}
            >
              <MoreHorizontal strokeWidth={1} />
            </button>
            <Popup
              anchor={anchor.current}
              show={show}
              popupClass={"w-[161px]"}
              anchorAlign={{ horizontal: "right", vertical: "bottom" }}
              popupAlign={{ horizontal: "right", vertical: "top" }}
            >
              <div ref={popupRef}>
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={() =>
                    navigate(`/visa/application/${props.dataItem.Id}`, {
                      state: {
                        action: "view",
                        employeeClass: props.dataItem.EmployeeClass,
                        employeeId: props.dataItem.EmployeeId,
                        createdOn: props.dataItem.CreatedOn,
                      },
                    })
                  }
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <Eye className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">View</span>
                  </span>
                </Button>

                {(props.dataItem.Status === STATUS_PENDING ||
                  props.dataItem.Status === STATUS_STARTED) && (
                  <Button
                    className="w-full iconBtn px-1"
                    fillMode="flat"
                    onClick={() =>
                      navigate(`/visa/application/${props.dataItem.Id}`, {
                        state: {
                          action: "edit",
                          employeeClass: props.dataItem.EmployeeClass,
                          employeeId: props.dataItem.EmployeeId,
                          createdOn: props.dataItem.CreatedOn,
                        },
                      })
                    }
                  >
                    <span className="inline-flex items-center gap-2 w-full">
                      <PencilLine className="w-4 h-4" />
                      <span className="flex-1 text-sm font-normal">Edit</span>
                    </span>
                  </Button>
                )}

                {props.dataItem.Status === STATUS_COMPLETED && (
                  <Button
                    className="w-full iconBtn px-1"
                    fillMode="flat"
                    onClick={toggleCancelDialog}
                  >
                    <span className="inline-flex items-center gap-2 w-full">
                      <FileX className="w-4 h-4" />
                      <span className="flex-1 text-sm font-normal">Cancel</span>
                    </span>
                  </Button>
                )}

                {props.dataItem.Status === STATUS_COMPLETED && (
                  <Button
                    className="w-full iconBtn px-1"
                    fillMode="flat"
                    onClick={toggleAbscondDialog}
                  >
                    <span className="inline-flex items-center gap-2 w-full">
                      <FileX className="w-4 h-4" />
                      <span className="flex-1 text-sm font-normal">
                        Abscond
                      </span>
                    </span>
                  </Button>
                )}
              </div>
            </Popup>
          </div>
        </div>
      </td>
    </>
  );
};

export const SubContractorActionCellPopup = (
  props: GridCustomCellProps & { handleEditClick: any }
) => {
  const navigate = useNavigate();

  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = async (rowData: any) => {
    await subContractorSvc.delete(rowData.id);
    navigate("/settings/subcontractor");
  };

  const toggleDialog = () => {
    setModalVisible(!modalVisible);
  };

  const handleDialog = (action: string) => {
    if (action === "yes") {
      handleDelete(props.dataItem);
    }

    toggleDialog();
  };

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("delete")}
          onToggleDeleteDialog={handleDialog}
        />
      )}

      <td className="text-end ">
        <div className="flex items-center gap-x-2 group">
          <div className="self-center cursor-pointer hover:underline hover:text-primaryMain">
            {props.dataItem.name}
          </div>
          <div className="text-end">
            <button
              className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible cta_dropdown  transition-all duration-300 ${
                show ? "active" : ""
              }`}
              onClick={onClick}
              ref={anchor}
            >
              <MoreHorizontal strokeWidth={1} />
            </button>
            <Popup
              anchor={anchor.current}
              show={show}
              popupClass={"w-[161px]"}
              anchorAlign={{ horizontal: "right", vertical: "top" }}
              popupAlign={{ horizontal: "left", vertical: "top" }}
            >
              <div ref={popupRef}>
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={() => props?.handleEditClick(props.dataItem)}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <PencilLine className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal"> Edit</span>
                  </span>
                </Button>

                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={toggleDialog}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <Trash2 className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal"> Delete</span>
                  </span>
                </Button>
              </div>
            </Popup>
          </div>
        </div>
      </td>
    </>
  );
};

export const CompanyActionCellPopup = (
  props: GridCustomCellProps & {
    handleEditClick: any;
    handleEnableDisable: any;
    fetchCompanies: any;
  }
) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = async (rowData: any) => {
    await companySvc.delete(rowData.id);
    props.fetchCompanies();
  };

  const toggleDialog = () => {
    setModalVisible(!modalVisible);
  };

  const handleDialog = (action: string) => {
    if (action === "yes") {
      handleDelete(props.dataItem);
      setShow(false);
    }

    toggleDialog();
  };

  const handleEnableDisable = async () => {
    if (props.dataItem.isActive) {
      await companySvc.update(props.dataItem.id, {
        ...props.dataItem,
        isActive: false,
      });
    } else {
      await companySvc.update(props.dataItem.id, {
        ...props.dataItem,
        isActive: true,
      });
    }
    props.fetchCompanies();
    setShow(false);
  };

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("delete")}
          onToggleDeleteDialog={handleDialog}
        />
      )}

      <td className="text-end ">
        <div className="flex items-center gap-x-2 group">
          <div className="self-center cursor-pointer hover:underline hover:text-primaryMain">
            {props.dataItem.name}
          </div>
          <div className="text-end">
            <button
              className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible cta_dropdown  transition-all duration-300 ${
                show ? "active" : ""
              }`}
              onClick={onClick}
              ref={anchor}
            >
              <MoreHorizontal strokeWidth={1} />
            </button>
            <Popup
              anchor={anchor.current}
              show={show}
              popupClass={"w-[161px]"}
              anchorAlign={{ horizontal: "right", vertical: "top" }}
              popupAlign={{ horizontal: "left", vertical: "top" }}
            >
              <div ref={popupRef}>
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={() => props?.handleEditClick(props.dataItem)}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <PencilLine className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal"> Edit</span>
                  </span>
                </Button>

                {/* <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={handleEnableDisable}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    {props.dataItem.isActive === false && (
                      <ShieldCheck className="w-4 h-4" />
                    )}
                    {props.dataItem.isActive === true && (
                      <ShieldMinus className="w-4 h-4" />
                    )}
                    <span className="flex-1 text-sm font-normal">
                      {" "}
                      {props.dataItem.isActive ? "Disable" : "Enable"}
                    </span>
                  </span>
                </Button> */}
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={toggleDialog}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <Trash2 className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal"> Delete</span>
                  </span>
                </Button>
              </div>
            </Popup>
          </div>
        </div>
      </td>
    </>
  );
};

export const ObjectTypeActionCellPopup = (
  props: GridCustomCellProps & { handleEditClick: any; fetchObjectTypes: any }
) => {
  const navigate = useNavigate();

  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = async (rowData: any) => {
    await objectTypeSvc.delete(rowData.id);
    props.fetchObjectTypes();
    // navigate("/settings/object-types");
  };

  const toggleDialog = () => {
    setModalVisible(!modalVisible);
  };

  const handleDialog = (action: string) => {
    if (action === "yes") {
      handleDelete(props.dataItem);
    }

    toggleDialog();
  };

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("delete")}
          onToggleDeleteDialog={handleDialog}
        />
      )}

      <td className="text-end ">
        <div className="flex items-center gap-x-2 group">
          <div className="self-center cursor-pointer hover:underline hover:text-primaryMain">
            {props.dataItem.name}
          </div>
          <div className="text-end">
            <button
              className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible cta_dropdown  transition-all duration-300 ${
                show ? "active" : ""
              }`}
              onClick={onClick}
              ref={anchor}
            >
              <MoreHorizontal strokeWidth={1} />
            </button>
            <Popup
              anchor={anchor.current}
              show={show}
              popupClass={"w-[161px]"}
              anchorAlign={{ horizontal: "right", vertical: "top" }}
              popupAlign={{ horizontal: "left", vertical: "top" }}
            >
              <div ref={popupRef}>
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={() => props?.handleEditClick(props.dataItem)}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <PencilLine className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal"> Edit</span>
                  </span>
                </Button>

                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={toggleDialog}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <Trash2 className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal"> Delete</span>
                  </span>
                </Button>
              </div>
            </Popup>
          </div>
        </div>
      </td>
    </>
  );
};

export const MappingEnabledCell = (
  props: GridCellProps,
  handleEnableDisable: any
) => {
  const { dataItem, field } = props;

  return (
    <td>
      <Switch
        checked={props.dataItem.isActive}
        onLabel="Yes"
        offLabel="No"
        name="isActive"
        value={dataItem.isActive}
        onChange={(e) => handleEnableDisable(props.dataItem)}
      ></Switch>
    </td>
  );
};

export const MappingActionCellPopup = (
  props: GridCustomCellProps & { fetchMappings: any }
) => {
  const navigate = useNavigate();

  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = async (rowData: any) => {
    await templateMappingSvc.delete(rowData.id);
    props.fetchMappings();
  };

  const handleDisable = async () => {
    await templateMappingSvc.update(props.dataItem.id, {
      ...props.dataItem,
      isEnabled: false,
    });
    props.fetchMappings();
    // navigate('/settings/mappings');
  };

  const handleEnable = async (rowData: any) => {
    await templateMappingSvc.update(props.dataItem.id, {
      ...props.dataItem,
      isEnabled: true,
    });
    props.fetchMappings();
    // navigate('/settings/mappings');
  };

  const toggleDialog = () => {
    setModalVisible(!modalVisible);
  };

  const handleDialog = (action: string) => {
    if (action === "yes") {
      handleDelete(props.dataItem);
    }

    toggleDialog();
  };

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("delete")}
          onToggleDeleteDialog={handleDialog}
        />
      )}

      <td className="text-end ">
        <div className="flex items-center gap-x-2 group">
          <div
            className="self-center cursor-pointer hover:underline hover:text-primaryMain"
            onClick={() =>
              navigate(
                `/settings/create-mapping?action=view&id=${props.dataItem.id}`
              )
            }
          >
            {props.dataItem.mappingName}
          </div>
          <div className="text-end">
            <button
              className={`p-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible cta_dropdown  transition-all duration-300 ${
                show ? "active" : ""
              }`}
              onClick={onClick}
              ref={anchor}
            >
              <MoreHorizontal strokeWidth={1} />
            </button>
            <Popup
              anchor={anchor.current}
              show={show}
              popupClass={"w-[161px]"}
              anchorAlign={{ horizontal: "right", vertical: "top" }}
              popupAlign={{ horizontal: "left", vertical: "top" }}
            >
              <div ref={popupRef}>
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={() =>
                    navigate(
                      `/settings/create-mapping?action=edit&id=${props.dataItem.id}`
                    )
                  }
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <PencilLine className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal"> Edit</span>
                  </span>
                </Button>
                {/* {props.dataItem.isEnabled && (
                  <Button
                    className="w-full iconBtn px-1"
                    fillMode="flat"
                    onClick={handleDisable}
                  >
                    <span className="inline-flex items-center gap-2 w-full">
                      <FileX className="w-4 h-4" />
                      <span className="flex-1 text-sm font-normal">
                        {" "}
                        Disable
                      </span>
                    </span>
                  </Button>
                )}
                {!props.dataItem.isEnabled && (
                  <Button
                    className="w-full iconBtn px-1"
                    fillMode="flat"
                    onClick={handleEnable}
                  >
                    <span className="inline-flex items-center gap-2 w-full">
                      <PencilLine className="w-4 h-4" />
                      <span className="flex-1 text-sm font-normal">
                        {" "}
                        Enable
                      </span>
                    </span>
                  </Button>
                )} */}
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={toggleDialog}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <Trash2 className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal"> Delete</span>
                  </span>
                </Button>
              </div>
            </Popup>
          </div>
        </div>
      </td>
    </>
  );
};

export const BBSImportCell = (
  props: GridCustomCellProps & {
    updatedFields: Record<string, string[]>;
    shapeCodes: IShapeCodes[];
    isInvalid: boolean;
    setIsInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    errsList: any;
    setErrsList: React.Dispatch<any>;
    invalidMembers: string[];
    invalidMarks: any[];
    standard: IStandard | null;
  }
) => {
  const commonState = useAppSelector((state: RootState) => state.common);
  const barsSizes: IBarSize[] = props.standard?.barSizes!;
  const bars: IBar[] = props.standard?.bars!;
  const [isInvalidCell, setIsInvalidCell] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (props?.dataItem) {
      const key = `${props.dataItem.name}:${props.dataItem.mark}`;
      let isInvalidTemp = false;
      // Validate Bar Size and Bar Types
      const barSizeObj = barsSizes.find(
        (x) => x.size === Number(props.dataItem.size)
      );
      const bar = bars.find((x) => x.typeCode === props.dataItem.type);
      if (props.dataItem.name && props.dataItem.mark) {
        if (key in props.updatedFields) {
          if (
            props.updatedFields[key] &&
            props.field &&
            props.updatedFields[key].includes(props.field)
          ) {
            setIsUpdated(true);
          }
        }
      }

      switch (props.field) {
        case "shapeCode":
          if (!props?.shapeCodes?.length) {
            isInvalidTemp = false;
          } else {
            isInvalidTemp = !props.shapeCodes.some(
              (shapeCode) => shapeCode.code == props.dataItem[props.field!]
            );
          }
          break;

        case "noOfMem":
          if (props.invalidMembers.includes(props.dataItem.name)) {
            isInvalidTemp = true;
          }
          break;
        case "type":
          // Validate Bar Size and Bar Types
          if (!bar) {
            isInvalidTemp = true;
          }
          break;
        case "size":
          if (!barSizeObj) {
            isInvalidTemp = true;
          }
          break;

        case "mark":
          if (
            props.invalidMarks.find(
              (x) =>
                x.memberName == props.dataItem.name &&
                x.value == props.dataItem.mark
            )
          ) {
            isInvalidTemp = true;
          }
          break;
        case "length":
          if (props.dataItem.isInvalidBarLength === true) {
            isInvalidTemp = true;
            if (!(key in props.errsList)) {
            }

            break;
          }
          if (commonState?.selectedProject?.event?.item?.barLength) {
            isInvalidTemp =
              props.dataItem[props.field] >
              commonState.selectedProject.event.item.barLength;
            if (!(key in props.errsList) && isInvalidTemp === true) {
            }
            break;
          } else {
            isInvalidTemp = false;
            break;
          }

        default:
          isInvalidTemp = false;
          setIsInvalidCell(false);
          break;
      }
      setIsInvalidCell(isInvalidTemp);
      if (props.isInvalid === false && isInvalidTemp === true) {
        props.setIsInvalid(isInvalidTemp);
      }
    }
  }, [props]);

  const getClass = () => {
    if (isInvalidCell) {
      return "text-[#A4262C] bg-[#F3D2D4]";
    }

    if (props.field && props.dataItem[props.field] && isUpdated) {
      return "text-[#FFE58D] bg-[#B58F0E]";
    }

    return "";
  };

  return (
    <>
      <td className={getClass()}>
        {props.field == "weight" &&
          props.dataItem[props.field] &&
          props.dataItem[props.field].toFixed(3)}
        {props.field && props.field != "weight" && props.dataItem[props.field]}
      </td>
    </>
  );
};

export const StatusCellA = (props: GridCellProps) => {
  const { dataItem, field } = props;
  const status = dataItem[field || ""];

  const getStatusClass = (status: string) => {
    switch (status) {
      case "New":
        return "text-[#063206] bg-[#DFF6DD]";
      case "Updated":
        return "text-[#FFE58D] bg-[#B58F0E]";
      case "Deleted":
        return "text-[#A4262C] bg-[#F3D2D4]";
      case "Revised":
        return "text-[#4094DC] bg-[#CEE5FF]";
      default:
        return "";
    }
  };

  return (
    <td>
      <span className={`px-2 py-1 rounded ${getStatusClass(status)}`}>
        {status}
      </span>
    </td>
  );
};

export const MemberNameCell: React.FC<
  GridCellProps & {
    shapeCodes: IShapeCodes[];
    invalidMembers: string[];
    invalidMarks: any[];
  }
> = (props) => {
  const { dataItem, field } = props;
  const name = dataItem[field || ""];
  const commonState = useAppSelector((state: RootState) => state.common);

  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (props.dataItem) {
      if (!props?.shapeCodes?.length) {
        setIsInvalid(true);
      } else {
        const isInvalidTemp = !props.shapeCodes.some(
          (shapeCode) => shapeCode.code == props.dataItem.shapeCode
        );
        setIsInvalid(isInvalidTemp);
      }

      if (props.invalidMembers.includes(props.dataItem.name)) {
        setIsInvalid(true);
      }

      if (
        props.dataItem.length > commonState.selectedProject.event.item.barLength
      ) {
        setIsInvalid(true);
      }

      if (props.invalidMarks.find((x) => x.memberName == props.dataItem.name)) {
        setIsInvalid(true);
      }
    }
  }, [props]);

  return (
    <td className="p-2">
      <div className="flex items-center gap-2">
        <p className="flex-1">{name}</p>
        {isInvalid && (
          <span className=" text-[#A4262C] border-[#A4262C] border rounded-sm inline-flex items-center justify-center p-[5px_5px]">
            <Info strokeWidth={1} size={14} />
          </span>
        )}
      </div>
    </td>
  );
};

export const DateCell = (props: GridCellProps) => {
  const { dataItem, field } = props;

  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options: any = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  return (
    <td>
      <span className={`px-2 py-1 rounded`}>
        {formatDate(dataItem[field || ""])}
      </span>
    </td>
  );
};

export const BBSActionCellPopup = (
  props: GridCustomCellProps & {
    handleEditClick: any;
    handleImport: any;
    handleExport: any;
    handleDelete: any;
  }
) => {
  const navigate = useNavigate();

  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = async (rowData: any) => {
    await bbsSvc.delete(rowData.id).then(async (x) => {
      props.handleDelete();
    });
    //navigate("/home");
  };

  const toggleDialog = () => {
    setModalVisible(!modalVisible);
  };

  const handleDialog = (action: string) => {
    if (action === "yes") {
      handleDelete(props.dataItem);
    }

    toggleDialog();
  };

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("delete")}
          onToggleDeleteDialog={handleDialog}
        />
      )}

      <td className="text-end ">
        <div className="flex items-center gap-x-2 group">
          <div className="self-center cursor-pointer hover:underline hover:text-primaryMain">
            {props.dataItem.no}
          </div>
          <div className="text-end">
            <button
              className={`px-2 [&.active]:opacity-100 [&.active]:visible opacity-0 invisible cta_dropdown  transition-all duration-300 ${
                show ? "active" : ""
              }`}
              onClick={onClick}
              ref={anchor}
            >
              <MoreHorizontal strokeWidth={1} />
            </button>
            <Popup
              anchor={anchor.current}
              show={show}
              popupClass={"w-[161px] bbsEditPopup"}
              anchorAlign={{ horizontal: "right", vertical: "top" }}
              popupAlign={{ horizontal: "left", vertical: "top" }}
            >
              <div ref={popupRef}>
                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={() => {
                    setShow(!show);
                    props?.handleEditClick(props.dataItem);
                  }}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <PencilLine className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal"> Edit</span>
                  </span>
                </Button>

                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={() => {
                    setShow(!show);
                    props?.handleImport(props.dataItem);
                  }}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <ArrowUpSquare className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">
                      {" "}
                      Import BBS
                    </span>
                  </span>
                </Button>

                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={() => {
                    setShow(!show);
                    props?.handleExport(props.dataItem);
                  }}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <FileX2 className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">
                      {" "}
                      Export BBS
                    </span>
                  </span>
                </Button>

                <Button
                  className="w-full iconBtn px-1"
                  fillMode="flat"
                  onClick={toggleDialog}
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <Trash2 className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal"> Delete</span>
                  </span>
                </Button>
              </div>
            </Popup>
          </div>
        </div>
      </td>
    </>
  );
};
