import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { useEffect, useRef, useState } from 'react';
import ErrorMessage from '../global/ErrorMessage';
import CustomLabel from '../global/RequiredMark';

interface IProps {
  name: string,
  value: Date | null,
  required: boolean,
  label: string,
  placeholder: string,
  disabled: boolean,
  onChange: (e: DatePickerChangeEvent) => void,
  onValidate: (name: string, isValid: boolean) => void,
  max?: Date
  flexDir?: string
}

function BBSDatePicker({ max, flexDir, name, value, required, label, placeholder, disabled, onChange, onValidate }: IProps) {
  const valueChanged = useRef(false);
  const [isValid, setIsValid] = useState(true);
  const [dirty, setDirty] = useState(false);

  const handleChange = (e: DatePickerChangeEvent) => {
    valueChanged.current = true;
    const input = e.target.value;

    let valid = true;
    if (input === null) {
      valid = true;
    } else {
      valid = true;
    }

    if ((input === null || input === undefined) && !required) {
      setIsValid(true);
      onValidate(name, valid);
    } else {
      setIsValid(valid);
      onValidate(name, valid);
    }
    onChange(e);
  };

  useEffect(() => {
    if (required && !value && valueChanged.current === false) {
      onValidate(name, false);
    }
  }, [value, required])

  return (
    <div className={flexDir === "row" ? 'flex gap-x-4' : ''}>
      <CustomLabel
        label={label}
        required={required}
        flexDir='row'
      />
      <div className={`flex-1 ${flexDir ? 'w-2/3' : ''}`}>
        <DatePicker
          placeholder={placeholder}
          format={"dd-MMM-yyyy"}
          disabled={disabled}
          value={value}
          max={max}
          onChange={handleChange}
          name={name}
          valid={isValid}
          className='self-center'
        />
        {required && dirty && (value === null || value === undefined) && (
          <ErrorMessage message="*Field can't be empty." />
        )}
      </div>
    </div>
  )
}

export default BBSDatePicker;
